import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Helper } from 'src/app/healpers/helper';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(private roleService:NgxRolesService,private loginService:LoginService,
    private cdf:ChangeDetectorRef) { }
  @Output() selectItem = new EventEmitter<any>();
 public selectedItem = 'Dashboard'
  currentonCollapseClicked='';
  permissions =[]
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: any) {
    console.log(event)
    sessionStorage.setItem("selectedItem",this.selectedItem);
  };


  ngOnInit(): void {
    // if (sessionStorage.getItem("selectedItem")) {
    //   this.selectedItem=sessionStorage.getItem("selectedItem");
    //   sessionStorage.removeItem("selectedItem")
    // }
    console.log(this.roleService.getRoles())
    this.cdf.detectChanges();
    Helper.selectedTitle.subscribe(res=>{this.selectedItem=res});
    // window.location.reload()
}
onSelectItem(data){
  // document.getElementById('contents').classList.toggle('active');
  // document.getElementById('sidebar').classList.toggle('active');
//  document.getElementById('sidebar').classList.toggle('side-nav-mobile-active');
document.getElementById('sidebar').classList.toggle('mobile-active-open');

  this.selectItem.emit(data)
  this.selectedItem=data;
  Helper.setNewTitle(data)
}

onCollapseClicked(data){


  if(this.currentonCollapseClicked!=data){
    this.currentonCollapseClicked = data;

  }else{
  this.currentonCollapseClicked=''
}
}

}
