import { NgModule } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';

import { UtilesRoutingModule } from './utiles-routing.module';
import { TableComponent } from './table/table.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';

import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { WrkCalendarComponent } from './wrk-calendar/wrk-calendar.component';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { WrkSkeletonComponent } from './wrk-skeleton/wrk-skeleton.component';
import {SkeletonModule} from 'primeng/skeleton';
import { WrkSessionCardComponent } from './wrk-session-card/wrk-session-card.component';
import { SessionTimerComponent } from './session-timer/session-timer.component';
import { CustomTimePipe } from 'src/app/pipes/date-and-time-pipe/custom-time.pipe';
import { WhatsappFloatingComponent } from 'src/app/sharedModule/whatsapp-floating/whatsapp-floating.component';

@NgModule({
  declarations: [
    TableComponent,
    WrkCalendarComponent,
    WrkSkeletonComponent,
    WrkSessionCardComponent,
    SessionTimerComponent,
    CustomTimePipe,
    WhatsappFloatingComponent

  ],
  exports:[TableComponent,WrkCalendarComponent,WrkSkeletonComponent,WrkSessionCardComponent,WhatsappFloatingComponent],
  imports: [
    CommonModule,
    UtilesRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    SharedModule,
    RippleModule,
    PaginatorModule,
    InputSwitchModule,
    ConfirmPopupModule,
    ButtonModule,
    FullCalendarModule,
    SkeletonModule
  ],
  
  providers:[ConfirmationService,SlicePipe,CustomTimePipe],
})
export class UtilesModule { }
