import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(private router:Router,private formBuilder:FormBuilder,private dataService:DataService,private sharedService:SharedService) { }
  forgetFrom : FormGroup;
  isSubmitted = false;
  isLoading = false;
  OTPCode =  '';
  ngOnInit(): void {
    this.creatForm();
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }


  async submit() {
    this.isSubmitted = true;
    if(this.forgetFrom.invalid){
      return;
    }
    else  {
      this.isLoading = true;
     await this.dataService.post({Email:this.forgetFrom.controls.email.value},'OTPEmail').toPromise().then(
        res=>{
          if(res.body['Result']['statusCode']==200){
            // this.OTPCode=res.body['Result']['data'];
            this.sharedService.setNewEmail(this.forgetFrom.controls.email.value);
            this.isLoading = false;
            this.router.navigate(['/website/emailVertification',btoa(JSON.stringify({email :this.forgetFrom.controls.email.value}))])

            
          }
        }
      )
      this.isLoading = false;
  
    }
  }
  get formControls() { return this.forgetFrom.controls; }
  creatForm() {
    this.forgetFrom = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    })

  }

  
}
