import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { LayoutsRoutingModule } from './layouts-routing.module';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { LayoutsComponent } from './layouts.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DashboardModule } from '../Modules/dashboard/dashboard.module';
import { ProfileComponent } from './components/profile/profile.component';
import { AvatarModule } from 'primeng/avatar';
import { UtilesModule } from '../Modules/utiles/utiles.module';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';


@NgModule({
  declarations: [
    NotificationPopupComponent,
    HeaderComponent,
    SideMenuComponent,
    LayoutsComponent,
    ProfileComponent
  ],
  exports:[
    NotificationPopupComponent,
    HeaderComponent,
    SideMenuComponent,
    LayoutsComponent
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    NgxPermissionsModule.forChild(),
    DialogModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DashboardModule,
    AvatarModule,
    FormsModule,
    ReactiveFormsModule,
    UtilesModule,
    CalendarModule,
    CardModule
  ],
  providers:[MessageService,DatePipe]
})
export class LayoutsModule { }
