import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpInterceptorService } from './apiServices/HttpInterceptor/http-interceptor.service';
import { LayoutsModule } from './layouts/layouts.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { OpentokService } from './opentok.service';
import { PublisherTwoComponent } from './sharedModule/publisher-two/publisher-two.component';
import { ToastModule } from 'primeng/toast';
import { LandingPageComponent } from './sharedModule/landing-page/landing-page.component';
import { UtilesModule } from './Modules/utiles/utiles.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaymentWebViewComponent } from './sharedModule/payment-web-view/payment-web-view.component';
import { PublisherAllComponent } from './sharedModule/publisher-all/publisher-all.component';
import { PubComponent } from './sharedModule/pub/pub.component';
import { SubComponent } from './sharedModule/sub/sub.component';
import { WesiteModuleModule } from './wesite-module/wesite-module.module';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { PaymentOtpComponent } from './sharedModule/payment-otp/payment-otp.component';
import { PaymentSuccessComponent } from './sharedModule/payment-success/payment-success.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaymentMessageComponent } from './sharedModule/payment-message/payment-message.component';
import { PaymentFailComponent } from './sharedModule/payment-fail/payment-fail.component';

export function translateHttpLoaderFactory(http): TranslateHttpLoader {
return new TranslateHttpLoader(
    new HttpClient(http),
    "assets/i18n/",
    ".ts"
);
}
@NgModule({
  declarations: [
    AppComponent,

    PublisherTwoComponent,
    LandingPageComponent,
    PaymentWebViewComponent,
    PublisherAllComponent,
    PubComponent,
    SubComponent,
    PaymentOtpComponent,
    PaymentSuccessComponent,
    PaymentMessageComponent,
    PaymentFailComponent
    // CustomTimePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    LayoutsModule,
    WesiteModuleModule,CalendarModule,
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ToastModule,
    UtilesModule,DropdownModule,ProgressSpinnerModule,    FormsModule,ReactiveFormsModule,


    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

  ],
  exports :[

  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
  },
  OpentokService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
