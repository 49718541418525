<div class="wrapper_contentBlock">
    <div class="myart_layerout">
        <form>
            <div class="wrLayout head_flex">
                <h3 class="bold-txt">Profile Information</h3>
            </div>
            <hr class="mat-bb" />
            <app-wrk-skeleton *ngIf="!isReadyData"></app-wrk-skeleton>
            <div *ngIf="isReadyData" class="wrpper_wrcontents">
                <div class="content_wrapperForms">
                    <div class="row">
                        <div class="col-lg-1">
                            <!--  -->

                            <p-avatar *ngIf="Tutor.photo_path_url" [image]="currenPhotoUrl" styleClass="p-mr-2" size="xlarge" shape="circle"></p-avatar>
                        </div>
                        <div class="mx-3 col-lg-4 align-self-baseline d-grid">
                            <label style="font-weight: 700;">{{Tutor.FullName ? Tutor.FullName : 'Full Name'}}</label>
                            <label class="text-muted">{{Tutor.Email ? Tutor.Email : 'email@example.com'}}</label>
                            <div class="mt-2">
                                <button (click)="uploader.click()" style="border-radius: 10px;" class="btn me-1 btn-sm btn-outline-info">Upload photo
                    </button>
                                <input #uploader accept="image/*" (change)="photoUrlChange($event)" hidden type="file" class="form-control" id="customFile" required />

                                <!-- <button style="border-radius: 10px;" class="btn btn-sm btn-outline-info" (click)="removePhoto()">Remove photo</button> -->
                            </div>

                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="alert alert-success alertTitle_" role="alert">
                            Personal Information
                        </div>
                    </div>
                    <br />
                    <div class="row justify-content-between">
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>User ID</label>
                                <input type="text" class="form-control" [(ngModel)]="Tutor.SpecialId" [ngModelOptions]="{standalone: true}" required value="" disabled> </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Full Name<span class="has-error">*</span></label>
                                <input [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.FullName}" type="text" class="form-control" [(ngModel)]="Tutor.FullName" [ngModelOptions]="{standalone: true}" required value="">
                                <div *ngIf="isSubmitted && !Tutor.FullName" class="help-block">
                                    <div class="has-error">Full Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Gender<span class="has-error">*</span></label>
                                <select [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.Gender}" [(ngModel)]="Tutor.Gender" [ngModelOptions]="{standalone: true}" name="" id="" class="form-control">
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                       </select>
                                <div *ngIf="isSubmitted && !Tutor.Gender" class="help-block">
                                    <div class="has-error">Gender is required</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row justify-content-between">

                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Email Address<span class="has-error">*</span></label>
                                <input disabled [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.email}" [(ngModel)]="Tutor.email" [formControl]="email" type="email" class="form-control" required value="">
                                <div *ngIf="isSubmitted && !Tutor.email" class="help-block">
                                    <div class="has-error">Email is required</div>
                                </div>
                                <div *ngIf="email.errors?.pattern" class="has-error">
                                    EMAIL INVALID
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Phone Number<span class="has-error">*</span></label>
                                <input [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.PhoneNumber}" [(ngModel)]="Tutor.PhoneNumber" [ngModelOptions]="{standalone: true}" type="text" class="form-control" required value="" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                                <div *ngIf="isSubmitted && !Tutor.PhoneNumber" class="help-block">
                                    <div class="has-error">Phone Number is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Paypal Email<span class="has-error">*</span></label>
                                <input [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.PayPal}" [(ngModel)]="Tutor.PayPal" [formControl]="paypalEmail" type="email" class="form-control" required value="">
                                <div *ngIf="isSubmitted && !Tutor.PayPal" class="help-block">
                                    <div class="has-error">Paypal Email is required</div>
                                </div>
                                <div *ngIf="paypalEmail.errors?.pattern" class="has-error">
                                    EMAIL INVALID
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Nationality<span class="has-error">*</span></label>
                                <select [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.Nationality}" [(ngModel)]="Tutor.Nationality" [ngModelOptions]="{standalone: true}" name="" id="" class="form-control">
                          <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>

                       </select>
                                <div *ngIf="isSubmitted && !Tutor.Nationality" class="help-block">
                                    <div class="has-error">Nationality is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>English type<span class="has-error">*</span></label>

                                <select placeholder="English type" [(ngModel)]="Tutor.EnglishType" [ngModelOptions]="{standalone: true}" class="form-control" [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.EnglishType}">
                                    <option value="">English type</option>
                                    <option value="native">Native</option>
                                    <option value="professional">Professional</option>
                                  </select>
                                <div *ngIf="isSubmitted && !Tutor.EnglishType" class="help-block font-10">
                                    <div class="has-error">English Type is required</div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-3 col-xs-12 col-sm-12 col-lg-3  wrt_forms">
                            <label>Date of birth<span class="has-error">*</span></label>
                            <p-calendar appendTo="body" inputId="basic" [monthNavigator]="true" [yearNavigator]="true" yearRange="1940 :2005" inputId="navigators" [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.DateOfBirth} " [(ngModel)]="Tutor.DateOfBirth" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" placeholder="Date of birth"></p-calendar>
                            <div *ngIf="isSubmitted && !Tutor.DateOfBirth" class="help-block">
                                <div class="has-error">Tutor Date of birth is Required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Password<span class="has-error">*</span></label>
                                <input [(ngModel)]="Tutor.Password" [formControl]="Password" id="password" type="password" class="form-control" required value="">
                                <!-- <div *ngIf="isSubmitted && !Tutor.Password" class="help-block">
                                    <div class="has-error">Password is required</div>
                                </div> -->
                                <div *ngIf="isSubmitted && Tutor.Password&& Tutor.Password.length < 6" class="help-block">
                                    <div class="has-error">Password must be at least 6 characters</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Confirm Password</label>
                                <input id="confirm-password" type="password" class="form-control" [formControl]="confirmPassword" [ngClass]="{ 'has-error': isSubmitted && !isConfirmedPassword}" required value="">
                                <div *ngIf="isSubmitted &&Tutor.Password&& !isConfirmedPassword" class="help-block">
                                    <div class="has-error">Password Doesn't match</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                        </div>
                    </div>
                    <br/>
                    <div class="wrLayout head_flex">
                        <h1>Address Details</h1>
                    </div>
                    <hr class="mat-bb" />
                    <div class="row justify-content-between">
                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Address<span class="has-error">*</span></label>
                                <select [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.Address}" [(ngModel)]="Tutor.Address" [ngModelOptions]="{standalone: true}" name="" id="" class="form-control">
                          <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>

                       </select>
                                <div *ngIf="isSubmitted && !Tutor.Address" class="help-block">
                                    <div class="has-error">Address is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-3">
                            <!-- <div class="wrt_forms">
                     <label>Time zone</label>
                     <select name="" id="" class="form-control">
                        <option>India</option>
                     </select>
                  </div> -->
                        </div>
                    </div>
                    <div class="row ">
                        <div class="alert alert-success alertTitle_" role="alert">
                            Education Details
                        </div>
                    </div>
                    <br/>
                    <div class="row justify-content-between">

                        <div class="col-md-12 col-lg-3">
                            <div class="wrt_forms">
                                <label>Qualifications <span class="has-error">*</span></label>
                                <select [ngClass]="{ 'has-error': isSubmitted &&  !Tutor.Qualifications}" [(ngModel)]="Tutor.Qualifications" [ngModelOptions]="{standalone: true}" name="" id="" class="form-control">
                                    <option value="">Qualification</option>
                                    <option value="diploma">Diploma</option>
                                    <option value="bachelor">Bachelor</option>
                                    <option value="master">Master</option>
                                    <option value="phD">PHD</option>
                                    <option value="otherPleaseType">other please type</option>
                                </select>
                                <div *ngIf="isSubmitted && !Tutor.Qualifications" class="help-block">
                                    <div class="has-error">Qualifications is required</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="wrLayout head_flex">
                        <h1>CV Details </h1>
                    </div>
                    <hr class="mat-bb" />
                    <div class="uploadimages_flex">
                        <div id="img-upload-form  " class="row my-2 align-items-center">
                            <div class="form_inputUpload">
                                <div class="border_slotted">
                                    <img src="assets/images/upload_img.png" alt="">
                                    <label for="upload_imgs" class="button">Add CV </label>
                                    <button (click)="upload.click()" class="btn_uploadFiles">Upload File</button>
                                    <!-- accept="image/*" -->
                                    <input #upload (change)="CVChanged($event)" type="file" id="files" name="files">
                                </div>
                            </div>
                            <ng-container *ngIf="Tutor.CV">
                                <div class="col-lg-2 mx-4 my-4">
                                    <p-card [style]="{width: '200px',height: '230px'}" styleClass="p-card-shadow">
                                        <ng-template pTemplate="header">
                                            <div class="text-center">
                                                <div class="image_with_badge_container">
                                                    <a [href]="currentCVUrl" target="_blank" download>
                                                        <img class="tutor-image" alt="Card" src="assets/images/file.png">
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-card>

                                </div>
                            </ng-container>

                        </div>
                    </div>
                    <br />

                    <div class="row text-center">
                        <p-progressSpinner *ngIf="isAdded" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                        <div *ngIf="!isAdded" class="col-md-12 col-lg-12 left_">
                            <button class="btn w-btn-success btn-110" (click)="onSubmitte()">Save</button>&nbsp;&nbsp;
                            <button routerLink="/" class="btn btn-110 btn-outline-secondary">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>