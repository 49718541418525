<div class="card border-0 font-table">

    <p-table #dt1 [resizableColumns]="true" [autoLayout]="true" [scrollable]="scrollable" scrollHeight="200px" [value]="list" [columns]="columns" dataKey="id" [loading]="loading" [rows]="10" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        styleClass="p-datatable-customers" (onSort)="onSort($event)">
        <ng-template pTemplate="header" let-columns>
            <tr style="font-size: 12px;">

                <ng-container *ngFor="let col of columns;let i =index">

                    <th [ngClass]="col?.width ? 'width-header-'+col.width : null" style="background-color: #EEFEFE;" [pSortableColumn]="col.field">
                        <div class="p-d-flex p-ai-center">
                            <p-columnFilter *ngIf="col.isFiltered" type="text" [field]="col.field" display="menu" [matchModeOptions]="matchModeOptions" [showAddButton]="false" [showOperator]="false"></p-columnFilter>

                            {{col.header}}
                            <p-sortIcon *ngIf="col.isSorted" [field]="col.field"></p-sortIcon>
                        </div>
                    </th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
            <tr class="bold-txt " style="font-size: 12px;">


                <ng-container>

                    <td *ngFor="let col of columns" [ngClass]="col?.width ? 'width-header-'+col.width : null">
                        <ng-container>
                            <div [ngSwitch]="col.type.name">
                                <div *ngSwitchCase="'id'" class="d-inline-block mw-100 ">
                                    {{i+1 + ((page-1) * 10)}}
                                </div>
                                <div *ngSwitchCase="'normal'" class="d-inline-block mw-100 ">
                                    {{rowData[col.field]}}
                                </div>
                                <div *ngSwitchCase="'date'">
                                    {{rowData[col.field] | date: 'MM/dd/yyyy'}}
                                </div>
                                <div *ngSwitchCase="'image'">
                                    <img [src]="rowData[col.field]" width="30">
                                    <span class="image-text">
                    <!-- {{rowData[col.field]}} -->
                  </span>
                                </div>

                                <div *ngSwitchCase="'span'" class="w-75">
                                    <span [class]="'status-badge  px-3 py-2  d-block text-center'" [ngClass]="{'status-active' : rowData[col.field] == 'active','status-inactive' : rowData[col.field] != 'active'}">
                    {{rowData[col.field] == 'active' ? 'Active' : 'De-active' }}
                  </span>
                                </div>
                                <div *ngSwitchCase="'paymentStatus'" class="w-75">
                                    <span [class]="'status-badge  px-3 py-2  d-block text-center'" [ngClass]="{'status-paid' : rowData[col.field] == 'paid','status-refound' : rowData[col.field] == 'refund','status-yetToPay' : rowData[col.field] != 'refund' && rowData[col.field] != 'paid'}">
                    {{rowData[col.field] == 'paid' ? 'Paid' : rowData[col.field] == 'refund'? 'Refund' : 'Yet To Pay' }}
                  </span>
                                </div>
                                <div *ngSwitchCase="'onlinePaymentStatus'" class="w-75">
                                    <span [class]="'status-badge  px-3 py-2  d-block text-center'" [ngClass]="{'status-paid' : rowData[col.field] == 'SUCCESS','status-refound' : rowData[col.field] == 'PENDING','status-yetToPay' : rowData[col.field] != 'PENDING' && rowData[col.field] != 'SUCCESS'}">
                    {{rowData[col.field] == 'SUCCESS' ? 'Success' : rowData[col.field] == 'PENDING'? 'Pending' : 'Yet To Pay' }}
                  </span>
                                </div>
                                <div *ngSwitchCase="'myPaymentStatus'" class="w-75">
                                    <span [class]="'status-badge  px-3 py-2  d-block text-center'" [ngClass]="{'status-paid' : rowData[col.field] == 'paid','status-refound' : rowData[col.field] != 'paid'}">
                    {{rowData[col.field] == 'paid' ? 'Received' : 'Not Received' }}
                  </span>
                                </div>
                                <div *ngSwitchCase="'sessionStatus'" class="w-75">
                                    <span [class]="' px-3 py-2  d-block'" [ngClass]="{'session-completed' : rowData[col.field] == 'Completed','session-notCompleted' : rowData[col.field] == 'Not Completed'}">
                    {{rowData[col.field] }}
                  </span>
                                </div>
                                <div *ngSwitchCase="'actions'" style="width: max-content">
                                    <div class="p-align-center p-d-flex">
                                        <!--fa-pencil-alt-->
                                        <span *ngIf="hasAction(col,'edit')" class="px-1" (click)="editClicked(rowData)"><i
                        class="pi pi-pencil pi-span  w-span"></i></span>
                                        <span *ngIf="hasAction(col,'view')" class="px-1" (click)="viewClicked(rowData)"><i
                        class="pi pi-eye pi-span w-span"></i></span>
                                        <!--  enable this for confirm popup -->
                                        <!-- <p-confirmPopup  ></p-confirmPopup> -->
                                        <span *ngIf="hasAction(col,'accept')" class="px-1" (click)="acceptClicked(rowData)"><i
                        class="pi pi-check-circle pi-span text-success span-hover"></i></span>
                                        <span *ngIf="hasAction(col,'reject')" class="px-1" (click)="rejectClicked(rowData)"><i
                        class="pi pi-times-circle pi-span text-danger span-hover"></i></span>
                                        <span *ngIf="hasAction(col,'delete')" class="px-1" (click)="deleteClicked($event,rowData)"><i
                        class="pi pi-trash pi-span  text-danger"></i></span>
                                        <button *ngIf="hasAction(col,'paynow')" [disabled]="rowData['PaymentStatus']!='not_paid'" class="cre-met-btn Delete_Dialog px-1" (click)="paynowClicked(rowData)">Pay Now</button>
                                        <ng-container *ngIf="hasAction(col,'createMeeting')">
                                            <button *ngIf="rowData['is_created']==0 && !isTutor" class="cre-met-btn Delete_Dialog px-1" (click)="createMeetingClicked(rowData)">Create Meeting</button>
                                            <ng-container *ngIf="rowData['is_created']==1">
                                                <app-session-timer [meeting_session_date]="rowData['meeting_session_date']" (joinClicked)="joinNowClicked(rowData)"></app-session-timer>
                                            </ng-container>
                                        </ng-container>
                                        <p-inputSwitch *ngIf="hasAction(col,'switch')" (onChange)="onSwitchChange($event,rowData)" [(ngModel)]="rowData['statusBoolean']"></p-inputSwitch>

                                    </div>
                                </div>

                            </div>
                        </ng-container>

                    </td>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>

                <label class="p-my-4">
          No records found.

        </label>
            </tr>
        </ng-template>


    </p-table>



    <!-- for enable Pagination from backend just uncomment this line then remove pagination properies from table above -->


    <p-paginator *ngIf="isPagination" (onPageChange)="onPageChange($event)" [showCurrentPageReport]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="total"></p-paginator>

</div>