import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {
  constructor(
      private router: Router,
      private loginService:LoginService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    const user = this.loginService.getUserInfo();
    if (user) {
        if(user.Type=='admin')
      this.router.navigate(['adminDashboard']);
      else if (user.Type=='tutor')
      this.router.navigate(['tutorDashboard']);
      return true;
    }
    this.router.navigate(['landingpage']);
    return false;
  }
}
