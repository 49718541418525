<div class="card p-p-4 h-100">
    <h5>{{title}}</h5>
    <p-chart type="pie" [options]="options" [data]="data" [width]="500" class="p-mb-6"></p-chart>




    <ng-container *ngFor="let item of labels_type;let i = index">
        <div class="row p-mb-3">
            <div class="col-6">
                <ng-container *ngIf="item=='Total Sales';else notTotal">
                    <label class="bold-txt h4"> 
                        {{item}}</label>
                </ng-container>
                <ng-template #notTotal>
                    <label class="bold-txt"> 
                        <span class="span-badge  align-text-bottom" [ngStyle]="{  'background-color': '#'+colors[i] }">
                        </span>
                        {{item}}</label>
                </ng-template>

            </div>
            <div class="col-6 text-end bold-txt">
                {{dataset_type[i]}}
            </div>
        </div>
    </ng-container>
</div>