<div class="row p-p-2 p-mt-3 align-items-center">
    <div class="bold-txt text-center col-1">
        <i class="fa fa-filter" style="color: #7ACCCE;"></i> Filter </div>

    <div class=" mar-dat-none col-2">
        <p-calendar inputId="basic" [formControl]="fromDate" [showIcon]="true" placeholder="from date"></p-calendar>
    </div>
    <div class=" mar-dat-none col-2">
        <p-calendar inputId="basic" [formControl]="toDate" [showIcon]="true" placeholder="to date"></p-calendar>
    </div>
    <div class="col-1">
        <button class="btn w-btn-info" (click)="updateDashboard()">
            Update
        </button>
    </div>
    <div class="col-1">
        <button class="btn w-btn-info" (click)="reset()">
            Reset
        </button>
    </div>
</div>


<!-- <div class="bold-txt p-mt-2 p-ml-3"> OverView </div> -->

<div class="row w-100 p-mt-3">
    <div class="col-lg-3 p-mt-3" *ngFor="let card of cards">
        <app-dashboard-card [title]="card.title" [boldTitle]="card.boldTitle" [counts]="card.counts" [image]="card.image"> </app-dashboard-card>
    </div>
</div>
<div class="row p-p-2 p-mt-3">

    <div class="col-lg-4">
        <app-tutor-statistics [countTutorStatistics]="countTutorStatistics"></app-tutor-statistics>
    </div>
    <div class="col-lg-4">
        <app-total-sales [colors]="colorsTotalSales" [countTotalSales]="countTotalSalesAll" title='Total Sales'></app-total-sales>
    </div>
    <div class="col-lg-4">
        <app-total-sales [colors]="colorsSalsPerPlan" [countTotalSales]="countTotalSalesPlan" title='Sales Per Plan'></app-total-sales>
    </div>

</div>



<hr class="p-mx-3">
<div class="row p-p-2">
    <div class="col-lg-8">
        <app-subscribers-stats [countSubscribersStats]=countSubscribersStats></app-subscribers-stats>
    </div>
</div>