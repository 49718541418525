import { Injectable } from '@angular/core';

import * as OT from '@opentok/client';
import config from '../config';

@Injectable()
export class OpentokService {

  session: OT.Session;
  token: string;

  constructor() { }

  getOT() {
    return OT;
  }

  initSession(TOKEN,SESSION_ID) {
    console.log('SESSION_ID service opentok')
    console.log(SESSION_ID)
    if ( TOKEN && SESSION_ID) {
      console.log('apiiiiiiiiiiiiiiiiiiiiiiiiii')
      this.session = this.getOT().initSession('47271114', SESSION_ID);
      this.token = TOKEN;
      return Promise.resolve(this.session);
    } /*else {
      return fetch(config.SAMPLE_SERVER_BASE_URL + '/session')
        .then((data) => data.json())
        .then((json) => {
          this.session = this.getOT().initSession(json.apiKey, json.sessionId);
          this.token = json.token;
          return this.session;
        });
    }*/
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          console.log('i connected')
          resolve(this.session);
        }
      });
    });
  }
  disconnect(){
    this.session.signal({
      type: "tutorDisconnect",
     

    },
      (error) => {
        if (error) {

        } else {
      
          this.session.disconnect();


        }
      }

    );

   
  }
}
