import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberInterface } from 'src/app/interfaces/subscriber.interface';
import { OpentokService } from 'src/app/opentok.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';
const BaseUrl = environment.BaseUrl;
@Component({
  selector: 'app-publisher-all',
  templateUrl: './publisher-all.component.html',
  styleUrls: ['./publisher-all.component.css']
})

export class PublisherAllComponent implements OnInit {

  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  publisher;
  publishing: Boolean;
  ListStat: boolean = false;
  SubscribersList= new Set< SubscriberInterface>();
  globalSessionId: string = '';
EndSession:Boolean=false;
  chatD = false;
  dateCh :any;
  globalArchiveI: string;
  state=  {
    connected: false,
    active: false,
    publishers: null,
    subscribers: true,
    meta: null,
    localAudioEnabled: true,
    localVideoEnabled: true,
  };

;
  connectingMask=false;
  startMask=false;
  controls=false;
  audioStyleVar=true;
  videoStyleVar=false;
  audioVar=false;
  videoVar=false;
  sessionIdRoom:any;
  tokenSession:any;
  nameJoiner:any;
  typeJoiner:any;
  videoSlashVar=false;
  audioSlashVar=false;
  SendData = { 'id': '' };
  messageTu:string;
  countSub:number=0;

  connectionCount = 0;
  @ViewChild('SubI') SubI;
  constructor(   private dataService: DataService,private ref: ChangeDetectorRef, private opentokService: OpentokService,  public http: HttpClient, private sharedService:SharedService,   private route: ActivatedRoute,    private router:Router) {

    const id = this.sharedService.decryptData(this.route.snapshot.params.id)

console.log(this.route.snapshot.params.id)
    this.SendData = { 'id': id };
    this.changeDetectorRef = ref;


  }

  async ngOnInit () {


    try { var data = await this.dataService.post(this.SendData,`getSession`).toPromise()
    this.nameJoiner = data.body['Result']['data']['user'].FullName;
  this.typeJoiner = data.body['Result']['data']['user'].type;
  this.tokenSession = data.body['Result']['data']['token'];
  this.sessionIdRoom=data.body['Result']['data']['sessionId'];



  } catch(err) {

    if(err.status == 400 || err.status == 500)
        {  this.router.navigateByUrl('/upcominglivestream').then(() => {

            });





        }
  }

  console.log(data.body)

 /*   this.dataService.post(this.SendData,`getSession`).subscribe(
      data => {
    //console.log(data.body)

        localStorage['nameJoiner'] = data.body['Result']['data']['user'].FullName;
        localStorage['typeJoiner'] = data.body['Result']['data']['user'].type;
        localStorage['sessionIdRoom'] = data.body['Result']['data']['sessionId'];
        localStorage['tokenSession'] = data.body['Result']['data']['token'];
        this.sessionIdRoom=data.body['Result']['data']['sessionId'];

      },err=>{

        if(err.status == 400 || err.status == 500)
        {  this.router.navigateByUrl('/upcominglivestream').then(() => {

            });





        }

      }
    ); */

    this.SubscribersList= new Set< SubscriberInterface>();

    this.opentokService.initSession(this.tokenSession,this.sessionIdRoom).then((session: OT.Session) => {
      this.session = session;
      this.session.on('streamCreated', (event) => {
        this.streams.push(event.stream);
        this.changeDetectorRef.detectChanges();
      });
      this.session.on('streamDestroyed', (event) => {
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          this.changeDetectorRef.detectChanges();
        }
      });
    })
    .then(() => this.opentokService.connect())
    .catch((err) => {
      console.error(err);
      alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
    });
  }
}
