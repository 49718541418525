import { ApplicationRef, ChangeDetectorRef, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthGuard } from './guards/auth.guard';
import { LayoutsComponent } from './layouts/layouts.component';
import { LoginService, USERPERMISSIONS } from './services/login/login.service';
import { Location } from "@angular/common";
import { AdminGuard } from './guards/admin.guard';
import { TutorGuard } from './guards/tutor.guard';
import { StudentGuard } from './guards/student.guard';
import { RedirectGuardService } from './guards/redirect-guard-service.guard';
import { PublisherTwoComponent } from './sharedModule/publisher-two/publisher-two.component';
import { ProfileComponent } from './layouts/components/profile/profile.component';
import { LandingPageComponent } from './sharedModule/landing-page/landing-page.component';
import { ExitService } from './services/exit-services/exit.service';
import { PaymentWebViewComponent } from './sharedModule/payment-web-view/payment-web-view.component';
import { PublisherAllComponent } from './sharedModule/publisher-all/publisher-all.component';
import { PaymentOtpComponent } from './sharedModule/payment-otp/payment-otp.component';
import { PaymentSuccessComponent } from './sharedModule/payment-success/payment-success.component';
import { PaymentMessageComponent } from './sharedModule/payment-message/payment-message.component';
import { PaymentFailComponent } from './sharedModule/payment-fail/payment-fail.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '', canActivate: [RedirectGuardService] },
  { path: 'payment', component: PaymentWebViewComponent },
  { path: 'paymentsuccess', component: PaymentSuccessComponent },
  { path: 'paymentmessage', component: PaymentMessageComponent },
  { path: 'paymentfail', component: PaymentFailComponent },


  { path: 'paymentotp/:data', component: PaymentOtpComponent },

  // { path: 'payment/Succeed', redirectTo:'payment/Succeeded', component: PaymentWebViewComponent },

  { path: 'payment/failed', component: PaymentWebViewComponent },
  { path: 'payment/Succeeded', component: PaymentWebViewComponent },


  { path: "login", loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: "website", loadChildren: () => import('./wesite-module/wesite-module.module').then(m => m.WesiteModuleModule) },
  { path: 'publisher', component: PublisherAllComponent,canDeactivate:[ExitService] },
  { path: 'publisherTwo', component: PublisherTwoComponent },
  {
    path: 'landingpage',
    component: LandingPageComponent
  },
  {
    path: 'landingpage/:lang',
    component: LandingPageComponent
  },
  {
    path: '', component: LayoutsComponent,
    canActivate: [AuthGuard],


    children: [
      { path: '', pathMatch: 'full', redirectTo: '', canActivate: [RedirectGuardService] },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'adminDashboard',
        loadChildren: () => import('./Modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'tutorDashboard',
        loadChildren: () => import('./TutorModules/tutor-dashboard/tutor-dashboard.module').then(m => m.TutorDashboardModule),
      },
      {
        path: "admins",
        loadChildren: () => import('./Modules/Admin/Admin.module').then(m => m.AdminModule),
        // canActivate:[AdminGuard]
      },
      {
        path: "tutors",
        loadChildren: () => import('./Modules/tutor/tutor.module').then(m => m.TutorModule),
        canActivate: [TutorGuard]
      },
      {
        path: "students",
        loadChildren: () => import('./Modules/student/student.module').then(m => m.StudentModule),
        canActivate: [StudentGuard]

      },
      {
        path: "payments",
        loadChildren: () => import('./Modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: "permissions",
        loadChildren: () => import('./Modules/permissions/permissions.module').then(m => m.PermissionsModule)
      },
      {
        path: "sessions",
        loadChildren: () => import('./Modules/sessions/sessions.module').then(m => m.SessionsModule)
      },
      {
        path: "subscriptions",
        loadChildren: () => import('./Modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
      },
      {
        path: "internalMarketing",
        loadChildren: () => import('./Modules/internal-marketing/internal-marketing.module').then(m => m.InternalMarketingModule)
      },
      {
        path: "tutorCancellation",
        loadChildren: () => import('./Modules/tutor-cancellation/tutor-cancellation.module').then(m => m.TutorCancellationModule)
      },
      {
        path: "manageCancellationPolicy",
        loadChildren: () => import('./Modules/manage-cancellation-policy/manage-cancellation-policy.module').then(m => m.ManageCancellationPolicyModule)
      },
      {
        path: "setting",
        loadChildren: () => import('./Modules/manage-setting/manage-setting.module').then(m => m.ManageSettingModule)
      },
      {
        path: "guaranteedSession",
        loadChildren: () => import('./TutorModules/guaranteed-sessions/guaranteed-sessions.module').then(m => m.GuaranteedSessionsModule)
      },
      {
        path: "upcominglivestream",
        loadChildren: () => import('./TutorModules/upcoming-live-stream/upcoming-live-stream.module').then(m => m.UpcomingLiveStreamModule)
      },
      {
        path: "tutorSessionHistory",
        loadChildren: () => import('./TutorModules/session-history/session-history.module').then(m => m.SessionHistoryModule)
      },
      {
        path: "mycancellation",
        loadChildren: () => import('./TutorModules/my-cancellation/my-cancellation.module').then(m => m.MyCancellationModule)
      },
      {
        path: "tutorsupport",
        loadChildren: () => import('./TutorModules/support/support.module').then(m => m.SupportModule)
      },
      {
        path: "tutorguide",
        loadChildren: () => import('./TutorModules/tutor-guide/tutor-guide.module').then(m => m.TutorGuideModule)
      },
      {
        path: "introvideo",
        loadChildren: () => import('./TutorModules/intro-video/intro-video.module').then(m => m.IntroVideoModule)
      },
      {
        path: "cancellationpolicy",
        loadChildren: () => import('./TutorModules/cancellation-policy/cancellation-policy.module').then(m => m.CancellationPolicyModule)
      },
      {
        path: "newletterSubscriber",
        loadChildren: () => import('./Modules/newletter-subscribers/newletter-subscribers.module').then(m => m.NewletterSubscribersModule)
      },
      {
        path: "customerSupport",
        loadChildren: () => import('./Modules/customer-support/customer-support.module').then(m => m.CustomerSupportModule)
      },
      {
        path: "CMS",
        loadChildren: () => import('./Modules/cms/cms.module').then(m => m.CMSModule)
      },
      {
        path: "Notifications",
        loadChildren: () => import('./Modules/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: "myPayment",
        loadChildren: () => import('./TutorModules/my-payment/my-payment.module').then(m => m.MyPaymentModule)
      },
      {
        path: "reports",
        loadChildren: () => import('./Modules/reports/reports.module').then(m => m.ReportsModule)
      },
    ],

  },
  {path: '**', redirectTo: '/landingpage'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true,scrollPositionRestoration:'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  route: string;

  constructor(private loginService: LoginService,
    private permissionsService: NgxPermissionsService,
    location: Location, router: Router,
    private ref: ApplicationRef
  ) {

    router.events.subscribe(val => {
      this.route = location.path();

      if (this.loginService.isLogged() && localStorage.getItem(USERPERMISSIONS)) {
        this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(USERPERMISSIONS)));

        if (this.loginService.getUserInfo()) {
          let role = this.loginService.getUserInfo().Type.toUpperCase()
          this.loginService.setRole(role);

        }
        this.ref.tick();
      }

    });
  }
}
