export class TutorModel {
  id                    : any;
  FullName              : string;
  UserId?               : string;
  Password?             : string;
  Email?                : string;
  email?                : string;
  EnglishType?          : string;
  PhoneNumber           : string | number;
  Gender?               : string;
  Address?              : string;
  Country?              : string;
  TutorCategory?        : string;
  TeachingQualification?: string;
  Qualifications?        : string;
  BreifVideo?           : string;
  photo_path_url?       : any;
  TeachingExperience?   : string;
  PayPal?               : string;
  Photo_Url?            : any[]=[];
  status?               : string;
  statusTutor?          : string;
  Nationality ?         : string;
  DateOfBirth ?         : string;
  CV?                   :any;
  init(){
    this.id=0;
    this.Address='';
    this.BreifVideo='',
    this.CV = '',
    this.Country='',
    this.Email='',
    this.FullName='',
    this.Gender='',
    this.Password='',
    this.PayPal='',
    this.PhoneNumber='',
    this.Photo_Url=[],
    this.TeachingExperience='',
    this.TeachingQualification='',
    this.TutorCategory='',
    this.UserId='',
    this.status='',
    this.photo_path_url=null
    this.Qualifications = '';
    this.Nationality = '';
    this.EnglishType = '';
    this.DateOfBirth = ''
  }
}
