<!-- START FIRST SECTION -->
<div class="container" style="max-width: 100% !important;">
    <div class="col-l2  " style="background-color: #24375D;">

        <app-website-header></app-website-header>
        <div class="row bg-websit">
            <div class="first-col align-self-center text-center-mobile p-pt-6">
                <div class="row p-pb-4">
                    <label class="text-main">Learn English with a Crowd</label>
                </div>
                <div class="row p-pb-2">
                    <label class="text-seconde">It's time to break the ice! Let's improve our English & public speaking
                    skills in front of an audience
                </label>
                </div>
                <div class="p-d-flex store-btn p-pl-2 p-pt-4    ">
                    <div class="p-pt-2 btn-store">
                        <a [href]="appStoreUrl" download target="_blank">
                            <div class="btn-group btn-sm group" role="group">
                                <button type="button" class="btn store" style="background-color: #70B8C7;"><img src="assets/images/websiteImages/appleStore.svg" class="appel" ></button>
                                <button type="button" class="btn w-btn-info store    bold-txt">App Store</button>
                            </div>
                        </a>
                    </div>
                    <div class="p-pt-2 btn-store">
                        <a [href]="googleStoreUrl" download target="_blank">
                            <div class="btn-group btn-sm group" role="group">
                                <button type="button" class="btn store" style="background-color: #70B8C7;"><img src="assets/images/websiteImages/googlePlay.svg" class="appel" ></button>
                                <button type="button" class="btn w-btn-info store   bold-txt">Play Store</button>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-12 text-center">
                <img class="img-fluid" src="assets/images/websiteImages/heroBanner.svg" alt="">
            </div>

        </div>
    </div>


    <!-- END FIRST SECTION -->


    <!-- START WHY WRITALK SECTION -->
    <div class="col-l2 p-pt-3 p-pb-6" style="background-color: white;" id="WhyWritalk">
        <div class="row bg-white">
            <div class="first-col text-center">
                <img class="img-fluid" src="assets/images/websiteImages/WhyWritalk.svg" alt="">
            </div>
            <div class="seconed-col p-mt-6">
                <div class="row">
                    <label class="text-primary-main">WHY WRITALK ?</label>
                </div>
                <div class="row p-mt-2">
                    <label class="text-primary-seconde" style="color: #7D7D7D;">The platform allows you to improve your
                    English & public speaking skills via live
                    broadcasts with English Tutors. Writalk
                    empowers confidence by its unique &
                    interacvtive crowd learning methodlogy.
                </label>

                </div>
                <div class=" p-mt-6 p-d-flex align-items-center">
                    <img class="p-mr-2 span-img" src="assets/images/websiteImages/arrowIcon.svg" alt="">
                    <div class="span-text">Group Classes with Friends.</div>
                </div>
                <div class=" p-mt-6 p-d-flex align-items-center">
                    <img class="p-mr-2 span-img align-items-center" src="assets/images/websiteImages/arrowIcon.svg" alt="">
                    <div class="span-text">Unlimted Plans, No Limits.</div>
                </div>
                <div class=" p-mt-6 p-d-flex align-items-center">
                    <img class="p-mr-2 span-img" src="assets/images/websiteImages/arrowIcon.svg" alt="">
                    <div class="span-text">Varaity of Native English Tutors.</div>
                </div>
                <div class=" p-mt-6 p-mb-4 p-d-flex align-items-center">
                    <img class="p-mr-2 span-img" src="assets/images/websiteImages/arrowIcon.svg" alt="">
                    <div class="span-text">Intensive Public Speaking Training</div>
                </div>
            </div>


        </div>
    </div>
    <!-- END WHY WRITALK SECTION -->



    <!-- START Third SECTION -->
    <div class="col-l2  " style="background-color: #7ACCCE;">
        <div class="row justify-content-between align-items-center p-p-4" style="background-color: #7ACCCE;">
            <div class="col-lg-2 text-center">
                <img class="info-image" src="assets/images/websiteImages/onlineSessions.svg" alt="">
                <div class="p-my-3 bold-txt info-text " style="position: relative;bottom: -5px;">
                    +100 Monthly Classes
                </div>
            </div>

            <div class="col-lg-2 text-center">
                <img class="info-image" src="assets/images/websiteImages/teacher.svg" alt="">
                <div class="p-my-3 bold-txt info-text">
                    < 1$ Hourly Fees </div>
                </div>

                <div class="col-lg-2 text-center">
                    <img class="info-image" src="assets/images/websiteImages/easyLearning.svg " alt=" ">
                    <div class="p-my-3 bold-txt info-text ">
                        24hr Lesson Review
                    </div>
                </div>

                <div class="col-lg-2 text-center ">
                    <img class="info-image " src="assets/images/websiteImages/comfortableTiming.svg " alt=" ">
                    <div class="p-my-3 bold-txt info-text ">
                        100% Truly Unlimted
                    </div>
                </div>

                <div class="col-lg-2 text-center ">
                    <img class="info-image " src="assets/images/websiteImages/support.svg " alt=" ">
                    <div class="p-my-3 bold-txt info-text " style="position: relative;bottom: -9px;">
                        24 Hour Customer Service
                    </div>
                </div>
            </div>
        </div>
        <!-- END Third SECTION -->



        <!-- START How dose it Work SECTION -->
        <div class="col-l2 p-pt-4 " style="background-color: white;" id="Howdoesitwork">
            <div class="row p-pt-4 text-center bg-white">
                <div class="text-primary-main">
                    How does it Work?
                </div>

            </div>
            <div class="row justify-content-around align-items-end bg-white p-pb-6">
                <div class="col-lg-2 col-xl-3 col-12 text-center">
                    <img class="  p-mt-3 how-img" src="assets/images/websiteImages/ChooseYourTeacher.svg" alt="">
                    <div class="how-text bold-txt" style="margin-top: 20px;">
                        Attend Free Classes
                    </div>
                </div>
                <div class="col-lg-1 col-12 text-center align-self-center">
                    <img class="w-100 arrow" src="assets/images/websiteImages/greenArrow.svg" alt="">

                </div>
                <!-- /////////// -->
                <div class="col-lg-2 col-xl-3 col-12 text-center">
                    <img class="how-img  p-mt-3" src="assets/images/websiteImages/SubscribeToOurPlans.svg" alt="">
                    <div style="margin-top: 40px;" class="how-text bold-txt">
                        Subscribe to Our Plans
                    </div>
                </div>
                <div class="col-lg-1 col-12 text-center align-self-center">
                    <img class="w-100 arrow" src="assets/images/websiteImages/greenArrow.svg" alt="">

                </div>
                <div class="col-lg-2 col-xl-3 col-12 text-center">
                    <img class="how-img p-mt-3" src="assets/images/websiteImages/StartYourJourney.svg" alt="">
                    <div style="margin-top: 40px;" class="how-text bold-txt">
                        Start Your Journey
                    </div>
                </div>
            </div>
        </div>
        <!-- END How dose it Work SECTION -->
        <div class="row text-center p-pt-6">
            <label class="text-primary-main">Join our Tutors</label>
        </div>
        <div class="row justify-content-around p-my-4">
            <div class="col-lg-3 col-md-6 col-10 my-3">
                <div class="card h-100">
                    <img class="card-img-top p-p-3 w-50  align-self-center mb-4 " src="assets/images/icons/video-conference.png">
                    <div class="card-body p-pt-4 position-relative" style="bottom: -6%;">
                        <div class="bold-txt text-center p-pb-2 join-text">Higher Earning</div>
                        <p class="card-text text-center join-text p-pb-4">
                            Teach a group of students at the same time and get continous increasing earnings via Paypal weekly </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-10 my-3">
                <div class="card h-100">
                    <img class="card-img-top p-p-3 w-50  align-self-center" src="assets/images/icons/analytics.png" alt="Card image cap">
                    <div class="card-body mb-4">
                        <div class="bold-txt text-center join-text">Less Classes</div>
                        <p class="card-text text-center join-text">
                            With our group method of teaching, you have less teaching hours per day with better returns, better Lifstyle. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-10 my-3">
                <div class="card h-100">
                    <img class="card-img-top p-p-3 w-50  align-self-center" src="assets/images/icons/video.png" alt="Card image cap">
                    <div class="card-body mb-4">
                        <div class="bold-txt text-center join-text">Relliabel Sessions</div>
                        <p class="card-text text-center join-text">
                            Pick your own classes based on your desire for Guarnteed paying & attending students </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="row text-center p-pb-4">
            <div class="btns-auth">
                <div class="p-mx-3">
                    <a class="btn w-btn-info  btn-sm li-header btn-font-size p-p-1 bold-txt" routerLink='/website/TutorRegister' style="width: max-content; padding-inline: 4vw !important;">Become a Tutor</a>
                </div>
            </div>
            <div class="btns-auth-mobile text-center" style="place-content: center;">
                <div class="p-mx-1">
                    <a class="btn w-btn-info btn-sm li-header btn-font-size p-p-1 bold-txt " routerLink='/website/TutorRegister' style="width: max-content; padding-inline: 4vw !important;">Become a Tutor</a>
                </div>

            </div>
        </div>
    </div>
    <app-footer></app-footer>
    <app-whatsapp-floating></app-whatsapp-floating>
