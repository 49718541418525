import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translate/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
   appStoreUrl= environment.appStoreUrl;
   googleStoreUrl = environment.googleStoreUrl;
   lang : string ='en'
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private translationService:TranslationService
    ) { }
  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.lang = res.lang=='ar' ? res.lang : 'en';
      this.translationService.setLanguage(this.lang);

    })
  }
  clicked(){
    this.router.navigate(['website'])
  }

  goTo(url:string){
    this.router.navigateByUrl(url)
  }

  OnLangChange(event){
    if(this.lang=='ar')
    this.router.navigate(['landingpage',this.lang])
    else
    this.router.navigate(['landingpage'])

  }
}
