import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-website-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() linkClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  OnLinkClicked(id) {
   this.linkClicked.emit(id);
   document.getElementById(id).scrollIntoView();
  }
  OnLinkChange(event){
    this.OnLinkClicked(event.target.value)
  }
}
