import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrk-skeleton',
  templateUrl: './wrk-skeleton.component.html',
  styleUrls: ['./wrk-skeleton.component.css']
})
export class WrkSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
