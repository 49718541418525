<div class=" dash-card">

    <div class="card-body">
        <div class="card-title">
           {{title}} <span class="bold-txt">{{boldTitle}}</span> 
        </div>
        <div class="p-d-flex p-justify-between p-mt-3">
            <div class="card-count">
                {{counts}}
    
            </div>
            <div>
                <img class="card-image" [src]="image" alt="">
            </div>
        </div>
        
    </div>
</div>