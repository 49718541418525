<!DOCTYPE html>
<html lang="en">

<head>

    <title>Add Admin</title>
</head>

<body>
    <app-notification-popup (close)="closeClicked($event)" [show]="showNotificationsList"></app-notification-popup>

    <!-- ------------------------------------------------ side menu-- ------------------------------------------------>

    <app-side-menu (selectItem)="changeSelectedItem($event)"></app-side-menu>
    <!-- ------------------------------------------------ End of side menu-- ------------------------------------------------>
    <!-- ------------------------------------------------ Top Nav ----------------------------------------------------------->
    <section id="contents" class="mobile-active">

        <app-header (onShowNotification)="onShowNotification($event)"></app-header>
        <!-- ------------------------------------------------ End Top Nav ----------------------------------------------------------->
        <!-- page contents here-->


        <!-- ------------------------------------------------ End Top Nav ----------------------------------------------------------->
        <!-- page contents here-->
        <router-outlet></router-outlet>

    </section>
    <!-- page contents End-->

    <!--notification popup-->

    <app-notification-popup>

    </app-notification-popup>
    <!--End notification popup-->

</body>

</html>