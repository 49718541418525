import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-churn-rate',
  templateUrl: './churn-rate.component.html',
  styleUrls: ['./churn-rate.component.css']
})
export class ChurnRateComponent implements OnInit {

  constructor() { }
  basicData : any;
  basicOptions:any;
  ngOnInit(): void {
    this.basicData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'],
      datasets: [
          {
            showInLegend: false,

              label: '',
              data: [65, 59, 80, 81, 56, 55, 40,45 ,50,40,38,50],
              fill: true,
              borderColor: '#4EA8DB',
              backgroundColor: '#E4F3F8',
              
          },
      ]
  }
  this.basicOptions = {
    legend: {
      position: 'bottom',
      display:false,
        labels: {
            fontColor: '#495057'
        }
    },
    
    scales: {
      yAxes: [{
        title: "Numbers",
          ticks: {
              fontColor: '#495057'
          }
      }]
  }
};

  
  }
}
