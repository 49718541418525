import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardCardComponent } from '../../dashboard-card/dashboard-card.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscribersStatsComponent } from './subscribers-stats/subscribers-stats.component';

import {ChartModule} from 'primeng/chart';
import { TutorStatisticsComponent } from './tutor-statistics/tutor-statistics.component';
import { TotalSalesComponent } from './total-sales/total-sales.component';
import { ChurnRateComponent } from './churn-rate/churn-rate.component';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DashboardCardComponent,
    DashboardComponent,
    SubscribersStatsComponent,
    TutorStatisticsComponent,
    TotalSalesComponent,
    ChurnRateComponent,

  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    DashboardComponent,
  ]

})
export class DashboardModule { }
