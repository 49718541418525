<div class="p-card p-component wrk-card p-mb-4">
    <div class="p-card-body" style="font-size: 14px;">
        <div class="p-card-content">
            <div class="row w-100">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-3">
                            <span><i class="pi pi-book"></i></span> Session Name
                        </div>
                        <div class="col-lg-2 bold-txt">
                            {{session.Name}}
                        </div>

                        <!-- ////////// -->
                        <div class="col-lg-3">
                            <span><i class="pi pi-calendar"></i></span> Session Date
                        </div>
                        <div class="col-lg-2 bold-txt">
                            {{session.SessionDate}}
                        </div>
                    </div>
                    <div class="row p-mt-2">
                        <div class="col-lg-3">
                            <span><i class="pi pi-clock"></i></span> Session Time
                        </div>
                        <div class="col-lg-2 bold-txt">
                            {{session.SessionTime | customTime}}
                        </div>
                        <div class="col-lg-3">
                            <span><i class="pi pi-clock"></i></span> Session Duration
                        </div>
                        <div class="col-lg-2 bold-txt">
                            {{session.SessionDurationByMin }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <button *ngIf="(remainingHours==0 && remainingMinutes==0 &&remainingSecondes ==0)||(remainingHours==0 && remainingMinutes<=15) || isReady;else timer" class="btn w-btn-info col-lg-4 p-mr-2 w-auto" (click)="joinNow()"> JOIN NOW</button>
                        <ng-template #timer>
                            <div class="col-lg-4 p-mr-2 w-auto time-session">
                                <div class="bold-txt">
                                    <span class="p-mx-2">{{remainingHours}}</span> :
                                    <span class="p-mx-2">{{remainingMinutes}}</span> :
                                    <span class="p-mx-2">{{remainingSecondes}}</span>
                                </div>
                                <div style="font-size:8px">
                                    <span class="p-mx-1">HOURS</span> :
                                    <span class="p-mx-1">MINUTES</span> :
                                    <span class="p-mx-1">SECONDES</span>
                                </div>
                            </div>
                        </ng-template>
                        <button *ngIf="!this.isReady " class="btn w-btn-scondary col-lg-4 w-auto" (click)="cancelClicked(session)"> CANCEL</button>
                    </div>
                </div>
            </div>
            <div class="row w-100 p-mt-3 ">
                <div class="col-lg-2">

                    Description
                </div>
                <label class="bold-txt col-lg-10" style="font-size: 13px; overflow-wrap: break-word;">{{session.Description}}</label>
            </div>
        </div>
    </div>
</div>