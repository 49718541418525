import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationService, FilterMatchMode, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { AdminInterface } from 'src/app/interfaces/admin.interface';
import { ColumnInterface } from 'src/app/interfaces/column.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit,OnChanges {

  @Input() isPagination: boolean = true;
  @Input() list :AdminInterface[] | any[]
  @Input() scrollable:boolean = false;
  @Input() isTutor:boolean = false;

  @Input() columns : ColumnInterface[]

  // this event emmit when view button clicked
  @Output() view  = new EventEmitter<any>()

  // this event emmit when edit button clicked
  @Output() edit  = new EventEmitter<any>()

  // this event emmit when delete button clicked
  @Output() delete  = new EventEmitter<any>()

  // this event emmit when accept button clicked
  @Output() accept  = new EventEmitter<any>()

  // this event emmit when reject button clicked
  @Output() reject  = new EventEmitter<any>()

  // this event emmit when switch changed
  @Output() switch  = new EventEmitter<any>()

  @Output() paynow  = new EventEmitter<any>()


  @Output() pageChanged  = new EventEmitter<any>()
  @Output() sortChanged  = new EventEmitter<any>()


  @Output() button = new EventEmitter<any>()
  @Output() createMeeting = new EventEmitter<any>()
  @Output() joinNow = new EventEmitter<any>()



  @Input() loading: boolean = false;

  @Input() total: number | string = 10;
  interval;

  activityValues: number[] = [0, 100];
  matchModeOptions: SelectItem[];
  page =1;
  first = 1;
  field = 'id';
  order = 'desc'
  showpageReport = true
  constructor(
    private confirmationService: ConfirmationService,) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.list = [...this.list];

  }

  ngOnInit() {

    this.matchModeOptions = [
      { label: "Starts With", value: FilterMatchMode.STARTS_WITH },
      { label: "Contains", value: FilterMatchMode.CONTAINS },
      { label: "End With", value: FilterMatchMode.ENDS_WITH }
    ];
  }

  clear(table: Table) {
      table.clear();
  }

  onPageChange(event){
  
    this.page= event.page + 1;
    this.first = event.page + 1;
    this.pageChanged.emit({page:this.page,items_list:event.rows});
  }
  onSort(event){
    
    let sort = {
      field : event.field,
      order : event.order ==1 ? 'asc' : 'desc'
    }
    console.log(sort);
    console.log(this.order)

    if(this.order!=sort.order || this.field !=sort.field){
     this.field = sort.field
     this.order = sort.order
    console.log("Sort Emitted");

      this.sortChanged.emit(sort)
    }
  }
  editClicked(rowData){

    this.edit.emit(rowData)
  }

  viewClicked(rowData){
    this.view.emit(rowData)
  }
  paynowClicked(rowData){
    this.paynow.emit(rowData)

  }
  deleteClicked(event:Event, rowData){
    let i = this.list.indexOf(rowData)
    this.delete.emit({data:rowData,index :i});

    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete row?',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
          //confirm action

      },
      reject: () => {
          //reject action
      }
  });
  }
  acceptClicked(rowData){
    this.accept.emit(rowData);
  }
  rejectClicked(rowData){
    this.reject.emit(rowData);
  }
  buttonClicked(btn,rowData){
    this.button.emit({btn:btn,rowData:rowData})
  }
  createMeetingClicked(rowData){
    this.createMeeting.emit(rowData)
  }
  joinNowClicked(rowData){
    this.joinNow.emit(rowData)
  }
  onSwitchChange(event,rowData){
    let obj ={
      checked: event.checked,
      user_id : rowData['user_id'],
      id : rowData['id']

    }
    this.switch.emit(obj);

  }
  isChecked(status){
    return status =="active" ? true : false;
  }
  hasAction(col,op){
    return col.actionField.includes(op);
  }


}

