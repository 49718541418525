<div class="notification_wrapper" *ngIf="show">
    <div class="notification_clear">
        <span (click)="closeClicked()" class="notifi_osff">
         <img src="../../../../assets/images/close_noti.png" alt="icon" />
      </span>
        <div class="notifi_head">
            <h2>Notifications</h2>
            <!-- <span>Clear All</span> -->
        </div>
        <div class="listout_notifi">
            <ul>
                <li class="active" *ngFor="let noti of notifications">
                    <div class="notifi_details">
                        <div class="noitifi_img"><img src="../../../../assets/images/noti_bell.png" alt="icon"></div>
                        <div class="notifi_content">
                            <h4>{{noti.TitleNotifi}}</h4>
                            <span>{{noti.created_date}}</span>
                            <p>{{noti.BodyNotifi}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>