import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OpentokService } from 'src/app/opentok.service';
@Injectable({
  providedIn: 'root'
})
export class ExitService {
  toCck = false;
  constructor(private opentokService: OpentokService) { }

  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.toCck == true)
      { this.opentokService.disconnect();

        return true;

      }
    var txt;
    var rText = confirm("are you sure to leave this session ?");
    if (rText == true) {
      this.toCck = true;
      this.opentokService.disconnect();
      return true;

    } else {
      txt = "You pressed Cancel!";
      return false;

    }

  }



}
