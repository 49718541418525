import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { timer } from 'rxjs';

@Component({
  selector: 'app-sub',
  templateUrl: './sub.component.html',
  styleUrls: ['./sub.component.css']
})
export class SubComponent implements AfterViewInit {

  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;

  constructor(private messageService:MessageService ) { }

  ngAfterViewInit() {
    const EveryFiveSecond= timer(0,5000);
  var subscriber=  this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, {
 
      width: '100%' , height:'100%',
      preferredFrameRate:30,preferredResolution:{width: 920, height: 1280},
   

  }, (err) => {
        if (err) {
          alert(err.message);
        }
        else{
       
        }
      });


   
      //Test Quality
      EveryFiveSecond.subscribe(any => 
      
        subscriber.getStats((error:any,stats )=>{
        // let check= JSON.stringify (stats);
          //console.log('state Subscriber');
          //console.log('state error ' + error);
         // console.log('stateSu stats' + check);
//if(stats.audio.packetsLost > 9)
//this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Subscriber has a Network Problem' });
//console.log(stats.audio.packetsLost)
            })
    )
        
         //ETest 
  
    //subscriber.restrictFrameRate(true);
  }
}
