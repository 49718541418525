import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorGuard implements CanActivate {
  constructor(    private permissionsService:NgxPermissionsService,
    ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
     return this.permissionsService.hasPermission(['Mtutor']);
  }
}

export class AddTutorGuard implements CanActivate {
  constructor(    private permissionsService:NgxPermissionsService,
    ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
     return this.permissionsService.hasPermission(['Mtutor_add']);
  }
}
export class ViewTutorGuard implements CanActivate {
  constructor(private permissionsService:NgxPermissionsService,
    ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
     return this.permissionsService.hasPermission(['Mtutor_view']);
  }
}
