import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css']
})
export class DashboardCardComponent implements OnInit {
  @Input() title : string;
  @Input() boldTitle : string;

  @Input() counts: string|number;
  @Input() image : string;

  constructor() { }

  ngOnInit(): void {
  }

}
