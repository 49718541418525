import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, AfterViewInit, ViewChild, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { timer } from 'rxjs';
import { SubscriberInterface } from 'src/app/interfaces/subscriber.interface';
import { OpentokService } from 'src/app/opentok.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { ExitService } from 'src/app/services/exit-services/exit.service';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';



@Component({
  selector: 'app-pub',
  templateUrl: './pub.component.html',
  styleUrls: ['./pub.component.css']
})
export class PubComponent implements AfterViewInit {
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @ViewChild('startable')  startable: ElementRef;

  @Input() session: OT.Session;
  publisher: OT.Publisher;
  publishing: Boolean;
  ListStat: boolean = false;
  SubscribersList= new Set< SubscriberInterface>();
@Input() sessionIdRoom;
@Input() typeJoiner;
@Input() nameJoiner;

EndSession:Boolean=false;
  chatD = false;
  dateCh :any;
  globalArchiveI: string;
  publishingAbility=false;
  state=  {
    connected: false,
    active: false,
    publishers: null,
    subscribers: true,
    meta: null,
    localAudioEnabled: true,
    localVideoEnabled: true,
  };;
  connectingMask=false;
  startMask=false;
  controls=false;
  audioStyleVar=true;
  videoStyleVar=false;
  audioVar=false;
  videoVar=false;
  videoSlashVar=false;
  audioSlashVar=false;
  SendData = { 'id': '' };
  messageTu:string;
  countSub:number=0;
  connectionCount = 0;
  constructor(private opentokService: OpentokService, public http: HttpClient,
    private dataService: DataService,
    private messageService: MessageService,
   private router:Router,
   private ExitS:ExitService,
   private route: ActivatedRoute,
   private sharedService:SharedService) {
    this.publishing=false;;
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {insertMode: 'append',

      width: '100%' , height:'100%',
      publishAudio:true, publishVideo:true,
frameRate: 30,
resolution: '1280x720',
mirror:false
});

    if (this.session) {

      if (this.session['isConnected']()) {

        this.updateState({ active:true });
  // this.publish();
      }

        this.session.on('sessionConnected', () =>{
         // this.publish();

          this.updateState({ active:true })

        }




          );





              //Test Quality
              const EveryFiveSecond= timer(0,5000);
              EveryFiveSecond.subscribe(any =>

               this.publisher.getStats((error,stats )=>{
                 console.log('state publisher')
                 console.log('state error ' + error)
                 console.log('statePb stats ' + JSON.stringify (stats[0].stats)  )
                 if(stats[0].stats.video.frameRate < 26)
                 this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Your network gets down' });
                   })
           )

                //ETest
//Session Events

this.session.on("connectionCreated", (event) => {

  if (event.connection.connectionId != this.session.connection.connectionId) {


  this.connectionCount++;
  console.log('joineeeeeeed ' + this.connectionCount)

 let ContSent:string= String(this.connectionCount)  ;

  this.session.signal({
    type: "refreshCount",
    data: ContSent

  },
  function(error) {
    if (error) {

    } else {
      console.log("signal sent.");
    }
  }

  );

  }else{
    this.session.signal({
      type: "tutorJoined"


    },
      (error) => {
        if (error) {

        } else {
    console.log('signal sesntsssssssssss')


        }
      }

    );
    this.updateListSub();
  }

});

this.session.on("sessionDisconnected", (event) => {

  if (this.typeJoiner == 'tutor') {

              this.session.signal({
                type: "tutorDisconnect",
                data: this.sessionIdRoom

              },
                (error) => {
                  if (error) {

                  } else {

                    this.session.disconnect();

                  }
                }

              );




            }
  this.connectionCount--;


});
      this.session.on("connectionDestroyed", (event) => {

        this.connectionCount--;


    this.rv(event.connection);

    let ContSent:string= String(this.connectionCount)  ;




              this.session.signal({
          type: "refreshCount",
          data: ContSent

        },
        function(error) {
          if (error) {

          } else {
            console.log("signal sent.");
          }
        }

        );

      });

  /**/
      this.session.on("signal:text-chat", (event:any) => {
        let s:string
        this.dateCh = new Date();
        this.dateCh=this.dateCh.toLocaleTimeString();
        console.log(event.data)
        let x = JSON.parse(event.data);
       s=x['Message'];
        if(event.data != [] && x.Mentions != []){
      s =x.Message;
          let m=x.Mentions;


         for(let i=0;i<m.length;i++)
   { let name=m[i]['Name']
    let rx:RegExp=  new RegExp (/@\[__.*?__\]\(__.*?__\)/i);

   s =s.replace(rx,name);
  console.log(s)}


  }
        if(x.photo_path_url == null)
       x.photo_path_url= "../../../assets/images/defaultChat.jpg";



            let toCh =     `
            <div class="left-chat">
            <img class="img-ch" src="${x.photo_path_url}">
            <p class="p-ch">

            ${s}
            <span> <span class="span-ch-first"> ${x.FullName} </span>${this.dateCh}</span>
            </p>


      </div>`;
        var el = document.createElement('li');
        el.innerHTML = toCh;
        document.getElementById("holderCh").append(el);


        var scroll2 = document.getElementById('holderCh');

        scroll2.scrollTop = scroll2.scrollHeight;
        scroll2.animate({scrollTop: scroll2.scrollHeight});




      });

      this.session.on("signal:rejectRequest", (event:any) => {


        this.messageService.add({ severity: 'warn', summary: 'Info Message', detail: 'Subscriber has reject your mic request' });
        this.updateListSub();
      });
      this.session.on("signal:refreshWeb", () => {


  this.updateListSub();



      });
    }


    this.SubscribersList= new Set< SubscriberInterface>();
   /* var scroll = document.getElementById('messageTu');
    scroll.scrollTop = scroll.scrollHeight;
    scroll.animate({scrollTop: scroll.scrollHeight});*/


  }

  publish() {

this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishing = true;
        this.startArchive();
        this.mAll();
        this.updateListSub();
        this.session.signal({
          type: "tutorJoined"


        },
          (error) => {
            if (error) {

            } else {
        console.log('signal sesntsssssssssss')


            }
          }

        );

      }
    });
  }
//Function



startArchive() {




  let SessionId = {
    'GlobalSession': this.sessionIdRoom
  };



  this.dataService.post(SessionId,`startAch` ).subscribe(
    data => {
      console
      localStorage['archiveId'] = data['id'];

    }
  );
};
stopArchive() {


  let archId = localStorage['archiveId'];


  this.dataService.get(`stopAch/${archId}`).subscribe(
    data => {

    }
  );
};

addVisit(connect_id) {

  let SendData = {
    'connection_id': connect_id,
    'MySession_id': this.sessionIdRoom
  };



  this.dataService.post(SendData,`addVisit`).subscribe(
    data => {



    }
  );
}
mAll() {

  let SendData = {
    'MySession_id': this.sessionIdRoom
  };


  this.dataService.post(SendData,`muteAll`).subscribe(
    data => {

      this.updateListSub();


    }
  );


}


updateListSub() {
  let sendToGetVisit = {
    'MySession_id': this.sessionIdRoom

  };



  this.dataService.post(sendToGetVisit,`VisitsAll`).subscribe(
    data => {
      this.SubscribersList= new Set< SubscriberInterface>();
      console.log(data.body['Result'])

      for (let i = 0; i < data.body['Result']['data'][0]['clientsByAll'].length; i++) {

        const _Info: SubscriberInterface = {

          FullName: data.body['Result']['data'][0]['clientsByAll'][i]['FullName'],
          connection_id:  data.body['Result']['data'][0]['clientsByAll'][i]['connection_id'],
          statePub:  data.body['Result']['data'][0]['clientsByAll'][i]['statePub']
        };

        this.SubscribersList.add(_Info);
      }
           }
  );


  this.session.signal({
    type: "mute-unMute"

  },
    function (error) {
      if (error) {

      } else {

      }
    }
  );
}
tutorLf() {

  let SendData = {
    'MySession_id': this.sessionIdRoom,
    'EndState': this.EndSession
  };

console.log('on')

  this.dataService.post(SendData,`leftSession`).subscribe(
    data => {
  console.log('left');

  console.log(data);
    }
  );

  this.router.navigate(['..'])
}

updateStateStud(cId) {


  let SendData = {
    'MySession_id': this.sessionIdRoom,
    'cId': cId
  };
  let sendToGetVisit = {
    'MySession_id': this.sessionIdRoom

  };

  this.dataService.post(SendData,`ChangePub`).subscribe(
    data => {

    }
  );

  /////


  this.dataService.post(sendToGetVisit,`VisitsAll`).subscribe(
    data => {


     this.SubscribersList= new Set< SubscriberInterface>();
  this.updateListSub();



    }
  );

}

rv(cn) {

  let SendData = {
    'MySession_id': this.sessionIdRoom,
    'connection_id': cn
  };


  this.dataService.post(SendData,`rv`).subscribe(
    data => {


this.updateListSub();
    }
  );



}
rvAll() {

  let SendData = {
    'MySession_id': this.sessionIdRoom

  };


  this.dataService.post(SendData,`removeAll`).subscribe(
    data => {


this.updateListSub();
    }
  );



}
ChatTuSend(){
  let data={
'Message':this.messageTu,
'FullName':'Tutor: ' +this.nameJoiner ,
'type':'TUTOR',
'photo_path_url':null,
'Mentions':[]
  };
  let dataSend=  JSON.stringify(data)
  console.log(dataSend)
  this.session.signal({
    type: "text-chat",
    data: dataSend

  },
    (error) => {
      if (error) {

      } else {
  console.log('signal chat')


      }
    }

  );
  this.messageTu = ''
}
updateUI (update)  {
  const { connected, active } = this.state;

  switch (update) {
    case 'connected':
      console.log('1');
      if (connected) {
        console.log('2');
        this.connectingMask=true;
        this.startMask=false;


        this.updateListSub();

      }
      break;
    case 'active':
      if (active) {  console.log('3333333333333');

        this.startMask=true;
        this.connectingMask=true;



      }
      break;
    case 'meta':

      break;
    default:
      console.log('nothing to do, nowhere to go');
  }
};
 updateState (updates) {

  Object.assign(this.state, updates);
  Object.keys(updates).forEach(update => this.updateUI(update));
};
 startCall ()  {
   this.publish();
  this.publishingAbility=!this.publishingAbility;


  this.startable.nativeElement.remove();
this.startMask=false;
  this.ListStat=true;




    this.chatD=true;

    this.controls=true;


};
 toggleLocalAudio  () {
  this.audioStyleVar=!  this.audioStyleVar;
  this.audioVar=!  this.audioVar;
this.audioSlashVar=!this.audioSlashVar;


    const enabled = this.state.localAudioEnabled;

    this.publisher.publishAudio(!enabled);

    this.updateState({ localAudioEnabled: !enabled });
    const action = enabled ? 'add' : 'remove';

  };
   toggleLocalVideo()  {

    this.videoVar=!  this.videoVar;
    this.videoSlashVar=!  this.videoSlashVar;

   this.videoStyleVar=! this.videoStyleVar;
     const enabled = this.state.localVideoEnabled;


     this.publisher.publishVideo(!enabled);
     this.updateState({ localVideoEnabled: !enabled });
     const action = enabled ? 'add' : 'remove';

   };
    Tk() {

    if (this.typeJoiner == 'tutor') {
console.log('exit');
this.EndSession=true;
      this.ExitS.canDeactivate();
      if(this.ExitS.toCck == true)
      {console.log('Toexit');
     /* this.session.signal({
        type: "tutorDisconnect",
        data: this.sessionIdRoom

      },
        (error) => {
          if (error) {

          } else {
            alert('iii')
            this.session.disconnect();


          }
        }

      );*/
      this.tutorLf();

    }

    }



  };
}
