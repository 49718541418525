<div class="p-p-4  bold-txt bg-websit container " style="max-width: 100% !important;">
    <div class="row bold-txt">
        Privacy Policy
    </div>
    <div class="row bold-txt h3 p-my-2">
        WRITALK PRIVACY STATEMENT
    </div>
    <div class="row">

        All services given in the Site; writalk.com. and WRITALK Mobile Application are operated by WRITALK, LLC. (shall be hereafter referred to as WRITALK) located Shop 245, Building 2648, Road 5720, Block257, The Lagoon Amwaj Islands, The Kingdom of Bahrain.
        WRITALK may collect personal or non-personal data for various purposes. The manner of collection and protection of such personal data is explained below.
    </div>
    <div class="row bold-txt h3 p-my-2">
        HOW DO WE COLLECT AND PROCESS YOUR DATA?
    </div>
    <div class="row">
        <ul>
            <li class="li-initial">
                By filling forms and questionnaires in the web site, identification and communication data belonging to the users (such as, name – surname, company information, telephone number, address or e- mail addresses) are naturally collected by WRITALK in order
                to be able to provide our services.
            </li>
            <li class="li-initial">
                By using paid aspects of the Service, the User may be required to share billing and payment information with WRITALK’s third party payment processors in order for payments to be processed. If such a third-party payment processor is used, the payment transaction
                will be handled, and payment-related data will be stored and processed by such payment processor. You may review the relevant payment processor’s privacy policy during the transaction.
            </li>
            <li class="li-initial">
                WRITALK may also collect and use information about the User’s information with the service in a manner and format that does not identify you as an individual non personal identifiable information solitary such as analytic information, cookies information
                and log file information, IP address, browser type. WRITALK may use third party analytic tools to measure traffic and usage trends for the Service for its legitimate interests to improve its website and services.
            </li>
            <li class="li-initial">
                WRITALK; from time to time, may send to the users’ campaign information, information about new releases, promotion offers. Users may either accept or not such types of commercial communications and later on may change its decision or may terminate the
                commercial communication by notifying to the contact included in the information transmission received by him. If you are in a country that requires opt-in consent, such commercial communications will only be sent to you if you consent
                to receive them.
            </li>
            <li class="li-initial">
                During the communication process, which is either performed over WRITALK by e- mail, personal data transmitted by the user from the electronic environment shall be never disclosed to third parties except for the purposes and scope determined by the "
                User’s Service Contract without prejudice to the cases designated here, such as affiliates and/or third party payment processors ". WRITALK may share or disclose the personal data with third party business partners or its affiliates with
                the purpose of providing its service to the User.
            </li>
            <li class="li-initial">
                For the definition of problems regarding the system and for fast solution of challenges or controversies which may occur in relation with the service rendered, WRITALK records the users’ IP address and uses them. IP addresses and Scanner info, Mobile
                Application Data may be also used for the general definition of the users and for collection of demographic data. Log file information is automatically reported by WRITALK browser each time the User accesses a web page.
            </li>
            <li class="li-initial">
                WRITALK may use the collected personal data both for enabling contact with the user when necessary and for the purposes and scope determined by the "User’s Contract" as anonymous (without disclosure of the identity) in terms of various statistical assessments,
                database formation and market surveys.
            </li>
            <li class="li-initial">
                WRITALK hereby undertakes that it shall keep the personal data strictly confidential and private, it shall be liable to keep such data as confidential business information and shall take necessary precautions in order to prevent the unauthorized use of
                the confidential information and disclosure of such information to third parties.
            </li>
            <li class="li-initial">
                WRITALK does not record and keep the credit card information of the customers who are shopping at the electronic stores. WRITALK uses the third party payment service provider regarding online payments such as Stripe Inc. The Users are directed to this
                third-party payment processor Stripe Inc. The User acknowledges and accepts that WRITALK shall not be responsible for security of the operations. Only the user itself may reach the information which he submitted to the electronic trading
                site and may change them. In case the personal data of the user is being protected safely, it is unable to be accessed by third parties and no alterations can be made. WRITALK does not have access to this payment information other than
                the amount of the User’s purchase and information identifying the User as the purchaser. All uses of billing and payment information are governed by the respective policies of those third parties.
            </li>
            <li class="li-initial">
                Reliability of the payment/ invoice/ address information of the orders which are introduced on- line by credit card are inspected by WRITALK’s third party service providers such as Stripe Inc. or by WRITALK sometimes against credit card fraud. For this
                reason, particularly, the accuracy of the financial and address/ telephone number information of some users who are placing order for the first time may be required to be approved. For the control of such data, communication may be provided
                with the credit card holder or with the bank, or third party payment processors if necessary.
            </li>
            <li class="li-initial">
                WRITALK may provide links to other sites within the scope of the web site and mobile application. Therefore, no responsibility is undertaken for the confidentiality applications and the contents of the sites which are accessed through these links. Commercials
                published in the electronic trading site are shown/ distributed to the users through the business partners involved in advertising. Privacy statement in this contract is only related to the use of WRITALK web sites and does not involve
                third party web sites. This Privacy Policy applies only to information collected by the writalk.com Web Site and Mobile Application.

            </li>
            <li class="li-initial">
                In limited cases stated below, WRITALK may declare to the third parties the user’s acquired information except the information which are subject to this "Privacy Statement” provisions. These exceptions are applicable for the following conditions;
                <ul>
                    <li class="p-my-1">
                        1. by virtue of the legal obligations such as law, by- law, regulation and other applicable legal rules;
                    </li>
                    <li class="p-my-1">
                        2. To fulfill and implement the requirements of the “Membership Contract” which is signed between WRITALK and the users and other contracts;
                    </li>
                    <li class="p-my-1">
                        3. In case relevant information is claimed by an authorized administrative or judicial authority for the duly execution of an investigation or interrogation;
                    </li>
                    <li class="p-my-1">
                        4. Cases where giving information is necessary for the protection of the users’ rights or for their safety.
                    </li>
                </ul>


            </li>
            <li class="li-initial">
                WRITALK never claims credit card number, user’s name, password in the e-mails. Data included in the e –mails may be seen by third parties. WRITALK cannot guarantee the security of the data transmitted from the user’s e-mails.
            </li>
            <li class="li-initial">
                If the User’s personally identifiable information changes, or if he no longer wants to use this Service, he may correct, update, delete or deactivate it by making the change on the User information page, e-mailing at info@writalk.com, or by other available
                contacting options at writalk.com.
            </li>
            <li class="li-initial">
                WRITALK follows generally accepted industry standards to protect the submitted personal information, both during transmission and once it has been received. However, no method of transmission over the Internet, or method of electronic storage, is 100%
                secure. WRITALK cannot guarantee those data’s absolute security, unconditionally.
            </li>
            <li class="li-initial">
                WRITALK; by using a technical communication file (Cookie), may obtain the users list who are visiting the web site and the users’ information regarding the use of the web site. Subject technical communication files are small text files which are sent
                by an internet site user to the browser so as to be stored in the main memory. Technical communication file is helpful for receiving statistical data about the number of people who visited the site, the purpose and quantity of the visit
                made by a single user and the time they remained in the site and for the production of commercials and content, dynamically, from the users’ pages which are specifically designed for the users. Technical communication file has not been
                designed for receiving data or further personal data in the main memory or e- mails. Most of the browsers have been designed to accept the technical communication file in the beginning but they may be readjusted upon the user’s request
                so that a warning should be available in case the technical communication file is not received or when the file is sent by the other party.
            </li>
            <li class="li-initial">
                WRITALK; unless permitted or approved by the user, does not record any communication in a mobile application, audio or video communication. Upon the user’s request, the audio and video communication may be recorded during the execution of the service
                and may be deleted later on.
            </li>
        </ul>
    </div>
    <div class="row bold-txt h3 p-my-2">
        International Data Transfers
    </div>
    <div class="row p-my-3">
        The User’s information collected through the service may be stored and processed in the Kingdom of Bahrain or any other countries where WRITALK has facilities, or the countries its subsidiaries, affiliates or service providers maintain facilities.
    </div>
    <div class="row p-my-3">
        WRITALK may any time change the provisions of this "Privacy Statement” by publishing in the site or by sending e-mail to the users or by publishing in the relevant site. In case the provisions of the Privacy Statement are amended, a new form shall be
        effective as of its publishing date.
    </div>
    <div class="row p-my-3">
        You may send e-mail to info@writalk.com address for your questions and suggestions regarding the Privacy Statement. </div>

    <div class="row bold-txt h3 p-my-2">
        Your Rights
    </div>
    <div class="row p-my-3">
        Your rights regarding your personal data may vary depending on the country. If you are unable to find your rights under your country’s data protection law, you may contact us by sending an e-mail to info@writalk.com and or by other available contacting
        options at writalk.com.
    </div>
</div>