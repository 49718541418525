import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
declare var init: any;
@Component({
  selector: 'app-payment-web-view',
  templateUrl: './payment-web-view.component.html',
  styleUrls: ['./payment-web-view.component.css']
})
export class PaymentWebViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private dataService: DataService,   private router: Router,) { }
  transaction_id: any = '';
  symbol: string = '';
  access_token: any = '';
  amount: number = 1;
  description: string = 'Test'
  ngOnInit(): void {

    // window.open('assets/html/payment.html','_blank', 'toolbar=0, width=800, height=400')
    this.route.queryParams.subscribe(res => {console.log(res)
    console.log('payment web view main');
    if(res['status'] != null && res['status'] == 'paid')
    {console.log('success paayyyy')
    console.log(this.amount)
      this.router.navigate(['paymentsuccess']);
    }
    if(res['status'] != null && res['status'] != 'paid')
    {console.log('failed paayyyy')
      this.router.navigate(['paymentfail']);
    }


      this.transaction_id =res['transaction_id']
      this.description = `Buy subscription with transaction id ${this.transaction_id}`
      this.dataService.post({ TransactionId: this.transaction_id }, 'moyasarAmount').subscribe(
        res => {
          console.log(res);

          if (res.body['Result']['statusCode'] == 200) {
            if (res.body['Result']['data']) {
              this.amount = res.body['Result']['data']['price']
              this.symbol = res.body['Result']['data']['Symbol']
              new init(this.amount, this.description,this.transaction_id,this.symbol);
            }
          }
        }
      )
    })
  }

}
