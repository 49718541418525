<div class="container p-0" style="max-width: 100% !important;    overflow-x: hidden;">




    <app-website-header (linkClicked)="linkClicked($event)">
    </app-website-header>
    <div id="top" class="row p-pt-3 bg-websit main-h">
        <div class="first-col-login text-center h-100 bg-websit column-display">
            <!-- <img class="img-fluid " src="assets/images/websiteImages/img8.png" alt=""> -->
            <img class="img-fluid tutor-image" src="assets/images/websiteImages/Tutor.svg" alt="">
        </div>
        <div class="seconed-col  p-mt-3 text-center bg-websit" style="height: max-content;">
            <div class="row">
                <label style="font-weight: 900;color: white;font-size: 40px;">Join our Tutors</label>
            </div>
            <div class="row">
                <label style="font-weight: 400;color: #D1D1D1;font-size: 20px;">Please fill the information
            </label>

            </div>
            <div class="row ">
                <form [formGroup]="registerForm" (ngSubmit)="register()">
                    <div class="row  justify-content-center">
                        <!-- Full Name -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.FullName.errors }">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>
                                <input formControlName="FullName" class="form-control contact-input" type="text" name="" id="" placeholder="Full Name">
                            </div>
                            <div *ngIf="isSubmitted && formControls.FullName.errors" class="help-block font-10">
                                <div *ngIf="formControls.FullName.errors.required">Full Name is required</div>
                                <div *ngIf="formControls.FullName.errors.minlength">Full Name must be at least 3 characters</div>

                            </div>
                        </div>
                        <!-- Gender -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.Gender.errors }">

                            <div class="input-group ">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>
                                <p-dropdown [options]="gender" formControlName="Gender" placeholder="Gender" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100% - 42px)'}">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isSubmitted && formControls.Gender.errors" class="help-block font-10">
                                <div *ngIf="formControls.Gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row  justify-content-center">
                        <!-- where do you live -->
                        <div class="col-lg-6  col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.Address.errors }">


                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>
                                <p-dropdown [options]="countries" formControlName="Address" placeholder="Where do you live ?" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100% - 42px)'}">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isSubmitted && formControls.Address.errors" class="help-block font-10">
                                <div *ngIf="formControls.Address.errors.required">Address is required</div>
                            </div>
                        </div>
                        <!-- Qualification -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.TeachingQualification.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend" style="width:42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>

                                <p-dropdown [options]="Qualifications" formControlName="TeachingQualification" placeholder="Qualification" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100% - 42px)'}">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isSubmitted && formControls.TeachingQualification.errors" class="help-block font-10">
                                <div *ngIf="formControls.TeachingQualification.errors.required">Teaching Qualification is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row  justify-content-center">
                        <!-- Nationality  -->
                        <div class="col-lg-6  col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.Nationality.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>

                                <p-dropdown [options]="countries" formControlName="Nationality" placeholder="Nationality" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100% - 42px)'}">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isSubmitted && formControls.Nationality.errors" class="help-block font-10">
                                <div *ngIf="formControls.Nationality.errors.required">Nationality is required</div>
                            </div>
                        </div>
                        <!-- DateOfBirth -->
                        <div class="col-lg-6  col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.DateOfBirth.errors }">
                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-calendar"></i></span>
                                </div>
                                <p-calendar formControlName="DateOfBirth" [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1940 :2005" inputId="navigators" [showIcon]="false" placeholder="Date of birth" [ngStyle]="{width:'calc(100% - 42px)'}"></p-calendar>
                            </div>
                            <div *ngIf="isSubmitted &&  formControls.DateOfBirth.errors" class="help-block font-10">
                                <div class="has-error" *ngIf="formControls.DateOfBirth.errors.required">Date of birth is Required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row  justify-content-center">

                        <!-- Email -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-envelope"></i></span>
                                </div>
                                <input formControlName="email" autocomplete="off" class="form-control contact-input" type="email" name="" id="" placeholder="Email">
                            </div>
                            <div *ngIf="isSubmitted && formControls.email.errors" class="help-block font-10">
                                <div *ngIf="formControls.email.errors.required">Email is required</div>
                            </div>
                            <div *ngIf="formControls.email.errors?.pattern" class="has-error font-10">
                                EMAIL INVALID
                            </div>
                        </div>

                        <!-- Phone Number -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.PhoneNumber.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-mobile"></i></span>
                                </div>
                                <input formControlName="PhoneNumber" autocomplete="new-PhoneNumber" class="form-control contact-input" type="text" oninput="this.value = this.value.replace(/[^+0-9]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Phone Number">
                            </div>
                            <div *ngIf="isSubmitted && formControls.PhoneNumber.errors" class="help-block font-10">
                                <div *ngIf="formControls.PhoneNumber.errors.required ">Phone Number is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <!-- c password -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.Password.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-lock"></i></span>
                                </div>
                                <p-password formControlName="Password" [toggleMask]="true" placeholder="Password" [ngStyle]="{width:'calc(100% - 42px)'}"></p-password>

                                <!-- <input minlength="6" autocomplete="new-password" formControlName="Password" class="form-control contact-input" type="password" placeholder="Password"> -->
                            </div>
                            <div *ngIf="isSubmitted && formControls.Password.errors" class="help-block font-10">
                                <div *ngIf="formControls.Password.errors.required">Password is required</div>
                                <div *ngIf="formControls.Password.errors.minlength">Password must be at least 6 characters</div>

                            </div>
                        </div>
                        <!-- confirm password -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.Password.errors }">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-lock"></i></span>
                                </div>
                                <input minlength="6" autocomplete="new-password" (focus)="onFocus()" formControlName="confirmPassword" class="form-control contact-input" type="password" name="" id="" placeholder="Confirm password">
                            </div>
                            <div *ngIf="(isSubmitted && !isConfirmedPassword) ||isFocus" class="help-block">
                                <div class="has-error">Password Doesn't match</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <!-- English type -->
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && formControls.EnglishType.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-user"></i></span>
                                </div>
                                <p-dropdown [options]="EnglishTypes" formControlName="EnglishType" placeholder="English type" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100% - 42px)'}">
                                </p-dropdown>
                            </div>
                            <div *ngIf="isSubmitted && formControls.EnglishType.errors" class="help-block font-10">
                                <div *ngIf="formControls.EnglishType.errors.required">English Type is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 p-2 px-4" [ngClass]="{ 'has-error': isSubmitted && !cv }">
                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-upload"></i></span>
                                </div>
                                <button [ngStyle]="{width:'calc(100% - 42px)'}" style="text-align: 'initial'" type="button" (click)="uploader.click()" class="btn w-btn-cv btn-sm "> {{cv ?  (cv.name | slice:0:10) + '...' : 'CV' }}</button>
                            </div>
                            <input #uploader accept="application/pdf" (change)="cvUrlChange($event)" hidden type="file" class="form-control" id="customFile" required />
                            <div class="text-muted font-10 p-pt-1 text-white-50">
                                <div>The CV only accepting pdf</div>
                            </div>
                            <div *ngIf="isSubmitted && !cv" class="help-block font-10">
                                <div>CV is required</div>
                            </div>

                        </div>

                    </div>
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-12 ">
                            <p-progressSpinner *ngIf="isLogged" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                            <button *ngIf="!isLogged" type="submit" class="btn w-btn-info col-8 login-text bold-txt">Submit your application</button>
                        </div>
                    </div>
                    <div class="row text-center justify-content-center forget-link d-block p-my-3" style="font-size: 12px;">
                        Already a member?<a class="text-white" routerLink='/website/TutorLogin'>Login</a>
                    </div>
                </form>
            </div>

        </div>
    </div>





    <div class="row justify-content-around p-my-4">
        <div class="col-lg-3 col-md-6 col-10 my-3">
            <div class="card h-100">
                <img class="card-img-top p-p-3 w-50  align-self-center mb-4 " src="assets/images/icons/video-conference.png">
                <div class="card-body p-pt-4 position-relative" style="bottom: -6%;">
                    <div class="bold-txt text-center p-pb-2 join-text" style="font-size: 1.5rem;">Higher Earning</div>
                    <p class="card-text text-center join-text p-pb-4">
                        Teach a group of students at the same time and get continous increasing earnings via Paypal weekly </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-10 my-3">
            <div class="card h-100">
                <img class="card-img-top p-p-3 w-50  align-self-center" src="assets/images/icons/analytics.png" alt="Card image cap">
                <div class="card-body mb-4">
                    <div class="bold-txt text-center" style="font-size: 1.5rem;">Less Classes</div>
                    <p class="card-text text-center">
                        With our group method of teaching, you have less teaching hours per day with better returns, better Lifstyle. </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-10 my-3">
            <div class="card h-100">
                <img class="card-img-top p-p-3 w-50  align-self-center" src="assets/images/icons/video.png" alt="Card image cap">
                <div class="card-body mb-4">
                    <div class="bold-txt text-center" style="font-size: 1.5rem;">Relliabel Sessions</div>
                    <p class="card-text text-center">
                        Pick your own classes based on your desire for Guarnteed paying & attending students </p>
                </div>
            </div>
        </div>

    </div>


</div>

<app-footer></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
