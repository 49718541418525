import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Helper } from 'src/app/healpers/helper';
import { TutorModel } from 'src/app/models/tutor.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { LoginService } from 'src/app/services/login/login.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';
import { DateAndTimeService } from 'src/app/services/date-and-time-service/date-and-time.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private dataService: DataService, private loginService: LoginService, private notificationService: NotificationService,private dateAndTimeService:DateAndTimeService) { }
  isSubmitted = false;
  isReadyData = false;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  Tutor: TutorModel = new TutorModel();
  onlineCertificates = [];
  currenPhotoUrl: any;
  countries = [];
  categories = [];
  qualifications = [];
  isConfirmedPassword = true;
  confirmPassword: FormControl = new FormControl('');
  Password: FormControl = new FormControl('');
  email: FormControl = new FormControl('', Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
  paypalEmail: FormControl = new FormControl('', Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));

  currentVideo = '';
  currentCVUrl  :any= '';
  isAdded = false;
  selectedFile :any = null;
  selectedCV : any = null;
  ngOnInit(): void {

    Helper.setNewTitle('MY PROFILE')
    let Email = this.loginService.getUserInfo().Email;
    this.getCountries();
    this.getTutorByEmail(Email);
    this.subscribeToPasswordChange();
   
  }
  toggleVideo(event?: any) {
    this.videoplayer.nativeElement.play();
  }

  photoUrlChange(event) {
    if (event.target.files[0]) {
      this.selectedFile = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.currenPhotoUrl = reader.result;
      }
    }
  }

  onSubmitte() {
    this.isSubmitted = true;

    if (!this.isValidTutor()) {
      this.notificationService.notify('warn', 'warn', 'Some Fields are Required.');

      return;
    }else {
        this.UpdateTutor();
    }

  }
  isValidTutor() {
    let result : any =  this.Tutor.FullName && this.Tutor.Gender && this.Tutor.email && this.Tutor.PhoneNumber
      && this.Tutor.Address &&  this.Tutor.Qualifications && this.Tutor.Nationality && this.Tutor.DateOfBirth && this.Tutor.EnglishType
    
      if(this.Tutor.Password){
        result =result && this.Tutor.Password && this.Tutor.Password.length >=6 && this.isConfirmedPassword 
      }
      return result;
  }

  prepareInfo() {
    const _Info: any = {
      FullName: this.Tutor.FullName,
      Password: this.Tutor.Password,
      Email: this.Tutor.email,
      PhoneNumber: this.Tutor.PhoneNumber,
      Gender: this.Tutor.Gender,
      Address: this.Tutor.Address,
     
      Qualifications: this.Tutor.Qualifications,
      BreifVideo: this.Tutor.BreifVideo,
      photo_path_url: this.Tutor.photo_path_url,
      PayPal: this.Tutor.PayPal,
      statusTutor: this.Tutor.statusTutor,
      Photo_Url: this.Tutor.Photo_Url,
      Nationality : this.Tutor.Nationality,
      DateOfBirth: this.dateAndTimeService.formatDate(new Date(this.Tutor.DateOfBirth)) ,     
      EnglishType : this.Tutor.EnglishType,
      CV:this.Tutor.CV
    }
    if (!this.selectedFile)
    delete _Info.photo_path_url
    else _Info.photo_path_url = this.selectedFile

    if (!this.selectedCV)
    delete _Info.CV
    else _Info.CV = this.selectedCV
    return _Info;
  }
  UpdateTutor() {
    this.isAdded = true;
    let obj = this.prepareInfo()
    this.dataService.post(obj, 'editTu').subscribe(res => {
      if (res.status == 200 && res.body['Result']['statusCode'] == 200) {
        this.isAdded = false;
        this.notificationService.notify('success', 'Success', 'Profile has been Updated Successfuly.')
        
        this.UpdateStorageUser(res.body['Result']['data'])
        this.isSubmitted = false;
      }
      else {
        this.isAdded = false;

      }
    }, err => {
      this.isAdded = false;
    })
  }
  UpdateStorageUser(Tutor){
    this.loginService.setUser(JSON.stringify({
      Email : Tutor.Email,
      FullName : Tutor.FullName,
      PhoneNumber : Tutor.PhoneNumber,
      Status : Tutor.status,
      photo_path_url:Tutor.photo_path_url,
      Type : 'tutor',
      id : Tutor.id,
      SpecialId:Tutor.SpecialId
    }))
  }
  getTutorByEmail(Email) {
    let obj = { Email: Email };
    this.dataService.post(obj, 'tutorDetail').pipe(
      map(
        (res: HttpResponse<any>) => {
          let data = res.body.Result.data;
          const Res = {
            id: data.id,
            Address: data.Address,
            BreifVideo: data.BreifVideo,
            // Country: data.Country,
            Email: data.Email,
            email: data.Email,
            FullName: data.FullName,
            Gender: data.Gender,
            PayPal: data.PayPal,
            PaypalIdentification: data.PaypalIdentification,
            PhoneNumber: data.PhoneNumber,
            // TeachingExperience: data.TeachingExperience,
            // TeachingQualification: data.TeachingQualification,
            Qualifications:data.Qualifications,
            // TutorCategory: data.TutorCategory,
            photo_path_url: data.photo_path_url,
            statusTutor: data.StatusTutor,
            Nationality:data.Nationality,
            DateOfBirth: new Date(data.DateOfBirth),
            EnglishType:data.EnglishType,
            Photo_Url:[],
            CV : data.CV
          }
         
          return Res;
        }
      )
    ).subscribe(
      res => {
        this.Tutor = (res as any);
        this.Tutor.email = this.Tutor.Email
        this.currenPhotoUrl = this.Tutor.photo_path_url
        this.currentVideo = this.Tutor.BreifVideo
        this.currentCVUrl = this.Tutor.CV

        this.isReadyData = true;
        console.log(this.Tutor)

      }
    )
  }
  getCountries() {
    this.dataService.get("COnly").subscribe(
      res => {
        this.countries = res.body.Result.data;
      }
    )
  }
  subscribeToPasswordChange() {
    this.confirmPassword.valueChanges.subscribe(
      res => {
        if (res != this.Tutor.Password)
          this.isConfirmedPassword = false;
        else
          this.isConfirmedPassword = true;
      }
    )
    this.Password.valueChanges.subscribe(
      res => {
        if (res != this.confirmPassword.value) {
          this.isConfirmedPassword = false;
        }
        else if (this.confirmPassword.value)
          this.isConfirmedPassword = true;

      }
    )
  }

  CVChanged(event) {
    if (event.target.files && event.target.files[0]) {
      // this.Tutor.Photo_Url.push(...event.target.files);
      this.Tutor.CV =  event.target.files[0];
      this.selectedCV = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.currentCVUrl = reader.result;
      }
    }
  }
}
