import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAndTimeService } from 'src/app/services/date-and-time-service/date-and-time.service';

@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html',
  styleUrls: ['./session-timer.component.css']
})
export class SessionTimerComponent implements OnInit {
  @Input()  meeting_session_date;
  @Output() joinClicked = new EventEmitter<any>()
   remainingHours =0;
   remainingMinutes = 0;
   remainingSecondes = 60;
   currentTime = new Date().toLocaleTimeString();

  constructor(private dateAndTimeService:DateAndTimeService) { }
  interval
  ngOnInit(): void {
    let offset = new Date().getTimezoneOffset() * 60;
    this.meeting_session_date = new Date(this.meeting_session_date)
    let secondBetweenTwoDate = ((this.meeting_session_date.getTime() + (-(offset*1000))) - new Date().getTime() ) / 1000;
    this.remainingSecondes =60- Number.parseInt(this.currentTime.split(':', 3)[2]);

    if(secondBetweenTwoDate <= 0){
      this.remainingHours =0;
      this.remainingMinutes = 0;
      this.remainingSecondes = 0;
    }
    else {
      let totalMinutes = secondBetweenTwoDate/60;
      this.remainingHours = Number.parseInt(Math.floor(totalMinutes / 60).toString()); 
      this.remainingMinutes = Number.parseInt((totalMinutes % 60).toString());
      
      this.startTimer();
    }
       
  }
 
  startTimer() {
    this.interval = setInterval(() => {
      if (this.remainingSecondes > 0) {
        this.remainingSecondes--;
      }
      else {
        if (this.remainingMinutes > 0) {
          this.remainingSecondes = 60;
          this.remainingMinutes--;
        } else {
          if(this.remainingHours > 0){
            this.remainingMinutes = 60;
            this.remainingHours--;
          }
        }
      }
    }, 1000)
  }

  onJoinClicked(){
    this.joinClicked.emit(true);
  }
}
