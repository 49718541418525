import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.css']
})
export class PaymentFailComponent implements OnInit {
  route: any;
  transaction_id: any;
  payment_status: any;

  constructor() { }

  ngOnInit(): void {
    console.log('successs failed');
    this.route.queryParams.subscribe(res => {      console.log(res);
      this.transaction_id = res['transaction_id']
      this.payment_status = res['status']
      console.log(res);
    })
  }
}
