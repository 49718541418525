import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentGuard implements CanActivate {
  constructor(    private permissionsService:NgxPermissionsService,
    ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
     return this.permissionsService.hasPermission(['Mstudent']);
  }
}

export class AddStudentGuard implements CanActivate {
  constructor(    private permissionsService:NgxPermissionsService,
    ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
     return this.permissionsService.hasPermission(['Mstudent_add']);
  }
}

