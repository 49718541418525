import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
 private encryptSecretKey = 'nakheel group encrypt secret key'
 private _sessionID :BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public sessionID : Observable<any> = this._sessionID.asObservable();
constructor() { }
  setNewSessionId(id){
    this._sessionID.next(id);
  }


  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}
