<div class="container  bg-websit min-h" style="max-width: 100% !important;padding: 0px;overflow-x: hidden;">
    <!-- <div class="logo-{{'COMMON.DIR' | translate}}"> -->
    <div class="row justify-content-between align-items-center">
        <div class="logo">
            <img class="logo-image cursor-pointer" (click)="clicked()" src="assets/images/websiteImages/logo.svg" alt="" routerLink="website">
        </div>
        <div class="mx-5" style="width:max-content">
            <select (change)="OnLangChange($event)" class="form-control select-header" [(ngModel)]="this.lang">
                <option value="ar">
                    <a class="li-header p-menuitem-link">AR</a>
                </option>
                <option value="en">
                    <a class="li-header p-menuitem-link">EN</a>
                </option>
            </select>
        </div>

    </div>

    <div class="col-l2  bg-websit">
        <div class="row bg-websit">
            <div class="col-12 text-center">
                <img class="img-fluid heroBanner top-pos" src="assets/images/websiteImages/heroBanner.svg" alt="">
            </div>
        </div>
        <div class="row text-center bg-websit">
            <label class="text-main top-pos">{{'LANDING_PAGE.LEARN_ENGLISH' |translate}}</label>
        </div>
        <div class="row text-center bg-websit p-pt-3">
            <label class="text-seconde top-pos">
                {{'LANDING_PAGE.ITS_TIME_TO_BREAK' |translate}}
            </label>
        </div>
        <div class="row bg-websit  top-pos">
            <div class="p-d-flex store-btn justify-content-center p-mb-2">
                <div class="p-pt-2 btn-store btn-store-1">
                    <a [href]="appStoreUrl" download target="_blank">
                        <div class="btn-group btn-sm group btn-130px" role="group">
                            <button type="button" class="btn store store-icon p-0 py-1 p-m-auto" style="background-color: #70B8C7;width: 25px !important;"><img class="appel" src="assets/images/websiteImages/appleStore.svg" ></button>
                            <button type="button" class="btn w-btn-info store p-0 py-1 bold-txt p-m-auto">{{'LANDING_PAGE.APP_STORE' | translate}}</button>
                        </div>
                    </a>
                </div>
                <div class="p-pt-2 btn-store btn-store-1">
                    <a [href]="googleStoreUrl" download target="_blank">
                        <div class="btn-group btn-sm group btn-130px" role="group">
                            <button type="button" class="btn store store-icon p-0 py-1 p-m-auto" style="background-color: #70B8C7;width: 25px !important;"><img class="appel" src="assets/images/websiteImages/googlePlay.svg" ></button>
                            <button type="button" class="btn w-btn-info store  p-0 py-1 bold-txt p-m-auto">{{'LANDING_PAGE.PLAY_STORE' | translate}}</button>
                        </div>
                    </a>
                </div>
            </div>
            <div class="p-d-flex store-btn justify-content-center ">
                <div class="p-pt-2 btn-store btn-store-1">
                    <div class="btn-group btn-sm group btn-260px" role="group">
                        <button type="button" class="btn store store-icon visit-btn px-0" style="background-color: #70B8C7;"><img class="glop" src="assets/images/websiteImages/world.png" ></button>
                        <button type="button" class="btn w-btn-info visit-btn bold-txt" (click)="clicked()">{{'LANDING_PAGE.VISIT_WEBSITE' |translate}}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>



<app-footer [isTranslate]="true"></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
