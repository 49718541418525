import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateAndTimeService {

  constructor() { }


  /**
    *
    * @param SessionTime EX : 10:24:20
    */
  ConvertToLocalTime(SessionTime) {
    let offset = new Date().getTimezoneOffset();
    let list = [];

    // list =[H,M,S]
    list = SessionTime.split(':')
    let temp_h = Number.parseInt(list[0].toString());
    let h = temp_h + (-offset / 60);
    if (h > 23) {
      h = h - 24;
    }
    return `${h}:${list[1]}:${list[2]}`
  }

  /**
   *
   * @param dateTime Ex: 2021-09-06 09:19:35
   */
  ConvertDateToLocal(dateTime) {
    // Note : i did som replace to conver date to format that safari accept it

    let offset = new Date().getTimezoneOffset();
    let date = new Date(dateTime.replace(/-/g, "/")).toLocaleDateString();
    date = (date as string).replace('/', '-')
    date = (date as string).replace('/', '-')
    let time = new Date(dateTime.replace(/-/g, "/")).toLocaleTimeString();
    let list = time.split(':')
    let h = Number.parseInt(list[0].toString());
    return `${date} - ${h + (-offset / 60)}:${list[1]}:${list[2].split(' ')[0]}`

  }

  /**
   *
   * @param time EX : 20:24:20 => 08:24 PM
   *
   */
  convertTo12Format(time: string) {
    let list = [];

    // list =[H,M,S]
    list = time.split(':')
    let date = new Date()
    date.setHours(list[0], list[1], list[2]);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }


  /**
   *
   * @param time  EX : 08:24 PM  => 20:24:20
   * @returns
   */
  convertTo24Format(time: string) {

    let list = [];

    // list =[H,M]
    list = time.split(':', 2)

    let AMPM: 'AM' | 'PM' = list[1].split(' ', 2)[1];
    list[1] = list[1].split(' ', 2)[0];
    let hours = Number.parseInt(list[0]);
    let minutes = Number.parseInt(list[1]);

    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ':' + sMinutes);
  }

  /**
   *
   * @param dateTime Ex: 2021-09-06 12:18:59
   */
  GetTimeFromDate(dateTime) {

    return `${dateTime.split(' ')[1]}`

  }

  /**
   *
   * @param date
   * @returns
   */
  formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // add 1 because months are indexed from 0
    let year = date.getFullYear();

    return `${year}/${month}/${day}`
  }
  formatDateWithDash(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // add 1 because months are indexed from 0
    let year = date.getFullYear();

    return `${year}-${month}-${day}`
  }
}
