import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { Helper } from './healpers/helper';
import { FireBaseService } from './services/fire-base/fire-base.service';
import { LoginService} from './services/login/login.service';
import { NotificationService } from './services/notification-service/notification.service';
import { LANGUAGE_KEY, TranslationService } from './services/translate/translate.service';
import { locale as enLang } from '../assets/i18n/en';
import { locale as arLang } from '../assets/i18n/ar';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  
  title = 'WRITALK';
    
  val: string;
  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(USERPERMISSIONS)));
  // }
  constructor(private primengConfig: PrimeNGConfig,
    private loginService:LoginService,
    private router:Router,
    private fireBaseService : FireBaseService,
    private cdf :ChangeDetectorRef,
    private notificationService:NotificationService,
    private messageService:MessageService,
    private titleService: Title,
    private metaTagService: Meta,
    private translationService: TranslationService,

    
    ) {
          // register translations
    this.translationService.loadTranslations(enLang,arLang);
    }

  ngOnInit() {
    this.titleService.setTitle('Learn English with a Crowd');
    this.metaTagService.updateTag(
      { name: 'description', content: ' It\'s time to break the ice! Let\'s improve our English & public speaking skills in front of an audience.' }
    );
    
    this.setUpAnalytics();
      Helper.setNewTitle("Dashboard")
      this.router.events.subscribe((evt) => {
        if(localStorage.getItem(LANGUAGE_KEY))
        this.translationService.setLanguage(localStorage.getItem(LANGUAGE_KEY))
        
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        
        window.scrollTo(0, 0)
    });
      this.primengConfig.ripple = true;
      if(!this.loginService.isLogged()){
	    	// this.router.navigate(['/website']);
      }
      else if(this.loginService.isLogged()) {

        this.fireBaseService.requestPermission();
        this.fireBaseService.receiveMessage();
      }
    this.subscribeToNotifications();


      this.cdf.detectChanges()
   
  }
  isTutor(){
   return this.loginService.isLogged() ? this.loginService.getUserInfo().Type=='tutor': false;
  }
  subscribeToNotifications(){
        this.notificationService.notificationChange.subscribe(
          (res :any)=>{
            this.messageService.add({ severity: res.severity, summary: res.summary, detail:res.detail });

          }
        )
  }
  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            gtag('config', 'G-F8HWCP4GQ2',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
  }
  

}
