import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private _code = new BehaviorSubject<any>(null);
  public code = this._code.asObservable();

  private _email = new BehaviorSubject<any>(null);
  public email = this._email.asObservable();

  public setNewCode(code){
    this._code.next(code);
  }

  public setNewEmail(email){
    this._email.next(email)
  }
}
