// Angular
import { Injectable } from '@angular/core';
// Translation
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: Object;
}
export const LANGUAGE_KEY = 'language_client'
@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  // Private properties
  private langIds: any = [];

  /**
   * Service Constructor
   *
   * @param translate: TranslateService
   */
  constructor(private translate: TranslateService) {
    // add new langIds to the list
    this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');

  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: any
   */
  setLanguage(lang,reload=false) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      localStorage.setItem(LANGUAGE_KEY, lang);
      this.translate.use(lang);
      if(reload) window.location.reload()
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return localStorage.getItem(LANGUAGE_KEY) || this.translate.getDefaultLang();
  }
}
