<div class="row links  text-center py-3">
    <div>
        <a class="inline-block mx-3 mt-2" target="_blank" href="https://facebook.com/writalkapp/">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50" style=" fill:white">    <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path></svg>
        </a>
        <a class="inline-block mx-3 mt-2" target="_blank" href="https://twitter.com/writalkapp?s=21">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50" style=" fill:white;">    <path d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M36.237,20.524 c0.01,0.236,0.016,0.476,0.016,0.717C36.253,28.559,30.68,37,20.491,37c-3.128,0-6.041-0.917-8.491-2.489 c0.433,0.052,0.872,0.077,1.321,0.077c2.596,0,4.985-0.884,6.879-2.37c-2.424-0.044-4.468-1.649-5.175-3.847 c0.339,0.065,0.686,0.1,1.044,0.1c0.505,0,0.995-0.067,1.458-0.195c-2.532-0.511-4.441-2.747-4.441-5.432c0-0.024,0-0.047,0-0.07 c0.747,0.415,1.6,0.665,2.509,0.694c-1.488-0.995-2.464-2.689-2.464-4.611c0-1.015,0.272-1.966,0.749-2.786 c2.733,3.351,6.815,5.556,11.418,5.788c-0.095-0.406-0.145-0.828-0.145-1.262c0-3.059,2.48-5.539,5.54-5.539 c1.593,0,3.032,0.672,4.042,1.749c1.261-0.248,2.448-0.709,3.518-1.343c-0.413,1.292-1.292,2.378-2.437,3.064 c1.122-0.136,2.188-0.432,3.183-0.873C38.257,18.766,37.318,19.743,36.237,20.524z"></path></svg>
        </a>
        <a class="inline-block mx-3 mt-2" target="_blank" href="https://instagram.com/writalkapp?utm_medium=copy_link">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50" style=" fill:white;">    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path></svg>            </a>
        <a class="inline-block mx-3 mt-2" target="_blank" href="https://www.linkedin.com/company/writalk">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50" style=" fill:white;">    <path d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z"></path></svg>
        </a>
    </div>
</div>
<div class="row p-px-2  footer justify-content-between mw-100">
    <div class="row navs justify-content-center p-0 m-auto">
        <div class="col-lg-3 col-md-3 col-4  text-center p-p-0 cursor-pointer" (click)="goTo('website/Privacy&Policy')"> {{this.isTranslate ? ('FOOTER.PRIVACY_POLICY' | translate) : 'Privacy Policy'}}</div>
        <div class="col-lg-3 col-md-3 col-4  text-center p-p-0 cursor-pointer" (click)="goTo('website/Terms&Condition')" style="width: max-content;">{{this.isTranslate ? ('FOOTER.TERMS_CONDITIONS' | translate) : 'Terms & Conditions'}} </div>
        <div class="col-lg-3 col-md-3 col-4  text-center p-p-0 cursor-pointer" (click)="goTo('website/contactUs')">{{this.isTranslate ? ('FOOTER.CONTACT_US' | translate) : 'Contact us'}}</div>
        <!-- <div class="col-lg-3 col-md-3 col-6  text-center p-p-0 cursor-pointer"><a href="https://blog.writalk.com/" target="_blankks" class="text-white">{{this.isTranslate ? ('FOOTER.BLOGS' | translate) : 'Blogs'}}</a></div> -->
    </div>
    <div class="d-lg-flex d-md-flex d-flex navs text-muted p-pt-4" style="place-content: center;">
        {{this.isTranslate ? ('FOOTER.COPYRIGHT' | translate) : 'Copyrights © 2021 WRITALk'}}
    </div>
</div>