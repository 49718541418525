import { ApplicationRef, ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject, Observable } from 'rxjs';
export const  TOKEN = "TOKEN";
export const  USER = "USER";

export const USERPERMISSIONS = "userPermissions"

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private router: Router,
    private permissionsService : NgxPermissionsService  ,
    private roleService:NgxRolesService,
    ) {}
   private _user_Info =  new BehaviorSubject<any>(null);
   public userInfo :Observable<any> = this._user_Info.asObservable();
  setToken(token: string): void {
    console.log(token)
    localStorage.setItem(TOKEN, token);
  }

  setUser(user:any){
    localStorage.setItem(USER, user);
    this._user_Info.next(user);
  }
  getToken() {
   return localStorage.getItem(TOKEN) ? JSON.parse(localStorage.getItem(TOKEN)) : '';
  }
  getUserInfo() {
    return localStorage.getItem(USER) ? JSON.parse( localStorage.getItem(USER)) : '';
   }
  logout(withRout:boolean = true) {

    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USERPERMISSIONS);
    localStorage.removeItem(USER);
    this.permissionsService.flushPermissions()
    this.roleService.flushRoles();
    withRout? this.router.navigateByUrl("/login") : null

    // window.location.reload();
    
  }

  isLogged() {
    return localStorage.getItem(TOKEN) != null;
  }
  setPermission(permissions){
    this.permissionsService.loadPermissions(permissions);
    localStorage.setItem(USERPERMISSIONS,JSON.stringify(permissions))

  }
  setRole(role:string){
    this.roleService.addRole(role,[])

  }
}
