import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-tutor-statistics',
  templateUrl: './tutor-statistics.component.html',
  styleUrls: ['./tutor-statistics.component.css']
})
export class TutorStatisticsComponent implements OnInit, OnChanges {

  constructor(private dataService: DataService) { }

  data: any;
  options: any;
  dataset = [];
  labels = [];
  colors =['orange','red','green']
  @Input() countTutorStatistics;
  ngOnInit(): void {
    // this.getTutorStatistics()

    this.options = {
      legend: {
        position: 'bottom',
        horizontalAlign: "center", // "center" , "right"
        verticalAlign: "top",  // "top" , "bottom"

        display:false,
      }
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getTutorStatistics();
  }
  getTutorStatistics() {
    this.dataset=[];
    this.labels = [];
    if (this.countTutorStatistics) {
      Object.entries(this.countTutorStatistics).forEach(([key, value]) => {
        this.dataset.push(value);
        switch (key) {
          case 'approved':
           this.labels.push(`Approved Tutors`);
            break;
            case 'rejected':
            this.labels.push(`Rejected  Tutors`);
            break;
            case 'requested':
              this.labels.push(`Applied Tutors`);
              break;
          default:
            break;
        }
      })
      this.data = {
        labels: this.labels,
        datasets: [
          {
            // showInLegend: false,
            data: this.dataset,
            backgroundColor: [
              "#F39C12",
              "#E74C3C",
              "#2ECC71",
            ],
            hoverBackgroundColor: [
              "#F39C12",
              "#E74C3C",
              "#2ECC71",
            ],

          }]
      };
    }

  }
}


