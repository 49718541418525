<!-- START Contact Us SECTION -->
<div class="container  bg-websit p-0  min-h " style="max-width: 100% !important;  overflow-x: hidden;">

    <app-website-header (linkClicked)="linkClicked($event)"></app-website-header>
    <p-tabView [(activeIndex)]="activeIndex" styleClass="tabview-custom" class="pt-2">
        <p-tabPanel [selected]="true">
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-calendar"></i> -->
                <span class="font-S">Email</span>
            </ng-template>
            <div class="col-l2  bg-websit" style="padding: 9px;">
                <div class="row  p-p-4 text-center bg-websit">
                    <div class="font-S" style="font-weight: 900;color: white;">
                        Get in Touch
                    </div>
                </div>
                <div class="row text-center bg-websit">
                    <div style="font-weight: 400;color: gray;font-size: 20px;">
                        Do you have any questions?
                    </div>
                </div>
                <div class="row justify-content-around bg-websit ">

                    <div class="col-lg-4 col-12">
                        <form [formGroup]="sendSupportForm" (ngSubmit)="sendSupport()">
                            <div class="row p-mt-2">
                                <div class="col-12">
                                    <input formControlName="Name" class="form-control contact-input" type="text" name="" id="" placeholder="Name" [ngClass]="{ 'has-error': isSubmitted && formControls.Name.errors }">
                                    <div *ngIf="isSubmitted && formControls.Name.errors" class="help-block has-error">
                                        <div *ngIf="formControls.Name.errors.required">Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-mt-2">
                                <div class="col-12">
                                    <input formControlName="Email" class="form-control contact-input" type="text" name="" id="" placeholder="Email" [ngClass]="{ 'has-error': isSubmitted && formControls.Email.errors }">
                                    <div *ngIf="isSubmitted && formControls.Email.errors" class="help-block has-error">
                                        <div *ngIf="formControls.Email.errors.required">Email is required</div>
                                    </div>
                                    <div *ngIf="formControls.Email.errors?.pattern" class="has-error">
                                        EMAIL INVALID
                                    </div>
                                </div>
                            </div>
                            <div class="row p-mt-2">
                                <div class="col-12">
                                    <div class="input-group">
                                        <p-dropdown [options]="supportTypes" formControlName="support_type_id" placeholder="Select Support Type" optionLabel="name" [showClear]="false" [ngStyle]="{width:'calc(100%)'}">
                                        </p-dropdown>
                                    </div>

                                    <div *ngIf="isSubmitted && formControls.support_type_id.errors" class="help-block has-error">
                                        <div *ngIf="formControls.support_type_id.errors.required">Support Type is required</div>
                                    </div>

                                </div>
                            </div>

                            <div class="row p-mt-2">
                                <div class="col-12">
                                    <textarea formControlName="FeedbackMsg" class="form-control contact-input" rows="5" placeholder="Enter Your Message" [ngClass]="{ 'has-error': isSubmitted && formControls.FeedbackMsg.errors }">
                            </textarea>
                                    <div *ngIf="isSubmitted && formControls.FeedbackMsg.errors" class="help-block has-error">
                                        <div *ngIf="formControls.FeedbackMsg.errors.required"> Message is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-mt-2 justify-content-end" style="padding-bottom: 5rem;">
                                <div>
                                    <p-progressSpinner *ngIf="isLoading" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                                    <button *ngIf="!isLoading" class="btn w-btn-info btn-font-size float-end">Send message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </p-tabPanel>
        <p-tabPanel header="header" [disabled]="true">
            <ng-template pTemplate="header">
                <span class="font-S">|</span>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="header">
            <ng-template pTemplate="header">
                <span class="font-S">WhatsApp</span>
            </ng-template>
            <div class="col-l2  bg-websit p-pt-6" style="padding: 9px;">
                <div class="row  p-p-4 text-center bg-websit" style="place-content: center;">
                    <div class="font-S" style="color: white;">
                        Feel free to contact us on WhatsApp
                    </div>
                    <a href="{{'https://api.whatsapp.com/send?phone='+whatsapp_number}}" target="_blank" class="btn w-btn-info  btn-sm li-header  p-p-1 bold-txt whatsbtn">Go to WhatsApp</a>

                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<app-footer></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
