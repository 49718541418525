import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Helper } from 'src/app/healpers/helper';
import { DataService } from 'src/app/services/data-service/data.service';
import { FireBaseService } from 'src/app/services/fire-base/fire-base.service';
import { LoginService } from 'src/app/services/login/login.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(private loginService:LoginService,private fb:FormBuilder,
    private dataService:DataService, private firebaseSevice:FireBaseService,
    private notificationService:NotificationService) { }
 title = Helper.selectedTitle
 displayDialog = false;
 resetPasswordForm :FormGroup;
 isSubmittedReset = false;
 isConfirmedPassword = true;
 isReseted = false;
 showNotificationsList = false;
 userInfo : any;
 isTutor : boolean = false;
 logo_url = 'assets/images/logo_title.png'
 photo_path_url
 count = 0 ;
 @Output() onShowNotification = new EventEmitter<any>()
  ngOnInit(): void {

    this.userInfo= this.loginService.getUserInfo();
    
    this.isTutor = this.userInfo.Type=='admin' ? false : true;
    if(this.isTutor)
    this.userInfo.photo_path_url ? this.logo_url = this.userInfo.photo_path_url : null
    this.resetPasswordForm = this.fb.group({
      newPassword : ['',Validators.required],
      confirmPassword: ['',Validators.required]
    })
    Helper.selectedTitle.subscribe(res=>{this.title=res});
    this.subscribeToPasswordChange();
    this.subscribeToNewNotification();
    this.loginService.userInfo.subscribe(
      res=>{
        console.log(res);
        if(res){
          this.userInfo =JSON.parse(res);
          this.userInfo.photo_path_url ? this.logo_url = this.userInfo.photo_path_url : null
        }
      }
    )
  }
  logout(){
    console.log("logout")
    this.loginService.logout();

  }

  showNotifications(){
    this.showNotificationsList = true;
    this.onShowNotification.emit(this.showNotificationsList);
  }
  openDialog(){

this.displayDialog = true;
  }
  submitResetPassword(){
    this.isSubmittedReset = true;
    const controls = this.resetPasswordForm.controls;
    if (this.resetPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      }
      );
      return;
    }
    if(!this.loginService.getUserInfo().Email)
    return
    this.isReseted = true;
    let obj ={
      email:this.loginService.getUserInfo().Email,
      Password:controls['newPassword'].value
    }
    this.dataService.post(obj , "uAdmin").subscribe(
      res=>{
        if(res.body['Result']['statusCode']==200){
          this.notificationService.notify('success','Success','Password Updated.')

          this.isSubmittedReset=false;
          this.isReseted   = false;
          this.displayDialog = false;

        }else {
          this.notificationService.notify('error','Error','Error.')

          this.isReseted = false;

        }
      },err=>{
        this.notificationService.notify('error','Error','Error.')

        this.isReseted = false;
      }
    )
  }


  subscribeToPasswordChange() {
    const controls = this.resetPasswordForm.controls;
    controls['confirmPassword'].valueChanges.subscribe(
      res => {
        if (res != controls['newPassword'].value)
          this.isConfirmedPassword = false;
        else
          this.isConfirmedPassword = true;
      }
    )
    controls['newPassword'].valueChanges.subscribe(
      res=>{
        if(res !=  controls['confirmPassword'].value ){
          this.isConfirmedPassword = false;
      }
        else if(controls['confirmPassword'].value)
        this.isConfirmedPassword = true;
      }
    )
  }
  subscribeToNewNotification(){
    this.firebaseSevice.newNotificationCount.subscribe(
      res=>{
        this.count = res;
      }
    )
  }
  sidebarCollapseClicked(){
      document.getElementById('contents').classList.toggle('active');
      document.getElementById('sidebar').classList.toggle('active');
      document.getElementById('sidebar').classList.toggle('mobile-active-open');

    //  document.getElementById('sidebar').classList.toggle('side-nav-mobile-active');
    // document.getElementById('sidebar').classList.toggle('side-nav-mobile-inactive');

    //  document.getElementById('contents').classList.toggle('mobile-active');

  }
}
