import { BehaviorSubject, Observable } from "rxjs";

export class  Helper {


  private static _selectedTitle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public static selectedTitle: Observable<any> =Helper._selectedTitle.asObservable();
  public static setNewTitle(message: any): void {
    Helper._selectedTitle.next(message);
}
}
