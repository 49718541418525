import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private router:Router,
    private formBuilder:FormBuilder,
    private route :ActivatedRoute,
    private dataService:DataService,
    private location:Location,
    private notifyService:NotificationService) { }
  resetFrom : FormGroup
  isSubmitted  = false;
  isLoading = false;

  email = '';
  isConfirmedPassword = true;
  code  : any
  ngOnInit(): void {
    this.createForm()
    try {
      this.email =JSON.parse(atob(this.route.snapshot.params.data)).email;
      this.code =JSON.parse(atob(this.route.snapshot.params.data)).code;  
    }catch{
      this.location.back()
    }
    this.subscribeToPasswordChange();
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }

  createForm(){
    this.resetFrom = this.formBuilder.group({
      password: ['', [Validators.required,Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],

    })
  }
  get formControls() { return this.resetFrom.controls; }

   submit() {
    this.isSubmitted = true;
    if(this.resetFrom.invalid || !this.isConfirmedPassword){
      return;
    }
    else  {
      this.isLoading = true;
      this.dataService.post({Email:this.email,Password:this.resetFrom.controls.password.value,Code : this.code},'forgetPass').toPromise().then(
        res=>{
          if(res.body['Result']['statusCode']==200){
            this.isLoading = false;
            this.router.navigate(['/website/TutorLogin'])
          }
        }
      )

    }
  }

  subscribeToPasswordChange() {
    const controls = this.resetFrom.controls;
    controls['confirmPassword'].valueChanges.subscribe(
      res => {
        if (res != controls['password'].value)
          this.isConfirmedPassword = false;
        else
          this.isConfirmedPassword = true;
      }
    )
    controls['password'].valueChanges.subscribe(
      res=>{
        if(res !=  controls['confirmPassword'].value ){
          this.isConfirmedPassword = false;
      }
        else if(controls['confirmPassword'].value)
        this.isConfirmedPassword = true;
      }
    )
  }
}
