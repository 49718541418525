import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailVertificationComponent } from './email-vertification/email-vertification.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { TutorLoginComponent } from './tutor-login/tutor-login.component';
import { TutorRegisterComponent } from './tutor-register/tutor-register.component';

const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'contactUs',
    component:GetInTouchComponent
  },
  {
    path:'Jobs',
    component:JobsComponent
  }
  ,
  {
    path:'Privacy&Policy',
    component:PrivacyPolicyComponent
  }
  ,{
    path:'Terms&Condition',
    component:TermsAndConditionComponent
  },
  {
    path : 'TutorLogin',
    component:TutorLoginComponent
  },
  {
    path : 'TutorRegister',
    component:TutorRegisterComponent
  },
  {
    path:'forgetPassword',
    component:ForgetPasswordComponent
  },
  {
    path:'resetPassword/:data',
    component:ResetPasswordComponent
  },
  {
    path:'emailVertification/:data',
    component:EmailVertificationComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class WesiteModuleRoutingModule { }
