<div class="App">
    <div class="App-header">

        <h1>Writalk</h1>
    </div>
    <div class="App-main">


      <!--Left bar-->

        <div    id="controls" class='App-control-container hidden'>
            <div  class="ots-video-control circle audio" id="toggleLocalAudio"></div>
            <div  class="ots-video-control circle video" id="toggleLocalVideo"></div>

           
            <div  class="ots-video-control disconnect-user"  id="disconnectMe" ><i class="fas fa-phone-slash icon-disconnect"></i></div>
        </div>
           <!--Video Section-->
        <div class="App-video-container" id="appVideoContainer" style="position: relative;">

            <div class="App-mask" id="connecting-mask">

                <div class="message with-spinner">Connecting</div>
            </div>
            <div class="App-mask hidden" id="start-mask">
                <div class="message button clickable" id="start" (click)="ListStat=true">Click To Start Call</div>
            </div>
            <div id="cameraPublisherContainer" class="video-container hidden"></div>
            <div id="screenPublisherContainer" class="video-container hidden"></div>
                <div id="cameraSubscriberContainer" class="video-container-hidden"></div>
                <div id="screenSubscriberContainer" class="video-container-hidden"></div>
         
        </div>
   <!--Right Section-->
      
    </div>



