<!-- <p-toast></p-toast> -->
<div class="container  bg-websit p-0  min-h overflow-hidden" style="max-width: 100% !important;  overflow-x: hidden;">
    <app-website-header (linkClicked)="linkClicked($event)"></app-website-header>
    <div class="row  p-pt-3 bg-websit">
        <div class="first-col-login text-center h-100 bg-websit column-display">
            <!-- <img class="img-fluid" src="assets/images/websiteImages/img8.png" alt=""> -->
            <img class="img-fluid tutor-image" src="assets/images/websiteImages/Tutor.svg" alt="">
        </div>
        <div class="seconed-col p-mt-3 text-center bg-websit">
            <div class="row text-center">
                <label style="font-weight: 900;color: white;font-size: 40px;">Reset Password</label>
            </div>
            <div class="row text-center">
                <label style="font-weight: 400;color: #D1D1D1;font-size: 20px;">Your new password must be different from previously used password
            </label>

            </div>
            <div class="row p-mt-6">
                <form [formGroup]="resetFrom" (ngSubmit)="submit()" class="p-mb-4">
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-8" [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
                            <input formControlName="password" class="form-control contact-input" type="password" name="" id="" placeholder="New password">
                            <div *ngIf="isSubmitted && formControls.password.errors" class="help-block has-error">
                                <div *ngIf="formControls.password.errors.required">Password is required</div>
                                <div *ngIf="formControls.password.errors.minlength">Password must be at least 6 characters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-8" [ngClass]="{ 'has-error': isSubmitted && formControls.confirmPassword.errors }">
                            <input formControlName="confirmPassword" class="form-control contact-input" type="password" name="" id="" placeholder="Confirm new password">
                            <div *ngIf="isSubmitted && !isConfirmedPassword" class="help-block">
                                <div class="has-error">Password Doesn't match</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row text-end justify-content-center">
                    <a class="forget-link col-8 p-mt-2" routerLink="/website/forgetPassword">  Forget Password?    </a>
                </div> -->
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-12 ">
                            <p-progressSpinner *ngIf="isLoading" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                            <button *ngIf="!isLoading" type="submit" class="btn w-btn-info col-8 btn-font-size">Submit</button>
                        </div>
                    </div>
                    <div class="row text-center justify-content-center forget-link d-block p-mt-2" style="font-size: 12px;">
                        Don’t Get the Code?<a class="text-white">Resend Code</a>
                    </div>
                </form>
            </div>

        </div>
    </div>



</div>
<app-footer></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
