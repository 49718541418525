<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
        <button id="sidebarCollapse" type="button" id="sidebarCollapse" class="menu-btn" (click)="sidebarCollapseClicked()">
     <i class="fas fa-bars"></i>
     </button>
        <span class="spanTitle">{{title}}</span>
        <div class="d-flex bg-white" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="notifyHeader borderSeperate" (click)="showNotifications()"><a class="notification active"><i class="far fa-bell"></i><span *ngIf="count > 0" class="Spanalert">{{count}}</span></a></li>
                <div class="divider"></div>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img [src]="logo_url" class="porfileimg" alt="">
                        <div class="profileTxt">
                            <h6>{{userInfo?.FullName ? userInfo?.FullName : 'User Name'}}</h6>
                            <small>{{userInfo.type}}</small>
                        </div>
                        <small class="dropdown_arrow"><img src="../../../../assets/images/dropdowntoggle.png" alt=""></small>
                    </a>

                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngIf="isTutor" routerLink="/profile" class="cursor-pointer">
                            <a class="dropdown-item">
                                <div class=" myprofile_detail_wrapper">
                                    <div>
                                        <img class="img-fluid" [src]="logo_url" alt="pro">
                                    </div>
                                    <div class="myprofile_content">
                                        <h3>{{userInfo?.FullName ? userInfo?.FullName : 'User Name'}} </h3>
                                        <span>View Profile Information</span>
                                    </div>
                                    <i class="fas fa-angle-right p-mx-2" aria-hidden="true"></i>
                                </div>
                            </a>
                        </li>
                        <!-- <li (click)="openDialog()"><a class="dropdown-item" href="#"><span class="bold-txt">Change Password</span></a></li> -->
                        <li (click)="logout()"><a class="dropdown-item " href="#"><span class="bold-txt" >Logout <img src="../../../../assets/images/logout.png" alt="logout"></span></a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>


<p-dialog [modal]="true" [draggable]="false" [resizable]="false" header="Change Password" [(visible)]="displayDialog" [transitionOptions]="'300ms'" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">

    <ul>
        <li class="active">
            <div class="Modal_details">
                <div class="Modal_content">
                    <div class="login_contents forgot_forms">
                        <h6 class="logo_Title">Please create your new password</h6>
                        <div class="flex_froms">
                            <form [formGroup]="resetPasswordForm">
                                <div class="form-group" [ngClass]="{ 'has-error': isSubmittedReset && resetPasswordForm.controls.newPassword.errors }">
                                    <label for="pwd" class="email_lbl">Create New Password</label>
                                    <input id="password-field" type="password" formControlName="newPassword" class="form-control " placeholder="Password" />
                                    <div *ngIf="isSubmittedReset && resetPasswordForm.controls.newPassword.errors" class="help-block">
                                        <div *ngIf="resetPasswordForm.controls.newPassword.errors.required">Password is required</div>
                                    </div>
                                    <span toggle="#password-field" class="toggle-password">
                              <img class="toggleClose" src="assets/images/password-toggle.png" alt="" />
                              <img class="toggleOpen" src="assets/images/password-toggle-open.png" alt="" />
                            </span>
                                </div>
                                <div class="form-group" [ngClass]="{ 'has-error': isSubmittedReset && resetPasswordForm.controls.newPassword.errors }">
                                    <label class="pwlbl" for="pwd">Re-enter New Password</label>
                                    <input id="password-fieldConfirm" type="password" formControlName="confirmPassword" class="form-control" placeholder="Password" />

                                    <div *ngIf="isSubmittedReset && resetPasswordForm.controls.confirmPassword.errors" class="has-error">
                                        <div *ngIf="resetPasswordForm.controls.confirmPassword.errors.required">Password is required</div>
                                    </div>
                                    <div *ngIf="isSubmittedReset && !isConfirmedPassword" class="has-error">
                                        <div class="has-error">Password Doesn't match</div>
                                    </div>
                                    <span toggle="#password-fieldConfirm" class="toggle-password">
                              <img class="toggleClose" src="assets/images/password-toggle.png" alt="" />
                              <img class="toggleOpen" src="assets/images/password-toggle-open.png" alt="" />
                            </span>
                                </div>
                                <div class="btn_grp">
                                    <p-progressSpinner *ngIf="isReseted" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                                    <button *ngIf="!isReseted" (click)="submitResetPassword()" type="submit" class="btn btn_submits">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</p-dialog>