import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild,Renderer2, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-payment-otp',
  templateUrl: './payment-otp.component.html',
  styleUrls: ['./payment-otp.component.css']
})
export class PaymentOtpComponent implements OnInit {
  data :any="div"
  tool = '<a href="../../user/update" class="fa fa-1x fa-pencil-square-o">View</a>';
  now =false


  constructor(private route:ActivatedRoute,private _sanitizer: DomSanitizer,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document 
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(res => {
      this.data=JSON.parse(res.get('data'))

    })
    var el = document.createElement('div');
    el.innerHTML = this.data;
    document.getElementById("mydiv").append(el);

    this.now=true
    const s = this.renderer2.createElement('script');
   s.type = 'text/javascript';
  //  s.src = 'https://path/to/your/script';
   s.text = `var e=document.getElementById("redirectTo3ds1Form"); if (e) { e.submit(); if (e.parentNode !== null) { e.parentNode.removeChild(e); } }`;
   this.renderer2.appendChild(this._document.body, s);
    this.data =     this.transform(this.data);
    this.now = true
    document.getElementById('redirectTo3ds1AcsSimple').classList.add('test');

    
    






    
  }

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

  

}
