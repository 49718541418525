import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data-service/data.service';
import { DateAndTimeService } from 'src/app/services/date-and-time-service/date-and-time.service';
import { FireBaseService } from 'src/app/services/fire-base/fire-base.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  cards = [];
  constructor(
    private dataService: DataService,
    private fireBaseService: FireBaseService,
    private dateAndTimeService: DateAndTimeService
  ) { }
  countSubscribersStats: any;

  countTotalSalesAll: { total: number, Apple_ALL: number, Google_ALL: number, Credimax_ALL: number  };
  countTotalSalesPlan: { All_ByPlans: { Name: any, TotalCost: any }[] }
  countTutorStatistics: any;
  fromDate = new FormControl('');
  toDate = new FormControl('');
  colors = ['548CFF','1A374D','406882','6998AB','B1D0E0','D1E8E4','A2D2FF','22577A','95D1CC','5584AC','2E4C6D','396EB0','DADDFC'];



  colorsTotalSales = ['3498DB', '7ACCCE','3498DB','9b59b6'
]
  colorsSalsPerPlan = []

  ngOnInit(): void {
    this.fireBaseService.requestPermission();
    this.fireBaseService.receiveMessage();
    this.getDashInfo()
  }
  initCards() {
    this.cards = [
      {
        title: 'No.of',
        boldTitle: ' Total Paid Subscribers',
        counts: null,
        image: 'assets/images/superadmin/dash-cart-one.png'
      },
      {
        title: 'No.of',
        boldTitle: 'Approved Tutors',
        counts: null,
        image: 'assets/images/superadmin/dash-cart-two.png'
      },
      {
        title: 'No.of',
        boldTitle: 'users',
        counts: null,
        image: 'assets/images/superadmin/dash-cart-three.png'
      },
      {
        title: 'No.of',
        boldTitle: 'Sessions',

        counts: null,
        image: 'assets/images/superadmin/dash-cart-six.png'
      },
      {
        title: 'No.of',
        boldTitle: 'Attendees',

        counts: null,
        image: 'assets/images/superadmin/dash-cart-four.png'
      },
      {
        title: 'No.of',
        boldTitle: 'Requested Tutors',
        counts: null,
        image: 'assets/images/superadmin/dash-cart-two.png'
      },
    ]
  }

  getDashInfo(filter?) {
    this.initCards()

    this.dataService.get('admin/dash', filter ? filter : {}).subscribe(
      res => {
        console.log(res);
        let data = res.body.Result.data;
        this.cards[0].counts = data.countTotalSubscribers;
        this.cards[1].counts = data.countApprovedTutors;
        this.cards[2].counts = data.countRegisteredStudent;
        this.cards[3].counts = data.countTotalSession;
        this.cards[4].counts = data.countAttendantSession;
        this.cards[5].counts = data.countRequestedTutors;

        this.countSubscribersStats = data.countSubscribersStats;
        this.countTotalSalesAll = data.countTotalSalesAll;
        this.countTotalSalesPlan = data.countTotalSalesPlan;

        this.countTutorStatistics = data.countTutorStatistics
        let i=0;
        while(i < this.countTotalSalesPlan.All_ByPlans.length){
          var val = this.colors[Math.floor(Math.random() * this.colors.length)];
          console.log(val)
          if (!this.check_insert(val)){
            this.colorsSalsPerPlan.push(val);
            i++;
          }else if(this.check_insert(val) && this.colors.length<=this.colorsSalsPerPlan.length){
            this.colorsSalsPerPlan.push(val);
            i++;
           }
          }
        console.log(this.colorsSalsPerPlan)
      }
    )

  }

  check_insert(value){
    return this.colorsSalsPerPlan.includes(value);
  }
  updateDashboard() {
    if (this.fromDate.value && this.toDate.value) {
      let obj = {
        fromDate: this.dateAndTimeService.formatDate(new Date(this.fromDate.value)),
        toDate: this.dateAndTimeService.formatDate(new Date(this.toDate.value)),
      }
      this.getDashInfo(obj);
    }

  }
  reset() {
    this.fromDate.reset()
    this.toDate.reset()
    this.getDashInfo()
  }
}
