import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, AfterViewInit, ViewChild, Input, Injectable } from '@angular/core';
import { OpentokService } from '../../opentok.service';
export type typescreen = "append" | "replace" | "after" | "before";
import { LoginService } from 'src/app/services/login/login.service';
import { SubscriberInterface } from 'src/app/interfaces/subscriber.interface';
import { DataService } from 'src/app/services/data-service/data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { url } from 'inspector';
import { SharedService } from '../shared.service';
const BaseUrl = environment.BaseUrl;
@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-publisher-two',
  templateUrl: './publisher-two.component.html',
  styleUrls: ['./publisher-two.component.css']
})
export class PublisherTwoComponent implements  AfterViewInit{
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  session;
  publisher: OT.Publisher;
  publishing: Boolean;
  ListStat: boolean = false;
  SubscribersList: SubscriberInterface[] = [];
  globalSessionId: string = '';
  connection_id_test;
  dateCh = new Date();
  globalArchiveI: string;
  headers;
  logintoken;
  SendData = { 'id': '' };
  constructor(private opentokService: OpentokService, public http: HttpClient, private loginService: LoginService, private dataService: DataService,
    private router:Router,
    private route: ActivatedRoute,
    private sharedService:SharedService
    ) {
      const id = this.sharedService.decryptData(this.route.snapshot.params.id)
     this.SendData = { 'id': id };
    this.publishing = false;
    this.loginService.getToken();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.logintoken}`,
      'Access-Control-Allow-Credentials': 'true'
    }
    );
  }

  ngAfterViewInit() {
    let timeToNo= setInterval(() => {

      if(document.getElementById('start-mask') != null){
            if(document.getElementById('start-mask').classList.contains("hidden"))
                    {window.location.reload();

                   }
                   else{
                    clearInterval(timeToNo);

                    return;
                   }}

            }, 3000);


    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.logintoken}`
    });

    const AccCore = require('opentok-accelerator-core');
console.log(this.SendData)

    this.http.post<any>(`${BaseUrl}/api/getSession`, this.SendData, { headers }).subscribe(
      data => {
        console.log(data['Result']['data']['sessionId'])

        localStorage['nameJoiner'] = data['Result']['data']['user'].FullName;
        localStorage['typeJoiner'] = data['Result']['data']['user'].type;
        localStorage['sessionIdRoom'] = data['Result']['data']['sessionId'];
        localStorage['tokenSession'] = data['Result']['data']['token'];


      },err=>{

        if(err.status == 400)
        {  this.router.navigateByUrl('/tutorCancellation').then(() => {

            });





        }

      }
    );
    let sendToGetVisit = {
      'MySession_id': localStorage['sessionIdRoom']

    };



    var otCore;

    var connectionCount = 0;
    var stk: string = localStorage['sessionIdRoom'];
    var ltk: string = localStorage['tokenSession'];
    this.globalSessionId = stk;


    const options = {
      credentials: {


        apiKey: '47271114',
        sessionId: stk,
        token: ltk
      },
      // A container can either be a query selector or an HTMLElement
      // eslint-disable-next-line no-unused-vars
      streamContainers: function streamContainers(pubSub, type, data) {
        return {
          publisher: {
            camera: '#cameraPublisherContainer',
            screen: '#screenPublisherContainer',
          },
          subscriber: {
            camera: '#cameraSubscriberContainer',
            screen: '#screenSubscriberContainer',
          },
        }[pubSub][type];
      },
      controlsContainer: '#controls',
      packages: ['textChat', 'screenSharing', 'annotation', 'archiving'],
      communication: {
        autoSubscribe: true,
        subscribeOnly: false,
        connectionLimit: null,
        callProperties: null,
      },
      textChat: {
        name: [localStorage['nameJoiner']], // eslint-disable-line no-bitwise
        waitingMessage: 'Messages will be delivered when other users arrive',
        container: '#chat',

      },
      screenSharing: {
        extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
        annotation: true,
        externalWindow: false,
        dev: true,
        screenProperties: null, // Using default
      },
      annotation: {

      },
      archiving: {
        startURL: 'https://api.opentok.com',
        stopURL: 'https://api.opentok.com',
      },
    };
    this
    /** Application Logic */
    const app = () => {

      const state = {
        connected: false,
        active: false,
        publishers: null,
        subscribers: true,
        meta: null,
        localAudioEnabled: true,
        localVideoEnabled: true,
      };

      /**
       * Update the size and position of video containers based on the number of
       * publishers and subscribers specified in the meta property returned by otCore.
       */
      const updateVideoContainers = () => {
        const { meta } = state;
        const sharingScreen = meta ? !!meta.publisher.screen : false;
        const viewingSharedScreen = meta ? meta.subscriber.screen : false;
        const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;

        const videoContainerClass = `App-video-container ${(sharingScreen || viewingSharedScreen) ? 'center' : ''}`;
        document.getElementById('appVideoContainer').setAttribute('class', videoContainerClass);

        const cameraPublisherClass =
          `video-container ${!!activeCameraSubscribers || sharingScreen ? 'small' : ''} ${!!activeCameraSubscribers || sharingScreen ? 'small' : ''} ${sharingScreen || viewingSharedScreen ? 'left' : ''}`;
        document.getElementById('cameraPublisherContainer').setAttribute('class', cameraPublisherClass);

        const screenPublisherClass = `video-container ${!sharingScreen ? 'hidden' : ''}`;
        document.getElementById('screenPublisherContainer').setAttribute('class', screenPublisherClass);

        const cameraSubscriberClass =
          `video-container ${!activeCameraSubscribers ? 'hidden' : ''} active-${activeCameraSubscribers} ${viewingSharedScreen || sharingScreen ? 'small' : ''}`;
        document.getElementById('cameraSubscriberContainer').setAttribute('class', cameraSubscriberClass);

        const screenSubscriberClass = `video-container ${!viewingSharedScreen ? 'hidden' : ''}`;
        document.getElementById('screenSubscriberContainer').setAttribute('class', screenSubscriberClass);
      };


      /**
       * Update the UI
       * @param {String} update - 'connected', 'active', or 'meta'
       */
      const updateUI = (update) => {
        const { connected, active } = state;

        switch (update) {
          case 'connected':
            if (connected) {
              document.getElementById('connecting-mask').classList.add('hidden');
              document.getElementById('start-mask').classList.remove('hidden');
            }
            break;
          case 'active':
            if (active) {
              document.getElementById('cameraPublisherContainer').classList.remove('hidden');
              document.getElementById('start-mask').classList.add('hidden');

              document.getElementById('controls').classList.remove('hidden');
            }
            break;
          case 'meta':
            updateVideoContainers();
            break;
          default:
            console.log('nothing to do, nowhere to go');
        }
      };

      /**
       * Update the state and UI
       */
      const updateState = (updates) => {

        Object.assign(state, updates);
        Object.keys(updates).forEach(update => updateUI(update));
      };

      /**
       * Start publishing video/audio and subscribe to streams
       */
      const startCall = () => {
        otCore.startCall()
          .then(function ({ publishers, subscribers, meta }) {
            updateState({ publishers, subscribers, meta, active: true });
          }).catch(function (error) { console.log(error); });
      };

      /**
       * Toggle publishing local audio
       */
      const toggleLocalAudio = function () {
        const enabled = state.localAudioEnabled;
        otCore.toggleLocalAudio(!enabled);
        updateState({ localAudioEnabled: !enabled });
        const action = enabled ? 'add' : 'remove';
        document.getElementById('toggleLocalAudio').classList[action]('muted');
      };



      /**
       * Toggle publishing local video
       */
      const toggleLocalVideo = () => {

        const enabled = state.localVideoEnabled;
        otCore.toggleLocalVideo(!enabled);
        updateState({ localVideoEnabled: !enabled });
        const action = enabled ? 'add' : 'remove';
        document.getElementById('toggleLocalVideo').classList[action]('muted');
      };

      /**
       * Subscribe to otCore and UI events
       */

      const SendToAll = () => {

        this.session.signal({
          type: "text-chat",
          data: {
            'FullName': 'ahmad',
            'Message': 'hi',
            'photo_path_url': ''
          },
          to: this.connection_id_test
        },
          function (error) {
            if (error) {

            } else {

            }
          }
        );


      };


      const Tk = () => {



        otCore.disconnect();
        this.router.navigate(['..']);
      };

      const createEventListeners = () => {
        const events = [
          'subscribeToCamera',
          'unsubscribeFromCamera',
          'subscribeToScreen',
          'unsubscribeFromScreen',
          'startScreenShare',
          'endScreenShare',
        ];
        events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {

          updateState({ publishers, subscribers, meta });
        }));
        /////////here i can controll events and add events from events list
        document.getElementById('start').addEventListener('click', startCall);

        document.getElementById('toggleLocalAudio').addEventListener('click', toggleLocalAudio);
        document.getElementById('toggleLocalVideo').addEventListener('click', toggleLocalVideo);

        document.getElementById('disconnectMe').addEventListener('click', Tk);



      };






      /**
       * Initialize otCore, connect to the session, and listen to events
       */
      const init = () => {

        otCore = new AccCore(options);


        otCore.connect().then(() => {
          updateState({ connected: true });




          this.session.connect(ltk, function (error) {

            if (error) {

            } else {


            };
          });

        });



        createEventListeners();

        otCore.on("connectionCreated", (event) => {

          connectionCount++;
          this.connection_id_test = event.connection;

          displayConnectionCount();
        });
     /*   this.session.on("sessionDisconnected", (event) => {

          connectionCount--;
          displayConnectionCount();

        });*/

        this.session.on("streamDestroyed", function (event) {

        });
        otCore.on("connectionDestroyed", (event) => {

          connectionCount--;

          displayConnectionCount();
        });


        //signal get
        this.session.on("signal:text-chat", (event) => {
          this.dateCh = new Date();
          let x = JSON.parse(event.data);

          if(x.photo_path_url == null)
       x= "../../../assets/images/chat_temp.png";
          let toCh = `<div class="ots-message-item">


        <div class="ots-user-name-initial image-ch"> <img src="${x.photo_path_url}" alt="no image" ></div>
        <div class="ots-item-timestamp text-left"> <span >${this.dateCh} </span></div>
        <div class="ots-item-text">
        <span> ${x.Message}</span>
        </div>

              </div>`;
          var el = document.createElement('div');
          el.innerHTML = toCh;
          document.getElementById("messagesHolder").append(el);




        });

        //End signal get

        function displayConnectionCount() {

         // (<HTMLInputElement>document.getElementById("connectionCountFieldtwo")).innerText = connectionCount.toString();
        }
      };

      init();

    };

    document.addEventListener('DOMContentLoaded', app);

  }

  /* publish() {


     this.session.publish(this.publisher, (err) => {
       if (err) {
         alert(err.message);
       } else {
         this.publishing = true;
       }
     });
   }*/
  startArchive() {





    let SessionId = {
      'GlobalSession': this.globalSessionId
    };



    this.http.post<any>(`${BaseUrl}/api/startAch`, SessionId, this.headers).subscribe(
      data => {
        localStorage['archiveId'] = data['id'];

      }
    );
  };
  stopArchive() {


    let archId = localStorage['archiveId'];


    this.http.get<any>(`${BaseUrl}/api/stopAch/${archId}`, this.headers).subscribe(

      data => {

      }
    );
  };


  mAll() {

    let SendData = {

    };


    this.http.post<any>(`${BaseUrl}/api/muteAll`, SendData, this.headers).subscribe(
      data => {




      }
    );


  }




  updateStateStud(FullName) {

    let SendData = {
      'MySession_id': this.globalSessionId,
      'FullName': FullName
    };
    let sendToGetVisit = {
      'MySession_id': localStorage['sessionIdRoom']

    };

    this.http.post<any>(`${BaseUrl}/api/ChangePub`, SendData, this.headers).subscribe(
      data => {



      }
    );

    /////

    this.SubscribersList = [];



  }



}


