import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data-service/data.service';
import { NotificationService } from '../notification-service/notification.service';

@Injectable({
  providedIn: 'root'
})
export class FireBaseService {
  fireBaseToken$: string;
  private count = 0;
  private _newNotificationCount = new BehaviorSubject<any>(this.count);
  public newNotificationCount :Observable<any> = this._newNotificationCount.asObservable()
    constructor(
    private angularFireMessaging: AngularFireMessaging,
    private dataService:DataService,
    private notificationService:NotificationService
    
  ) { }

  requestPermission() {
    this.angularFireMessaging.requestToken.pipe(
      map(
        res=>{ return res;}
      )
    ).subscribe(
      (token) => {
        this.fireBaseToken$ = token;
        console.log(token)
        let obj = {
          Fcm:token,
        }
        if(token)
        this.dataService.post(obj,'addToTop').subscribe(
          res=>{
           
          }
        )
        return token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      },
      ()=>{
        
      }
    );
  }
   getFireToken():Observable<any>{
    return this.angularFireMessaging.requestToken;
      
}
  receiveMessage() {
    
    this.angularFireMessaging.messages.subscribe(
      (payload :any) => {
        console.log("new message" , payload)
        this.count++;
        this.setNewNotificationCount(this.count)
        this.notificationService.notify('info',payload.notification.title,payload.notification.body)
      }),
      (err) => {
        console.error('Unable to get message .', err);
      }
  }
  setNewNotificationCount(count){
    this._newNotificationCount.next(count)
  }

  
}
