export const locale = {
    lang: 'en',
    data : {
        LANDING_PAGE :{
            LEARN_ENGLISH :'Learn English with a Crowd',
            ITS_TIME_TO_BREAK:'It\'s time to break the ice! Let\'s improve our English & public speaking skills in front of an audience.',
            VISIT_WEBSITE :'Visit Website',
            APP_STORE : 'App Store',
            PLAY_STORE: 'Google Play'
        },
        FOOTER:{
            PRIVACY_POLICY : 'Privacy Policy',
            TERMS_CONDITIONS : 'Terms & Conditions',
            CONTACT_US :'Contact us',
            BLOGS : 'Blogs',
            COPYRIGHT:'Copyrights © 2021 WRITALk'
        },
        COMMON:{
             DIR: "ltr",
        }
    }
}