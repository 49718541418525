<!-- <p-toast></p-toast> -->
<div class="container  bg-websit p-0  min-h overflow-hidden" style="max-width: 100% !important;    overflow-x: hidden;">

    <app-website-header (linkClicked)="linkClicked($event)"></app-website-header>
    <div class="row  p-pt-3 bg-websit ">
        <div class="first-col-login text-center h-100 bg-websit column-display">
            <!-- <img class="img-fluid" src="assets/images/websiteImages/img8.png" alt=""> -->
            <img class="img-fluid tutor-image" src="assets/images/websiteImages/Tutor.svg" alt="">
        </div>
        <div class="seconed-col p-mt-3 text-center bg-websit">
            <div class="row">
                <label style="font-weight: 900;color: white;font-size: 40px;">Welcome Back!</label>
            </div>
            <div class="row">
                <label style="font-weight: 400;color: #D1D1D1;font-size: 20px;">Log in to your account
            </label>

            </div>
            <div class="row p-mt-6">
                <form [formGroup]="loginForm" (ngSubmit)="login()" class="p-mb-4">
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-8" [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">


                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-envelope"></i></span>
                                </div>

                                <input formControlName="email" autocomplete="new-email" class="form-control contact-input" type="text" name="" id="" placeholder="Email">
                            </div>
                            <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
                                <div *ngIf="formControls.email.errors.required">Email is required</div>
                            </div>
                            <div *ngIf="formControls.email.errors?.pattern" class="has-error">
                                EMAIL INVALID
                            </div>
                        </div>
                    </div>
                    <div class="row p-mt-4 justify-content-center">
                        <div class="col-8" [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">

                            <div class="input-group">
                                <div class="input-group-prepend" style="width: 42px;">
                                    <span class="w-input-group" id="basic-addon1"><i class="pi pi-lock"></i></span>
                                </div>
                                <p-password formControlName="password" autocomplete="new-password" [feedback]="false" [toggleMask]="true" placeholder="Password" [ngStyle]="{width:'calc(100% - 42px)'}"></p-password>

                            </div>
                            <div *ngIf="isSubmitted && formControls.password.errors" class="help-block has-error">
                                <div *ngIf="formControls.password.errors.required">Password is required</div>
                                <div *ngIf="formControls.password.errors.minlength">Password must be at least 6 characters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row text-end justify-content-center">
                        <a class="forget-link col-8 p-mt-2" routerLink="/website/forgetPassword">  Forget Password?    </a>
                    </div>
                    <div class="row p-mt-4 justify-content-center ">
                        <div class="col-12 p-mt-3">
                            <p-progressSpinner *ngIf="isLogged" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                            <button *ngIf="!isLogged" type="submit" class="btn w-btn-info col-8 btn-font-size login-text bold-txt">Login</button>
                        </div>
                    </div>
                    <div class="row text-center justify-content-center forget-link d-block p-my-3" style="font-size: 12px;">
                        Don't have an account?<a class="text-white bold-txt" routerLink='/website/TutorRegister'>Register</a>
                    </div>
                </form>
            </div>

        </div>
    </div>


</div>

<app-footer></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
