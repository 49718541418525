<div class="wrapper_contentBlock">

    <div class="background-base">
        <div class="row justify-content-center head-ca" style="font-size: 13px;">
            <div class="col p-align-center p-d-flex p-mx-4">
                <span class="span-red p-mx-2"></span>
                <label>Cancelled</label>
            </div>
            <div class=" col p-align-center p-d-flex p-mx-4">
                <span class="span-blue p-mx-2"></span>
                <label>Avaialable</label>
            </div>
            <div class="col p-align-center p-d-flex p-mx-4">
                <span class="span-green p-mx-2 "></span>
                <label style="width: max-content;">On Going</label>
            </div>
            <div class="col p-align-center p-d-flex p-mx-4">
                <span class="span-secondary p-mx-2"></span>
                <label style="width: max-content;">Avaialable for a Backup Tutor</label>
            </div>
            <div class="col p-align-center p-d-flex p-mx-4">
                <span class="span-black p-mx-2"></span>
                <label>Completed</label>
            </div>
        </div>
        <p-fullCalendar (eventClick)="onClickEvent($event)" #calendar [events]="events" [options]="options"></p-fullCalendar>

    </div>
</div>