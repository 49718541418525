<div class="p-p-4  bold-txt bg-websit container " style="max-width: 100% !important;    overflow: hidden;">
    <div class="row bold-txt">
        Terms of Service
    </div>
    <div class="row bold-txt h3 p-my-2">
        TERMS OF USE
    </div>
    <div class="row">
        <div class="row p-my-1">
            Please read carefully the “Terms of Use” before using our website and our mobile application.
        </div>
        <div class="row p-my-1">
            WRITALK service users are deemed to have accepted the following terms:
        </div>
        <ul class="p-ml-5">
            <li class="p-my-2 p-ml-5">
                1- By accessing and using the website “writalk.com” or using the WRITALK service, it has been signified that, whether registered or not all visitors, user and others who access the service (“User”) have read, understood and agree to be bound by this Terms
                of Use and to the collection and use of their information as set forth in our privacy policy statement page.
            </li>
            <li class="p-my-2 p-ml-5">
                2- All services and contents in the website and mobile application hereby belong to WRITALK, LLC.…………………. (shall be hereafter referred to as; WRITALK) located in Shop 245, Building 2648, Road 5720, Block257, The Lagoon Amwaj Islands, The Kingdom of Bahrain,
                and shall be operated by this Company.
            </li>
            <li class="p-my-2 p-ml-5">
                3- User hereby accepted that he is subject to the below given terms and conditions during using the services supplied in the website and mobile application, he/she is entitled to and has legal capacity and power to sign contract according to the applicable
                laws and over 18 years old, has read and comprehend this contract and bound by the terms of this contract.
            </li>
            <li class="p-my-2 p-ml-5">
                4- This Terms of Use has been issued for unlimited time. The text defines the terms and conditions of use of the website and mobile application for the parties. User; when accepted this Terms of Use in any manner, hereby declares and undertakes that he
                shall fulfill all the rights and liabilities in full and complete.
            </li>
            <li class="p-my-2 p-ml-5">
                5- WRITALK may make changes on the Terms of Use from time to time. Those changes occurred will be notified by publishing from this website. You shall be deemed notified and informed of any changes whenever it is published from this web site. And User
                agrees that the continued use of the Services after such changes have been published, this will constitute the acceptance of such revised Terms of Use.
            </li>
        </ul>
    </div>
    <div class="row bold-txt h3 p-my-2">
        6- DEFINITIONS
    </div>
    <div class="row p-my-2 p-ml-5">
        "Service": means the types of service- not limited with them - which provides opportunity for practice during the session either in English or in further foreign languages through a consultant over an interactive video or platform of which the software
        in the website or mobile application has been developed by WRITALK and/ or the relevant sub- software licenses and their rights have been acquired by WRITALK.
    </div>
    <div class="row p-my-2 p-ml-5">
        "Consultant" : is the third and real persons who have an agreement with WRITALK in order to practice either primarily in English or in any other foreign language with the user over the website or mobile application.
    </div>
    <div class="row p-my-2 p-ml-5">
        "Package": is the limited video packages which are purchased by the member for the supply of Service for a fee under the tariff and conditions specified in the website and mobile application and which provide a specific minute and/ or time communication
        opportunity with the consultant as per the feature of the package.
    </div>
    <div class="row p-my-2 p-ml-5">
        "Session": is the session which involves all types of video and/ or audio interaction in which the member and consultant meet under a virtual environment so that the service could be supplied to the member and of which its period is determined by the
        selection of one of the packages supplied by WRITALK.
    </div>
    <div class="row p-my-2 p-ml-5">
        "Video": is the integration of audio and images having digital features obtained during the session between the member and the consultant.
    </div>
    <div class="row bold-txt h3 p-my-2">
        7- RIGHTS AND RESPONSIBILITIES OF THE PARTIES REGARDING THE USE OF SERVICE
    </div>
    <div class="row p-my-2 p-ml-5">
        -WRITALK reserves its right to make alterations on the product and Services which are offered with the prices indicated in the website and mobile application, at any time.
    </div>
    <div class="row p-my-2 p-ml-5">
        -WRITALK hereby accepts and undertakes that; the User shall be allowed to utilize the Services which comprise the subject of the service contract except technical defects and force major conditions.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts in advance that; he shall not act reverse engineering during the use of the website and mobile application or shall not act in any manner in order to find and reach the source code thereof otherwise he shall be responsible for the
        losses to born before 3rd parties and he shall be subject to legal and penal procedures in this respect.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts that; he shall be solely responsible for the losses to incur due to wrong and deficient misinformation he provided during his membership process to the website and mobile application and WRITALK may terminate its membership unilaterally
        without prior notice in case of his misinformation and violation of the contract.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts that; as regards his activities in the site and mobile application, in any part of the site or regarding his communications, in the sessions made with the consultant, he shall not act and make declarations against general ethics and
        morality, against law, in the manner harming personal rights, in misleading, aggressive, explicit, pornographic manner, against copyrights, in the manner involving racist and hate approaches, in the manner encouraging illegal actions and he shall
        not produce and share such kinds of contents. Otherwise, he shall be fully and exclusively responsible for the losses to occur in this respect, and in such a case WRITALK officials may suspend, terminate such accounts and reserve their rights
        to pursue legal proceedings. For this reason, in case of any information requests regarding event and user accounts from the judicial authorities; WRITALK reserves its right to share such information with these authorities.
    </div>

    <div class="row p-my-2 p-ml-5">
        -WRITALK does not provide equipment to access the Service. User‘s access to website or mobile application, his internet connection for service supply and services for (sms) shall be provided by the user itself through the operators and service suppliers
        which the user is subscribed to and internet connection and SMS fees shall be paid by the User.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User is liable to maintain necessary hardware and devices which provide internet connection in order to be capable of using the Service and so that the Service may provide the utility as expected and to operate these instruments in due form. WRITALK
        shall not be responsible for any deficiency, failure, irregularity, error and/ or damage in connection with the internet and electronic communication or for any mistake and / or damage thereof or for the delays sourced from the devices which are
        used by the user for internet connection.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts, declares and commits in advance that; his unique purpose for attending the session is to make practice and improve in English and/ or any other languages preferred by the member and the service shall not be used for further purposes.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts, declares and commits in advance and irrevocably that; he shall abstain from any illegal actions against the consultant during the session as per international contracts, applicable laws and further legislation and shall not act in
        this way, he shall comply with public decency during the session with the consultant, he shall abstain from any kind of wrongful acts against the consultant and shall not act in this manner.
    </div>
    <div class="row p-my-2 p-ml-5">
        -Users accept, declare and undertake that; no advice and information, whether oral or written, obtained by himself from WRITALK or through or from WRITALK Services will create any warranty not expressly stated in these Terms of Use.
    </div>
    <div class="row p-my-2 p-ml-5">
        -USER accepts and declares that WRITALK in its sole discretion reject, refuse the post or remove any part of User’s content and reserves the right to restrict, suspend, or terminate the access to the service at any time, for any reason, with or without
        a notice and will not be liable.
    </div>
    <div class="row p-my-2 p-ml-5">
        -Supply of the Service depends on whether the consultant is available or not at the time when the user wishes to attend the session. Consultants may announce in the website and mobile application whether they are available or not or the time and day which
        they are available to provide Service. User hereby accepts, declares and undertakes in advance and irrevocably that; the consultant which he personally prefers may not be available for every time and he is aware that WRITALK has no liability to
        enable to consultant to provide service every day and every time.
    </div>
    <div class="row p-my-2 p-ml-5">
        -For enabling the service to be provided in better quality and to improve the Service ‘s efficiency as expected, consultant may take written notes during the session about the performance of the member, the training level of the foreign language which
        he wishes to practice and develop and further information regarding the member.
    </div>
    <div class="row p-my-2 p-ml-5">
        -WRITALK is not liable to direct the user’s communication with other users and consultants or to intervene in the subject communication. WRITALK, within the scope of the service, without giving any guarantee or commitment thereof, has provided to the
        user technical and technologic platform and tools so that the user shall not communicate with the users and/ or consultants which he does not wish to communicate and he shall be capable of obstructing those undesired contacts. Accordingly, the
        user shall be able to make a restriction as he wishes to do.
    </div>
    <div class="row p-my-2 p-ml-5">
        -WRITALK is not responsible for pecuniary losses and intangible damages arising out of disputes between the user and other users and consultants. WRITALK shall be never kept responsible neither directly nor indirectly for the consequences to be born against
        the use within the scope of this article.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User is not allowed to use the website, mobile application and service for commercial purpose and action including the sales, marketing, advertisement and introduction of any product, goods and/ or service, but not limited with the foregoing actions.
        Users are not allowed to collect any personal information from other users and consultants for commercial or other purposes, including e- mail, telephone number, address.
    </div>
    <div class="row p-my-2 p-ml-5">
        -The User acknowledges and agrees that he will pay the fee of the subscribed package and the fees of the package will be charged from the User’s credit card each package period automatically unless the User closes the auto renew option. The User may close
        the auto renew option and cancel the account at any time; however, there are no refunds regarding the previous period for cancellation since service provided by WRITALK is within the scope of services which are executed instantly and the user
        paid the service fee is not entitled to use its right of withdrawal. Purchased time and subscribed packages are not refundable; however, in the event of the User closing the auto renew option, any other fees will not be charged.
    </div>
    <div class="row p-my-2 p-ml-5">
        -In the event the user has been mistakenly charged for minutes not purchased, the user may request a refund.

    </div>
    <div class="row p-my-2 p-ml-5">
        -The User acknowledges and agrees that any credit card and related billing and payment information that is provided to WRITALK may be shared by WRITALK with its affiliates or any other companies who work on its behalf or with, such as third-party payments
        processors.
    </div>

    <div class="row bold-txt h3 p-my-2">
        8- INTELLECTUAL PROPERTY RIGHTS
    </div>

    <div class="row p-my-2 p-ml-5">
        -All registered or non- registered intellectual property rights in this site and mobile application, such as trade name, trade mark (including but not limited to WRITALK trade name), patent, logo, design, information and procedures, belong to the site
        operator and its owner WRITALK’, and its licensors or to the relevant person specified thereof and such intellectual property rights and the Services are protected within the scope of national and international law, including United States copyright
        law. Visiting this website / mobile application and/or utilization of the services in this site/ mobile application do not assign any right within the scope of intellectual property.
    </div>
    <div class="row p-my-1">
        -WRITALK grants the User a limited personal, non-exclusive, non-transferable licence to use the Service for personal and non-commercial use. WRITALK reserves the right to terminate this license at any time for any reason.
    </div>
    <div class="row p-my-2 p-ml-5">
        -Contents, product pictures, logos, drawings, models, videos, formats included in the site and mobile application are not allowed to be copied, multiplied, published, submitted and/ or transferred. Site / mobile application , either completely or partly,
        may not be used in another internet site without permission. In such a violation, the user is liable to compensate for the losses to occur in this respect.
    </div>
    <div class="row bold-txt h3 p-my-2">
        9- CONFIDENTIAL INFORMATION
    </div>
    <div class="row p-my-2 p-ml-5">
        WRITALK may collect registration and other information and the collection and use of this information is governed by terms and policies regarding confidentiality are also explained in the Privacy Policy Statement page.
    </div>
    <div class="row bold-txt h3 p-my-2">
        10- RECORDS AND SECURITY
    </div>
    <div class="row p-my-2 p-ml-5">
        -User should submit correct, full and updated record information. Otherwise, this contract shall be deemed to have been violated and the user's permit for making operation may be revoked without a further notice.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User is personally responsible for his password and account security which is used for his access to the website and mobile application. Otherwise, WRITALK shall not be kept responsible for the probable data losses and security violations or for the
        damages of the hardware and devices.
    </div>
    <div class="row p-my-2 p-ml-5">
        -User hereby accepts that; he must notify WRITALK immediately of any breach of security or unauthorized use of his account. WRITALK will not be liable for any losses caused by any unauthorized use of the account.
    </div>
    <div class="row bold-txt h3 p-my-2">
        11- GOVERNING LAW
    </div>
    <div class="row p-my-2 p-ml-5">
        Enforcement of these terms of use will be governed by the laws of the Kingdom of Bahrain. The Ministry of Justice located in Bahrain is the competent authority for the settlement of all kinds of disputes born from the application and interpretation of
        these terms.
    </div>
    <div class="row bold-txt h3 p-my-2">
        12- MISCELLANEOUS
    </div>
    <div class="row p-my-2 p-ml-5">
        -In case the parties care not able to fulfill their liabilities born from the contract due to various reasons which are not under their control such as; natural disasters, fire, explosions, civil wars, wars, rebels, civil commotions, proclamation of mobility,
        strike, lockout and epidemics, communication infrastructure and internet failures, power cut (shall be referred as; force major” completely, below), parties shall not be liable for such non- fulfillment. Within this process, parties’ right and
        liabilities born from this contract shall be suspended.
    </div>
    <div class="row p-my-2 p-ml-5">
        -In case any of the terms of this text becomes invalid either completely or partially, the remaining part of the terms shall remain in force.
    </div>
    <div class="row p-my-2 p-ml-5">
        -WRITALK may alter the Services offered in this website and the terms of the contract either partially or completely whenever wishes to do so. Such amendments shall be effective as of the date they are published in the website. It is the user's responsibility
        to follow up the amendments. Users, by continuing to utilize the services provided, shall be deemed to have accepted such amendments.
    </div>
    <div class="row p-my-2 p-ml-5">
        -All notifications whether such notifications are required by law or are for marketing or other business related purposes to be sent to either parties in relation with this terms and service contract shall be sent to WRITALK’ e-mail address which is already
        known and to the user’ s e-mail address specified in the membership form. User accepts that; the e- mail address indicated during relevant process and his address declared by him therein are his valid notification address and he shall immediately
        inform the new address in case of any change thereof, otherwise the notifications sent to these addresses shall be effective.
    </div>
    <div class="row p-my-2 p-ml-5">
        -Parties’ records and documents and computer records and fax records shall be accepted as evidence for all kinds of disputes that may arise out of this contract between the parties, and the user hereby accepts that he is not allowed to object against
        these records.
    </div>
    <div class="row bold-txt h3 p-my-2">
        Contacting Us:
    </div>
    <div class="row p-my-2 p-ml-5">
        If you have any questions or concerns about our Services or these Terms, you may contact with us at: info@writalk.com
    </div>

</div>