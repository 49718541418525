<!-- <p-toast></p-toast> -->
<div class="container  bg-websit p-0  min-h overflow-hidden" style="max-width: 100% !important;  overflow-x: hidden;">

    <app-website-header (linkClicked)="linkClicked($event)"></app-website-header>
    <div class="row  p-pt-3 bg-websit">
        <div class="first-col-login text-center h-100 bg-websit column-display">
            <!-- <img class="img-fluid" src="assets/images/websiteImages/img8.png" alt=""> -->
            <img class="img-fluid tutor-image" src="assets/images/websiteImages/Tutor.svg" alt="">
        </div>
        <div class="seconed-col p-mt-6 text-center bg-websit">
            <div class="row">
                <label style="font-weight: 900;color: white;font-size: 40px;">Email verification</label>
            </div>
            <div class="row justify-content-center">
                <label class="col-lg-8" style="font-weight: 400;color: #D1D1D1;font-size: 16px;">Enter OTP sent to your Email
            </label>
            </div>
            <div class="row p-mt-2">
                <form class="p-mb-4">
                    <div class="row p-mt-4 p-pb-6 justify-content-center p-px-6">
                        <div class="col-3 col-md-2 col-lg-2 ">
                            <input #input1 maxlength="1" class="form-control contact-input text-center" type="text" [(ngModel)]="code[0]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                        <div class="col-3 col-md-2 col-lg-2 ">
                            <input #input2 maxlength="1" class="form-control contact-input text-center" type="text" [(ngModel)]="code[1]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                        <div class="col-3 col-md-2 col-lg-2 ">
                            <input #input3 maxlength="1" class="form-control contact-input text-center" type="text" [(ngModel)]="code[2]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                        <div class="col-3 col-md-2 col-lg-2 ">
                            <input #input4 maxlength="1" class="form-control contact-input text-center" type="text" [(ngModel)]="code[3]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                        <!-- <div class="col-2">
                            <input #input5 maxlength="1" class="form-control contact-input" type="text" [(ngModel)]="code[4]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div>
                        <div class="col-2">
                            <input #input6 maxlength="1" class="form-control contact-input" type="text" [(ngModel)]="code[5]" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                        </div> -->
                    </div>

                    <div class="row p-mt-2 justify-content-center">
                        <div class="col-12 ">
                            <p-progressSpinner *ngIf="isLoading" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                            <button *ngIf="!isLoading" type="submit" class="btn w-btn-info col-8  login-text bold-txt" (click)="submit()">Verify</button>

                        </div>
                    </div>
                    <div class="row text-center justify-content-center forget-link d-block p-mt-2" style="font-size: 12px;">
                        Didn’t receive the code?<a class="text-white" (click)="resend()">Resend code</a>

                    </div>
                </form>
            </div>
        </div>
    </div>




</div>
<app-footer></app-footer>
<app-whatsapp-floating></app-whatsapp-floating>
