import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-email-vertification',
  templateUrl: './email-vertification.component.html',
  styleUrls: ['./email-vertification.component.css']
})
export class EmailVertificationComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    sessionStorage.setItem("tempData",JSON.stringify(this.email));
  }
  constructor(
    private router:Router,
    private sharedService:SharedService,
    private dataService:DataService,
    private route : ActivatedRoute,
    private location:Location) { }
  isSubmitted = false;
  isLoading = false;
  code = ['','','','',]
  email : string
  ngOnInit(): void {
    this.sharedService.email.subscribe(
      res=>{
        if(res)
        this.email  = res;
        else
        this.router.navigate(['/website/forgetPassword'])
      }
    )

    try {
      this.email =JSON.parse(atob(this.route.snapshot.params.data)).email;
    }catch{
      this.location.back()
    }
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }
  
  submit(){
    this.isSubmitted =  true;
    this.isLoading =true;
    let obj = {Code:this.code.join('').toString(),Email:this.email}
    this.dataService.post(obj,'validateOtp').subscribe(
      res=>{
        if(res.body['Result']['statusCode']==200){
          
      this.isLoading  = false;
      this.isSubmitted = false;
        this.router.navigate(['/website/resetPassword',btoa(JSON.stringify({email :this.email,code :this.code.join('').toString()}))])

        }
      },err=>{
        this.isLoading  = false;
        this.isSubmitted = false;
      }
    )
   

  }

  resend(){
    if(!this.email)
    return;
    this.isLoading = true;
     this.dataService.post({Email:this.email},'OTPEmail').toPromise().then(
      res=>{
        if(res.body['Result']['statusCode']==200){
          this.sharedService.setNewEmail(this.email);
          this.isLoading = false;
        }
      }
    )
  }
}
