import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { loginInterface } from 'src/app/interfaces/login.interface';
import { DataService } from 'src/app/services/data-service/data.service';
import { FireBaseService } from 'src/app/services/fire-base/fire-base.service';
import { LoginService } from 'src/app/services/login/login.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';

@Component({
  selector: 'app-tutor-login',
  templateUrl: './tutor-login.component.html',
  styleUrls: ['./tutor-login.component.css']
})
export class TutorLoginComponent implements OnInit ,OnDestroy{
  currentAdminPermission = [];
  constructor(private formBuilder: FormBuilder,
    private elementRef: ElementRef,

    private dataService: DataService,
    private loginService: LoginService,
    private router: Router,
    private notificationService: NotificationService,
    private fireBaseService: FireBaseService) { }
  isSubmitted = false;
  loginForm: FormGroup;
  isLogged = false;
  ngOnInit(): void {
    this.loginService.logout(false);
    this.creatForm();
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }
  login() {
    this.isSubmitted = true;
    const controls = this.loginForm.controls;
    if (this.loginForm.invalid) {
      if (controls.email.invalid) {
        this.notificationService.notify('error', 'Error', 'Email is Required.')

      }
      if (controls.password.errors.required) {
        this.notificationService.notify('error', 'Error', 'Password is Required.')

      }
      console.log(controls.password.errors)
      if (controls.password.errors.minlength) {
        this.notificationService.notify('error', 'Error', 'Password must be at least 6 characters.')

      }
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      }
      );
      return;
    }

    this.tryLogin();

  }
  creatForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })

  }
  tryLogin() {
    this.isLogged = true;

    let Obj = this.prepareInfo();
    this.dataService.post(Obj, 'login', null).subscribe(
      res => {
        console.log(res)
        if (res.status == 200) {
          if ((res.body as any).user.Type != "tutor") {
            this.notificationService.notify('error', 'Error', 'Email or Password is not correct.')

            this.isLogged = false;
            return

          }
          this.loginService.setToken(JSON.stringify((res.body as any).token));
          this.loginService.setUser(JSON.stringify((res.body as any).user));
          // set role here ...
          let role = JSON.stringify((res.body as any).user.Type.toUpperCase())
          this.loginService.setRole(role)
          this.getAdminPermission();
        }
        else {
          this.isLogged = false;
        }
      },
      err => {

        this.isLogged = false;

      }
    )
  }
  prepareInfo(): loginInterface {
    const controls = this.loginForm.controls;
    const _Info: loginInterface = {
      Email: controls["email"].value,
      Password: controls["password"].value
    }
    return _Info;
  }
  getAdminPermission() {
    const controls = this.loginForm.controls;

    let obj = {
      email: controls["email"].value
    }
    this.dataService.post(obj, 'indexAdmin').subscribe(
      res => {
        if (res.body['Result']['statusCode'] == 200) {
          this.currentAdminPermission = [];
          res.body['Result']['data'].forEach(element => {
            this.currentAdminPermission.push(element.name);
          });
          // set user permissions
          this.loginService.setPermission(this.currentAdminPermission);
          this.notificationService.notify('success', 'Success', 'Login Successfuly.')

          this.router.navigate(["/"]);

        }

      },
      err => {
        //  this.notificationService.notify('error','Error','Error.')
        ;

        this.isLogged = false;

      }
    )
    this.addDeviceToTopic()

  }
  get formControls() { return this.loginForm.controls; }

  addDeviceToTopic() {
    let obj = {
      Fcm: '',
    }
    this.fireBaseService.getFireToken().subscribe(
      res => {
        obj.Fcm = res;
        if(res)
        this.dataService.post(obj, 'addToTop').subscribe(
          res => {
            this.fireBaseService.receiveMessage();
          }
        )
      }
    )
  }
  
  ngOnDestroy(): void {
    this.elementRef.nativeElement.remove();
    }
}
