import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterModel } from 'src/app/models/filter.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { FireBaseService } from 'src/app/services/fire-base/fire-base.service';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.css']
})
export class NotificationPopupComponent implements OnInit {
 @Input() show = false;
 @Output() close = new EventEmitter<any>();
 userId : any;

 notifications = [];
  constructor(private dataService:DataService,private loginService: LoginService,private firebaseSevice:FireBaseService,) { }


  ngOnInit(): void {
    this.userId= this.loginService.getUserInfo().id ;
    this.getNotifications();
  }

  subscribeToNewNotification(){
    this.firebaseSevice.newNotificationCount.subscribe(
      res=>{
        this.getNotifications();
      }
    )
  }
  closeClicked(){
    this.close.emit(true);
  }
  getNotifications(){
    let filter = new FilterModel();
    filter.items_list=1000;
    
      this.dataService.get(`showNotificationForUser/${this.userId}`).subscribe(
        res=>{
          if (res.body.Result.data && res.body.Result.data[0])
          this.notifications = res.body.Result.data[0];
        }
      )
  }

}
