<!-- <p-toast></p-toast> -->
<div class="container  bg-websit p-0  min-h overflow-hidden" style="max-width: 100% !important;    overflow-x: hidden;">


    <app-website-header (linkClicked)="linkClicked($event)"></app-website-header>
    <div class="row p-pt-6 bg-websit">
        <div class="first-col-login text-center h-100 bg-websit column-display">
            <!-- <img class="img-fluid" src="assets/images/websiteImages/img8.png" alt=""> -->
            <img class="img-fluid tutor-image" src="assets/images/websiteImages/Tutor.svg" alt="">
        </div>
        <div class="seconed-col p-mt-3 text-center bg-websit">
            <div class="row">
                <label style="font-weight: 900;color: white;font-size: 40px;">Forgot Password</label>
            </div>
            <div class="row justify-content-center">
                <label class="col-lg-8" style="font-weight: 400;color: #D1D1D1;font-size: 16px;">Please enter your registered email
                    to reset your password
            </label>
            </div>
            <div class="row p-mt-2">
                <form [formGroup]="forgetFrom" (ngSubmit)="submit()" class="p-mb-4">
                    <div class="row p-mt-4  justify-content-center">
                        <div class="col-8" [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
                            <input formControlName="email" class="form-control contact-input login-text bold-txt" type="text" name="" id="" placeholder="Email">
                            <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
                                <div *ngIf="formControls.email.errors.required">Email is required</div>
                            </div>
                            <div *ngIf="formControls.email.errors?.pattern" class="has-error">
                                EMAIL INVALID
                            </div>
                        </div>
                    </div>

                    <div class="row p-mt-6 justify-content-center">
                        <div class="col-12 ">
                            <p-progressSpinner *ngIf="isLoading" [style]="{width: '75px', height: '75px'}" styleClass="custom-spinner" strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                            <button *ngIf="!isLoading" type="submit" class="btn w-btn-info col-8  login-text bold-txt">Verify</button>
                        </div>
                    </div>
                    <div class="row text-center justify-content-center forget-link d-block p-mt-2" style="font-size: 12px;">
                        Didn’t receive the code?<a class="text-white">Resend code</a>
                    </div>
                </form>
            </div>
        </div>
    </div>





</div>
<app-footer></app-footer>

<app-whatsapp-floating></app-whatsapp-floating>
