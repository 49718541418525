import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TutorInterface } from 'src/app/interfaces/tutor.interface';
import { DataService } from 'src/app/services/data-service/data.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';
import { DateAndTimeService } from 'src/app/services/date-and-time-service/date-and-time.service';

@Component({
  selector: 'app-tutor-register',
  templateUrl: './tutor-register.component.html',
  styleUrls: ['./tutor-register.component.css'],
})
export class TutorRegisterComponent implements OnInit, AfterViewInit ,OnDestroy{

  constructor(private fb: FormBuilder,
    private elementRef: ElementRef,
    private notificationService: NotificationService,
    private dataService: DataService,
    private router: Router,
    private dateAndTimeService: DateAndTimeService
  ) { 
    document.body.scrollTop = 0;
   
  }

  EnglishTypes = [
    { name: 'Native', code: 'native' },
    { name: 'Professional', code: 'professional' },]
  Qualifications = [
    { name: 'Diploma', code: 'Diploma' },
    { name: 'Bachelor', code: 'Bachelor' },
    { name: 'Master', code: 'Master' },
    { name: 'phD', code: 'phD' },
    { name: 'Other', code: 'photherPleaseTypeD' },

  ]
  gender = [
    { name: 'male', code: 'male' },
    { name: 'female', code: 'female' },


  ]
  ngAfterViewInit(): void {
   
    this.createForm()
    this.subscribeToPasswordChange();
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.remove();
    }
  registerForm: FormGroup;
  isSubmitted = false;
  isLogged = false;
  qualifications = [];
  countries = [];
  categories = [];
  cv: any;
  isConfirmedPassword = true;
  isFocus = false;

  defaultDate: Date = new Date("January 31 2005 12:30");
  ngOnInit(): void {
    this.createForm();
    this.getCountries();
    this.subscribeToPasswordChange();
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }
  
  createForm() {
    this.registerForm = this.fb.group({
      FullName: ['',[ Validators.required, Validators.minLength(3)]],
      Address: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      EnglishType: ['', Validators.required],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      PhoneNumber: ['', [Validators.required]],
      Gender: ['', Validators.required],
      TeachingQualification: ['', Validators.required],
      Nationality: ['', Validators.required],
      confirmPassword: ['']
    })
  }
  register() {
    this.isSubmitted = true;
    const controls = this.registerForm.controls;
    console.log(this.prepareInfo());
    if (!this.isValidTutor()) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      return;
    }
    this.tryRegister();

  }
  isValidTutor() {
    let bool = true;
    const controls = this.registerForm.controls;

    Object.keys(controls).forEach(controlName => {
      if (controls[controlName].invalid || !controls[controlName].value) {
        console.log(controlName)
        bool = false;
      }

    }

    );
    if(!this.isConfirmedPassword)
    bool = false
    return bool
  }
  tryRegister() {
    let Obj = this.prepareInfo();
    this.isLogged = true;

    this.dataService.post(Obj, 'registerTu', null).subscribe(
      res => {
        if (res.body['Result']['statusCode'] != 200) {
          this.isSubmitted = false;
          this.isLogged = false;

          return;
        }
        this.router.navigate(['website/TutorLogin']);
        setTimeout(() => {
          this.notificationService.notify('success', 'Your Request has been Sent Successfuly', 'We will Contact You When It is Get Approved.')

        }, 500);
        this.isLogged = false;
      },
      err => {
        console.log(err)
        this.isLogged = false;
      }
    )
  }
  prepareInfo() {
    const controls = this.registerForm.controls;
    const _Info: TutorInterface = {
      email: controls["email"].value,
      Password: controls["Password"].value,
      FullName: controls["FullName"].value,
      Address: controls["Address"].value.name,
      Gender: controls["Gender"].value.name,
      Qualifications: controls["TeachingQualification"].value.name,
      PhoneNumber: controls["PhoneNumber"].value,
      Nationality: controls["Nationality"].value.name,
      EnglishType: controls["EnglishType"].value.name,
      DateOfBirth: controls["DateOfBirth"].value ? this.dateAndTimeService.formatDateWithDash(new Date(controls["DateOfBirth"].value)): '',
      CV: this.cv
    }
    console.log(_Info)
    return _Info;
  }
  get formControls() { return this.registerForm.controls; }
  getCountries() {
    this.dataService.get("COnly").subscribe(
      res => {
        this.countries = res.body.Result.data;
      }
    )
  }


  cvUrlChange(event) {
    if (event.target.files[0]) {
      this.cv = event.target.files[0]
      console.log(this.cv);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
      }
    }
  }
  onFocus(){
    this.isFocus = this.registerForm.controls['confirmPassword'].value !=this.registerForm.controls['Password'].value;
  }
  subscribeToPasswordChange() {
    console.log("subscribeToPasswordChange")
    this.registerForm.controls['confirmPassword'].valueChanges.subscribe(
      res => {
        if (res != this.registerForm.controls['Password'].value)
          this.isConfirmedPassword = false;
          else
        {
          this.isConfirmedPassword = true;
          this.isFocus = false;
        }
      }
    )
    this.registerForm.controls['Password'].valueChanges.subscribe(
      res => {

        if (this.registerForm.controls['confirmPassword'].value && res != this.registerForm.controls['confirmPassword'].value)
          this.isConfirmedPassword = false;
        else if (this.registerForm.controls['confirmPassword'].value) {
          this.isConfirmedPassword = true;
          this.isFocus = false;
        }

      }
    )
  }
}
