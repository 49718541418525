export const locale = {
    lang: 'ar',
    data : {
        LANDING_PAGE :{
            LEARN_ENGLISH :'تعلم اللغة الانكليزية مع الجمهور',
            ITS_TIME_TO_BREAK:'تطبيق رايتوك يعطيك الفرصة للتحدث في بث مباشر أمام الجمهور مع معلمين من أهل اللغة بباقات غير محدودة',
            VISIT_WEBSITE :'تصفّح الموقع',
            APP_STORE : 'حمّل التطبيق',
            PLAY_STORE : 'حمّل التطبيق'
        },
        FOOTER:{
            PRIVACY_POLICY : 'سياسة الخصوصية',
            TERMS_CONDITIONS : 'الشروط والأحكام',
            CONTACT_US       : 'تواصل معنا',
            BLOGS : 'المدونات',
            COPYRIGHT:'WRITALK 2021 © حقوق النشر',
        },
        COMMON:{
            DIR: "rtl",
       }
    }
}