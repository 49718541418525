import { Component, Input, OnInit } from '@angular/core';
import { Helper } from '../healpers/helper';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css']
})
export class LayoutsComponent implements OnInit {

  constructor() { }
  title =""

 @Input() showNotificationsList = false;
  ngOnInit(): void {

    Helper.selectedTitle.subscribe(res=>{this.title=res})
    console.log(this.title);
  }
  changeSelectedItem(event){
    this.title =event;
  }

  onShowNotification(event){
    console.log(event)
    this.showNotificationsList = event;
  }

  closeClicked(event){
    this.showNotificationsList = false;
  }
}
