<div class="w-100 h-100  bg-websit" style="padding-top: 20px;    overflow: hidden;">
    <div class="container">
        <div class="w-100 text-center pb-5">
            <img class="image-logo" src="assets/images/R.png" alt="" >
        </div>
        
        <div class="w-100 text-center pb-5">
            <div class="row text-center bg-websit p-pt-3">
                <label class="text-seconde top-pos">
                              Payment Failed    </label>
            </div>       </div>

            <div class="w-100 text-center pb-5">
                <div class="row text-center bg-websit p-pt-3">
                    <label class="text-seconde top-pos">
                                 Your payment was not successfully processed. Please try again or contact our customer support .    </label>
                </div>       </div>
      
        
    </div>
    
</div>