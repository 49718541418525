<div class="w-100 h-100  bg-websit" style="padding-top: 20px;    overflow: hidden;">
    <div class="container">
        <div class="w-100 text-center pb-5">
            <img class="image-logo" src="assets/images/websiteImages/logo.svg" alt="" >
        </div>
        <div class="w-100 text-center pb-5">
            <div class="row text-center bg-websit p-pt-3">
                <label class="text-seconde top-pos">
                    Congratulations, your payment has been processed successfully.                </label>
            </div>       </div>
        <div class="w-100 text-center pb-5">
            <div class="row text-center bg-websit p-pt-3">
                <label class="text-seconde top-pos">
                    Let's improve our English & public speaking skills in front of an audience.
                </label>
            </div>       </div>
        
    </div>
    
</div>