import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FilterModel } from 'src/app/models/filter.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.css']
})
export class GetInTouchComponent implements OnInit {

  constructor(private fb:FormBuilder,private notificationService:NotificationService,private dataService:DataService ,private router:Router) { }
  isLoading = false;
  sendSupportForm : FormGroup;
  supportTypes =[];
  isSubmittedSubscribeNow= false;
  isSubmitted = false;
  activeIndex = 0
  whatsapp_number  = environment.whatsapp_number
  ngOnInit(): void {
    this.creatForm();
    this.getSupportTypes();
  }
  creatForm() {
    this.sendSupportForm = this.fb.group({
      Name : ['',Validators.required],
      Email : ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      FeedbackMsg : ['',Validators.required],
      support_type_id : ['',Validators.required],
    })

  }
  getSupportTypes(){
    let filter = new FilterModel()
    filter.items_list=10000;
    this.dataService.get('showActiveSupportType').pipe(
      map(
        (res :any)=>{
          const data = res.body['Result']['data'].map(
            item=>({
              code :item.id,
              name : item.Name
            })
          )
          return data;
        }
      )
    ).subscribe(
      res=>{
        this.supportTypes = res
      }
    )
  }

  sendSupport(){
    this.isSubmitted = true;
    const controls = this.sendSupportForm.controls;
    if (this.sendSupportForm.invalid) {
      this.notificationService.notify('error','Error','Requirdes Fields.')

      return;
    }
    else {
    let Obj = this.prepareInfo();
    this.isLoading = true;

    this.dataService.post(Obj, 'sendGuestCustomerSuppoert', null).subscribe(
      res => {
        if (res.body['Result']['statusCode'] != 200) {

                   this.notificationService.notify('error','Error','Error.')
;
          this.isLoading = false;
          return
        }
        else{
          this.notificationService.notify('success','Success','Your Message has been sent Successfuly.')

          this.isLoading = false;
          this.isSubmitted = false;
          this.creatForm();

        }
      },

    )
    }
  }
  get formControls() { return this.sendSupportForm.controls; }

  prepareInfo()  {
    const controls = this.sendSupportForm.controls;
    const _Info = {
      Name: controls["Name"].value,
      Email: controls["Email"].value,
      FeedbackMsg: controls["FeedbackMsg"].value,
      support_type_id: controls["support_type_id"].value.code,
    }
    return _Info;
  }
  linkClicked(id) {
    this.router.navigate(['website']).then(() => {
      document.getElementById(id).scrollIntoView();
    })
  }
}
