export class SessionModel {
  id: number | string;
  Name: string;
  SessionDate: string | Date;
  SessionTime: string | Date;
  Description: string;
  Price : string | number;
  Status: string;
  SessionDurationByMin : string;
  PrimaryTutor : number | string;
  SecondaryTutor : number | string;
  StreamStatus :string;
  TutorEmail:string;
  init() {
    this.id= 0;
    this.Name ='';
    this.Status='';
    this.SessionDate='';
    this.SessionTime='';
    this.Description='';
    this.Price ='';
    this.SessionDurationByMin ='';
    this.PrimaryTutor = '';
    this.SecondaryTutor = '';
    this.StreamStatus = '';
    this.TutorEmail='';
  }
  constructor(){
    this.id= 0;
    this.Name ='';
    this.Status='';
    this.SessionDate='';
    this.SessionTime='';
    this.Description='';
    this.Price ='';
    this.SessionDurationByMin ='';
    this.PrimaryTutor = '';
    this.SecondaryTutor = '';
    this.StreamStatus = '';
    this.TutorEmail='';

  }
}
