export class FilterModel {
    querySearch :string;
    status?     :string;
    Status?     :string;

    items_list  :number;
    page :number;
    fromDate? : string|Date;
    toDate? : string|Date;
    orderType? : 'desc' | 'asc'
    order? : string;
    export : 0|1
    constructor(){
        this.page=1;
        this.items_list= 10;
        this.querySearch='%%';
        this.status='%%',
        this.Status='%%',

        this.toDate=null;
        this.fromDate=null;
        this.order = 'id';
        this.orderType= 'desc';
        this.export =0;
    }
  
}