<div class="card p-p-4 h-100">
    <h5>Tutors Statistics</h5>
    <p-chart type="doughnut" [options]="options" [data]="data" [width]="500" class="p-mb-6"></p-chart>


    <ng-container *ngFor="let item of labels;let i = index">
        <div class="row p-mb-3">
            <div class="col-6">

                <label class="bold-txt"> 
                    <span class="span-badge color-{{colors[i]}} align-text-bottom">
    
                    </span>
                    {{item}}</label>
            </div>
            <div class="col-6 text-end bold-txt">
                {{dataset[i]}}
            </div>
        </div>
    </ng-container>

</div>