import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-total-sales',
  templateUrl: './total-sales.component.html',
  styleUrls: ['./total-sales.component.css']
})
export class TotalSalesComponent implements OnInit, OnChanges {

  constructor() { }

  data: any;
  dataset = [];
  dataset_type = [];

  labels = [];
  labels_type = [];
  @Input() colors=[] 

  color_display = [];

  options: any;
  @Input() title
  @Input() countTotalSales: any;

  ngOnInit(): void {

    this.options = {
      legend: {
        position: 'bottom',
        horizontalAlign: "left", // "center" , "right"
        verticalAlign: "center",  // "top" , "bottom"

        display: false,
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTotalSales()

  }
  getTotalSales() {
    this.dataset=[];
    this.labels = [];
    this.labels_type = [];
    if (this.countTotalSales) {
      if(!this.countTotalSales?.All_ByPlans){
        let i =0 ;
      Object.entries(this.countTotalSales).forEach(([key, value]) => {
        if(key!='total'){
          this.dataset.push(value);
          this.color_display.push(this.colors[i])
        }

        this.dataset_type.push(value);


        switch (key) {
          case 'total':
            this.labels_type.push(`Total Sales`);
            break;
          case 'Apple_ALL':
            this.labels.push(`Apple`);
            this.labels_type.push(`Apple`);
            break;
          case 'Google_ALL':
            this.labels.push(`Google`);
            this.labels_type.push(`Google`);
            break;
          default:
            this.labels.push(key);
            this.labels_type.push(key);
          break;
        }
        i++
      })}
      else{
        this.countTotalSales?.All_ByPlans.forEach(element => {
            this.dataset.push(element.TotalCost)
            this.labels.push(element.Name)
            this.labels_type.push(element.Name);
            this.color_display = [...this.colors]
        })
        this.dataset_type = [...this.dataset]
      } 

      this.data = {
        labels: this.labels,
        datasets: [
          {
            // showInLegend: false,
            data: this.dataset,
            backgroundColor:this.color_display.map(item=>`#${item}`),
            hoverBackgroundColor: this.color_display.map(item=>`#${item}`)
          }],
          hoverOffset: 4

      };
    }

  }
}
