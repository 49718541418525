import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import CalendarComponent from '@fullcalendar/core/CalendarComponent';
import { FullCalendar } from 'primeng/fullcalendar';

@Component({
  selector: 'app-wrk-calendar',
  templateUrl: './wrk-calendar.component.html',
  styleUrls: ['./wrk-calendar.component.scss']
})
export class WrkCalendarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('calendar') private calendar: FullCalendar;
  // @ViewChild('calendar') fc: FullCalendar;

  constructor() {
    
   }

  @Input() events: any[] = []
  @Input() options: any;
  @Output() eventClick = new EventEmitter<any>()
  @Output () monthAfter = new EventEmitter<any>()
  @Output () monthBefore = new EventEmitter<any>()

  isReady = false;
  ngOnInit(): void {
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.calendar){
      // this for refresh the calendar
    this.calendar.ngOnDestroy();
    this.calendar.initialize()
    }
    this.options = { ...this.options };
    this.events = [...this.events];
  }
  onClickEvent(event) {
    this.eventClick.emit()
  }
  ngAfterViewInit() {
    console.log("ngAfterViewInit")
    this.bindEvents();
    this.isReady = true;
  }
  
  bindEvents() {
    
    const prevButton = this.calendar.el.nativeElement.getElementsByClassName("fc-prev-button");
    const nextButton = this.calendar.el.nativeElement.getElementsByClassName("fc-next-button");
    nextButton[0].addEventListener('click', () => {
      this.bindEvents();
      this.monthAfter.emit(true)
      this.isReady = true;
    });
    prevButton[0].addEventListener('click', () => {
      alert("prev")
      this.bindEvents();
      this.monthBefore.emit(true)
    });

  }
  unBindEvents() {
    let prevButton = this.calendar.el.nativeElement.getElementsByClassName("fc-prev-button");
    let nextButton = this.calendar.el.nativeElement.getElementsByClassName("fc-next-button");
    nextButton[0].removeEventListener('click', () => {
      console.log("nextClick")
    });
    prevButton[0].removeEventListener('click', () => {
      console.log("prevClick")
    });
  }

  ngOnDestroy() {
    // this.unBindEvents();
  }
}
