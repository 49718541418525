import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionModel } from 'src/app/models/session.model';

@Component({
  selector: 'app-wrk-session-card',
  templateUrl: './wrk-session-card.component.html',
  styleUrls: ['./wrk-session-card.component.css']
})
export class WrkSessionCardComponent implements OnInit {
 
  currentMinutes: number;
  currentHours: number;
  currentSecondes: number;
  currentDay : number;
  currentYear : number =new Date().getFullYear();
  currentMonth: number =new Date().getMonth();


  sessionHours: number;
  sessionMinutes: number;
  sessionSecondes: number;
  sessionDay : number;
  sessionYears: number;
  sessionMonth : number;

  currentTime = new Date().toLocaleTimeString();
  currentDate = new Date().toLocaleDateString();

  interval;
  remainingHours: number;
  remainingMinutes: number;
  remainingSecondes: number = 60;
  _isReady = false; 

  SessionDate : any
  @Input() session: SessionModel = new SessionModel()
  @Input() set isReady(value){
    console.log(value)
    this._isReady = value ? true : false;
  }  
  get isReady(){
    return this._isReady
  }
  @Output() cancel = new EventEmitter<any>();
  @Output() join = new EventEmitter<any>();

  constructor() { }
  ngOnInit(): void {

    this.timer()
    this.sessionSecondes = Number.parseInt((this.session.SessionTime as string).split(':')[2]) 
    
  }
  startTimer() {
    this.interval = setInterval(() => {
      // console.log(this.remainingHours);
      // console.log(this.remainingMinutes);
      if(this.remainingHours<=0 && this.remainingMinutes<=0)
        this.isReady = true;
      if (this.remainingSecondes > 0) {
        this.remainingSecondes--;
      }
      else {
        if (this.remainingMinutes > 0) {
        this.remainingSecondes = 60;
          this.remainingMinutes--;
        } else {
          if(this.remainingHours > 0){
            this.remainingMinutes = 60;
            this.remainingHours--;
          }
          
        }
      }
    }, 1000)
  }
  joinNow(){
      this.join.emit(true);
  }

  cancelClicked(event){
    this.cancel.emit(event)
  }


  timer(){

    var startTime = new Date(); 
    var endTime = new Date(`${this.session.SessionDate} ${this.session.SessionTime}`)
    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var totalMinutes = Math.round(difference / 60000);
    let secondBetweenTwoDate = totalMinutes * 60;
    let offset = new Date().getTimezoneOffset() * 60;
    this.remainingSecondes =60- Number.parseInt(this.currentTime.split(':', 3)[2]);

    if(secondBetweenTwoDate <= 0){
      this.remainingHours =0;
      this.remainingMinutes = 0;
      this.remainingSecondes = 0;
    }
    else {
      this.remainingHours = Number.parseInt(Math.floor(totalMinutes / 60).toString()); 
      this.remainingMinutes = Number.parseInt((totalMinutes % 60).toString());
    this.startTimer();
      
    }
    if(this.remainingHours<=0 && this.remainingMinutes<=0)
    this.isReady = true;
  }
}
