import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-subscribers-stats',
  templateUrl: './subscribers-stats.component.html',
  styleUrls: ['./subscribers-stats.component.css']
})
export class SubscribersStatsComponent implements OnInit, OnChanges {

  constructor(private dataService: DataService) { }

  basicData: any;
  basicOptions: any;
  labels = [];
  data = [];
  @Input() countSubscribersStats: any;
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getSubscribersStats()

  }
  applyLightTheme() {
    this.basicOptions = {
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          fontColor: '#495057'
        }
      },

      scales: {
        yAxes: [{
          title: "Subscribers in Hundreds",

          ticks: {
            fontColor: '#495057'
          }
        }]
      }
    };

  }
  getSubscribersStats() {
    this.labels = [];
    this.data = [];
    if (this.countSubscribersStats) {
      Object.entries(this.countSubscribersStats).forEach(([key, value]) => {
        if (key !== "year") {
          this.labels.push(key);
          this.data.push(value);
        }
      });
      this.basicData = {
        labels: this.labels,
        datasets: [
          {
            showInLegend: false,

            label: '',
            data: this.data,
            fill: false,
            borderColor: '#42A5F5'
          },
        ]
      }

      this.applyLightTheme();

    }

  }
}
export interface AppConfig {
  inputStyle?: string;
  dark?: boolean;
  theme?: string;
  ripple?: boolean;
}