import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router:Router) { }
  @Input() isTranslate :boolean = false
  ngOnInit(): void {
  }
  goTo(url:string){
    this.router.navigateByUrl(url)
  }
}
