import { Pipe, PipeTransform } from '@angular/core';
import { DateAndTimeService } from 'src/app/services/date-and-time-service/date-and-time.service';

@Pipe({
  name: 'customTime'
})
export class CustomTimePipe implements PipeTransform {
 
  constructor(private dateAndTimeService:DateAndTimeService){}
  /**
   * @param time Ex : 20:25:18 => 08:25 pm
   * @returns 
   */
  transform(time:string): string {
    let result = this.dateAndTimeService.convertTo12Format(time)
    return result;
  }

}
