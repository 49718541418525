import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as objectToFormData from "object-to-formdata";
import { serialize } from 'object-to-formdata';

import { environment } from 'src/environments/environment';
const BaseUrl = environment.BaseUrl;
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(protected http: HttpClient) { }


  get(Url: string, param?, httpHeaders?: HttpHeaders) {

    return this.http.get<any>(
      `${BaseUrl}/api/${Url}` +
      (param ? "?" + this.serializeObject(param) : ""),
      {
        observe: 'response',
        headers: httpHeaders
      }
    );
  }

  getExternalApi(url){
    return this.http.get(url,{
      observe: 'response',
    });
  }

  post(resource, Url: string, httpHeaders?: HttpHeaders, responseType: any = 'json', asFormData = true) {
    httpHeaders = new HttpHeaders()
    httpHeaders.set('Content-Type','application/json')
    httpHeaders.set('Accept','application/json')
    return this.http.post(`${BaseUrl}/api/${Url}`,
      asFormData ? serialize(resource, { indices: true }) : resource,
      {
        observe: 'response',
        headers: httpHeaders,
        responseType: responseType
      })
  }

  put(resource, Url: string, httpHeaders?: HttpHeaders, responseType: any = 'json') {
    return this.http.post(`${BaseUrl}/api/${Url}`, resource, {
      observe: 'response',
      headers: httpHeaders,
      responseType: responseType
    })
  }
  put_(resource, Url: string, httpHeaders?: HttpHeaders, responseType: any = 'json') {
    console.log("DAs")
    console.log(`${BaseUrl}/api/${Url}`)
    return this.http.put(`${BaseUrl}/api/${Url}`, resource, {
      observe: 'response',
      headers: httpHeaders,
      responseType: responseType
    })
  }

  delete(id, Url) {
    let i = id ? `/${id}` : ''
    return this.http.delete(`${BaseUrl}/api/${Url}` + i, {
      observe: 'response',
    })
  }

  serializeObject(object) {
    return Object.entries(object)
      .map(
        ([key, val]) => {
          if (val == null || val == undefined)
            return `${key}=`
          else
            return `${key}=${val}`
        })
      .join("&");
  }


}


