import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  transaction_id:any
  session_id : any
  payment_status : any
  constructor(private route: ActivatedRoute,private dataService: DataService,private router :Router) { }

  ngOnInit(): void {
    // console.log('aaaaaaaaaaaaaaaaaaaaaaaaa');
    // this.route.params.subscribe(res => {
    //   // this.transaction_id = res['transaction_id']
    //   // this.session_id = res['session_id']
    //   // this.session_id = res['status']
    //   // console.log(res);
    //   console.log(res['status']);
    // })
    //  this.dataService.put_(this.prepare_info(), 'credimax').subscribe(
    //   (res: any) => {
    //     if (res.body.Result.statusCode == 200) {
    //       // window.location.href = "https://www.bing.com/search?q=Succeeded&form=ANNTH1&refig=18d8d67a46ca49eaab15daa6b42eb58f";
    //        this.router.navigate(['paymentmessage'])
    //     }
    //     else {
    //       this.router.navigate(['paymentfail'])

    //     }
    //   }, err => {
    //     this.router.navigate(['paymentfail'])

    //   }
    // )
  }


  prepare_info() {
    return {
      'transaction_id': this.transaction_id,
       'session_id': this.session_id,
    }
  }

}
