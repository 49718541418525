import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { WesiteModuleRoutingModule } from './wesite-module-routing.module';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { TutorLoginComponent } from './tutor-login/tutor-login.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TutorRegisterComponent } from './tutor-register/tutor-register.component';
import { ToastModule } from 'primeng/toast';
import { HeaderComponent } from './header/header.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailVertificationComponent } from './email-vertification/email-vertification.component';
import { CalendarModule } from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {PasswordModule} from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { UtilesModule } from '../Modules/utiles/utiles.module';
import { FooterComponent } from './footer/footer.component';
import { TabViewModule } from 'primeng/tabview';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    HomeComponent,
    JobsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    TutorLoginComponent,
    TutorRegisterComponent,
    HeaderComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    EmailVertificationComponent,
    GetInTouchComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    WesiteModuleRoutingModule,
    ProgressSpinnerModule,
    FormsModule,ReactiveFormsModule,
    ToastModule,
    CalendarModule ,DropdownModule,
    PasswordModule,
    InputTextModule,
    UtilesModule,
    TabViewModule,
    TranslateModule
  ],
  exports:[FooterComponent]
  ,providers:[DatePipe]
})
export class WesiteModuleModule { }
