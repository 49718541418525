import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { FilterModel } from 'src/app/models/filter.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { NotificationService } from 'src/app/services/notification-service/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private fb:FormBuilder,private notificationService:NotificationService,private dataService:DataService) { }
  isSubmittedSubscribeNow= false;
  appStoreUrl= environment.appStoreUrl;
  googleStoreUrl = environment.googleStoreUrl;

  SubscribeNow = new FormControl('',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  ngOnInit(): void {

  }
  SubscribeNowClick(){
    this.isSubmittedSubscribeNow = true;

    if(this.SubscribeNow.invalid){
      return;
    }
    else{
      this.dataService.post({Email:this.SubscribeNow.value},'addNewletterSubscription').subscribe(
        res=>{
          if (res.body['Result']['statusCode'] != 200) {

                     this.notificationService.notify('error','Error','Error.')
;
            return
          }
          else{
            this.notificationService.notify('success','Success','You have successfully registered for Writalk Newsletters.')

            this.isSubmittedSubscribeNow = false;
            this.SubscribeNow = new FormControl('',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
          }
        }
      )
    }
  }
  
  
}
