import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login/login.service';
export const SUCCESS_KEY_CODE: number = 200;
export const BAD_KEY_CODE: number = 400;
export const SERVER_ERROR_KEY_CODE: number = 500;

const NOT_AUTHORIZED_KEY_CODE: number = 401;

@Injectable({
  providedIn: 'root'
})

export class HttpInterceptorService implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router, private messageService: MessageService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // should get it from local storage
    let token = this.loginService.getToken();
    let clonedRequest: HttpRequest<any> = req.clone({

      setHeaders: { Authorization: 'Bearer ' + token },
      //replace it with token
      // headers : req.headers.set('Authorization','Bearer ' + token)
    })
    return next.handle(clonedRequest).pipe(
      tap(res => {
        // console.log(res);
        if (res instanceof HttpResponse) {

          if (res.body.code !== SUCCESS_KEY_CODE || res.body.Result.statusCode !== SUCCESS_KEY_CODE) {

            return throwError("Operation Error")
          }
         
          else if ((res.status === BAD_KEY_CODE || res.body.Result.statusCode == BAD_KEY_CODE)
              ||(res.status === SERVER_ERROR_KEY_CODE || res.body.Result.statusCode == SERVER_ERROR_KEY_CODE)) {
            let message = res.body.Result.message;
            if (res.body.Result.message)
              if (typeof (message) == "string") {
                this.messageService.add({ severity: 'error', summary: 'ERROR', detail: message });
              } else {
                Object.keys(res.body.Result.message).forEach(item => {
                  this.messageService.add({ severity: 'error', summary: 'ERROR', detail: res.body.Result.message[item] });
                })
              }

            return throwError("Operation Error")
          }
        }
      }),
      
      catchError(err => {
        if ((err.status === BAD_KEY_CODE || err.error?.Result?.statusCode == BAD_KEY_CODE)
        ||(err.status === SERVER_ERROR_KEY_CODE || err.error?.Result?.statusCode == SERVER_ERROR_KEY_CODE)) {
          let message = err.error.Result?.message;
          if (message)
            if (typeof (message) == "string") {
              this.messageService.add ({ severity: 'error', summary: 'ERROR', detail: message });
            } else {
              Object.keys(err.error.Result.message).forEach(item => {
                this.messageService.add({ severity: 'error', summary: 'ERROR', detail: err.error.Result.message[item] });
              })
            }
          return throwError(err)
        }
        else if(err.status==NOT_AUTHORIZED_KEY_CODE){
          this.loginService.logout()
        }
        console.log(`(Http Or Operation) Error Interpreted :\n${err?.message}`);
        return throwError(err);

      })

    )

  }
}
