

<div class="App">
    <div class="App-header">

        <h1>Writalk</h1>
    </div>
    <div class="row"> <div class="App-main col-12 row" style="margin: 0;">



        <div id="controls" class='App-control-container hidden col-1' [ngClass]="{'hidden':controls == false}">
            <div class="ots-video-control circle audio text-center" id="toggleLocalAudio" [ngClass]="{'audio':audioVar ==false}" (click)="toggleLocalAudio()"> <i id="ToiconAudio" class="fas icon-control-main" [ngClass]="{'audioStyle':audioStyleVar == false,'fa-microphone-slash':audioSlashVar == true}"></i></div>


            <div class="ots-video-control circle video text-center" id="toggleLocalVideo" [ngClass]="{'video':videoVar == false}" (click)="toggleLocalVideo()"><i id="ToiconVideo" class="fas icon-control-main" [ngClass]="{'fa-video-slash':videoSlashVar == true,'audioStyle':'videoStyleVar == false'}"></i></div>
         

            <div class="ots-video-control disconnect-user" id="disconnectMe" (click)="Tk()"><i class="fas fa-sign-out-alt icon-disconnect icon-control-main"></i></div>
            <span id="connectionCountFieldtwo" >{{connectionCount}}</span>
        </div>
        <div class="App-video-container" class="col-8" id="appVideoContainer" style="position: relative;">
            <div class="App-mask" id="connecting-mask" [ngClass]="{'hidden':connectingMask == true}">
                <div class="message with-spinner">Connecting</div>
            </div>
            <div class="App-mask hidden" id="start-mask" [ngClass]="{'hidden':startMask == false}" #startable>
                <div class="message button clickable" id="start" (click)="startCall()">Click To Start Call</div>
            </div>
            <div [ngClass]="{'publishing': publishing,'hiddenPub': publishingAbility == false}" #publisherDiv style="
            height: 100%;
            "></div>
            

        </div>

        <div class="righVideoCall col-3 row">
            
            <div class="main-section col-12" [ngClass]="chatD ? 'open-more' : 'open-close' ">
                <div class="row border-chat" >
                    <div class="col-md-12 col-sm-12 col-xs-12 first-section">
                        <div class="row">
                            <div class="col-12 left-first-section" >
                                <p>Writalk Chat</p>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-6 right-first-section">
                                <!--  <a href="javascript:void(0);"><i class="fa fa-minus" aria-hidden="true" (click)="this.chatD = !this.chatD" ></i></a>
    -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-chat border-chat-two" >
                    <div class="col-12 second-section" style="height: 100%;">
                        <div id="messageTuTT"  class="chat-section" >
                            <ul id="holderCh" class="chat-box-S">




                            </ul>
                            <div class="p-col-12 chat-box-T">
                                <div class="p-inputgroup">
                                    <button type="button" pButton pRipple icon="pi pi-check" styleClass="p-button-success" (click)='ChatTuSend()'></button>    
                                    <input type="text" pInputText placeholder="Your Message" [(ngModel)]="messageTu" (keyup.enter)='ChatTuSend()'>   
                             
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>
   



    <div class="container">
        <div class="row">
            <div *ngIf="ListStat" class="col-12 subscriber-session">
                <h3 class="text-left"> List Of Subscribers:</h3>
                <div class="text-center">
                    <ul class="row ul-tojoin">
                        <li *ngFor="let sb of SubscribersList; index as i; trackBy: trackByFn" class="col " (click)="updateStateStud(sb.connection_id)">
                            <p class="list-si"> <i class="fas icon-Us" [ngClass]="{'fa-user-times': sb.statePub == 'mute', 'fa-user-check' : sb.statePub == 'unMute','fa-hand-paper': sb.statePub == 'requested' }"></i>{{sb.FullName}}</p>
                        </li>

                    </ul>
                    <div class="d-grid gap-2 col-6 mx-auto">
                        <button class="btn btn-danger btn-mute" type="button" (click)="mAll()">Mute All</button>

                    </div>

                </div>
            </div>


         
        </div>

    </div>


