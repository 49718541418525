// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BaseUrl:'https://writalk-be.nakheelgroup.online',
  //BaseUrl:'https://be-preprod.writalk.com',
  //  BaseUrl:'https://dev-backend.writalk.com', //dev
    BaseUrl:'https://backend.writalk.com', //prod
  // BaseUrl:'http://127.0.0.1:8000',

  merchantID:'E17616950',


  appStoreUrl:'https://apps.apple.com/ae/app/writalk/id1576062112',
  googleStoreUrl:'https://play.google.com/store/apps/details?id=com.writalk.writalk',
  whatsapp_number:'+966539131232',
  firebase : {
    apiKey: "AIzaSyA2dyRp7LglMXnBP2vRo4nQ9iHnp36DVmY",
    authDomain: "writalk-6b.firebaseapp.com",
    databaseURL: "https://writalk-6b-default-rtdb.firebaseio.com",
    projectId: "writalk-6b",
    storageBucket: "writalk-6b.appspot.com",
    messagingSenderId: "573918274230",
    appId: "1:573918274230:web:0f6029fa0d7f343d32fc17",
    measurementId: "G-KB8YBN4SFP"
  }
};

// apiKey: "api-key",
// authDomain: "project-id.firebaseapp.com",
// databaseURL: "https://project-id.firebaseio.com",
// projectId: "project-id",
// storageBucket: "project-id.appspot.com",
// messagingSenderId: "sender-id",
// appId: "app-id",


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
