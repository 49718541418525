<div class="row p-pt-3" style="background-color: #24375D;align-items: flex-end;">
    <div class="logo">
        <img class="logo-image cursor-pointer" src="assets/images/websiteImages/logo.svg" alt="" routerLink="/website">
    </div>
    <div class="links">
        <ul class="p-d-flex ul-header justify-content-end" style="align-items: center;">
            <li class="p-mx-2 display-nav">
                <a class="li-header p-menuitem-link " (click)="OnLinkClicked('WhyWritalk')">Why Writalk?</a>
            </li>
            <li class="p-mx-2 display-nav">
                <a class="li-header p-menuitem-link " (click)="OnLinkClicked('Howdoesitwork')">How does it work?</a>
            </li>
            <li>
                <select (change)="OnLinkChange($event)" class="form-control select-header">
                    <option value="WhyWritalk">
                        <a class="li-header p-menuitem-link "  href="#WhyWritalk">Why Writalk  ?</a>
                    </option>
                    <option value="Howdoesitwork">
                        <a class="li-header p-menuitem-link "  href="#Howdoesitwork">How does it work ?</a>
                    </option>
                </select>
            </li>
            <div class="btns-auth-mobile align-items-end">
                <div class="p-mx-1">
                    <a class="btn w-btn-info btn-sm li-header btn-font-size p-p-1 bold-txt" routerLink='/website/TutorRegister' style="padding-inline: 1vw !important;">Become a Tutor</a>
                </div>
                <div class="p-mx-1">
                    <a class="btn w-btn-signIn btn-sm li-header btn-font-size p-p-1" routerLink='/website/TutorLogin' style="padding-inline: 5vw !important;">Sign in</a>
                </div>
            </div>


            <div class="btns-auth align-items-end">
                <div class="p-mx-3">
                    <a class="btn w-btn-info  btn-sm li-header bold-txt" routerLink='/website/TutorRegister' style="padding-inline: 1vw !important;">Become a Tutor</a>
                </div>
                <div class="p-mr-3">
                    <a class="btn w-btn-signIn btn-sm li-header bold-txt" routerLink='/website/TutorLogin' style="padding-inline: 4vw !important;">Sign in</a>
                </div>
            </div>
        </ul>
    </div>

</div>